import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { marshallTextDocumentPayload } from "@incident-shared/incident-forms/marshall";
import { ConfirmationDialog, LoadingModal, ModalFooter } from "@incident-ui";
import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  Incident,
  IncidentAlertStateEnum,
  IncidentsCreateUpdateRequestBody,
  IncidentStatus,
  IncidentStatusCategoryEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { Category, isCategory } from "../statuses/status-utils";
import { OpenStreamsBlockResolvingModal } from "../streams/OpenStreamsWarning";
import { useStatusesForIncident } from "../useIncidentCrudResources";
import {
  doPendingAlertsRequireAction,
  PendingAlertsBlockerWarning,
} from "./PendingAlertsNeedingUpdateModal";

export type UpdateFormData = Omit<
  IncidentsCreateUpdateRequestBody,
  "custom_field_entries"
>;

export const CancelIncidentModal = ({
  incident,
  onClose,
}: {
  onClose: () => void;
  incident: Incident;
}): React.ReactElement => {
  const { statusesLoading, statuses } = useStatusesForIncident({ incident });

  const title = "Cancel incident";
  if (statusesLoading) {
    return <LoadingModal title={title} onClose={onClose} />;
  }

  return (
    <CancelIncidentForm
      onClose={onClose}
      incident={incident}
      statuses={statuses}
    />
  );
};

const CancelIncidentForm = ({
  onClose,
  incident,
  statuses,
}: {
  onClose: () => void;
  incident: Incident;
  statuses: IncidentStatus[];
}): React.ReactElement => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const formMethods = useForm<UpdateFormData>();

  const { setError, getValues } = formMethods;

  const canceledStatus = statuses.find(isCategory(Category.Canceled));
  if (!canceledStatus) {
    throw new Error("Could not find canceled status");
  }

  const { data: listAlertsResponse, isLoading: isLoadingPendingAlerts } =
    useAPI("alertsListIncidentAlerts", { incidentId: incident.id }, {});
  const incidentAlerts = listAlertsResponse?.incident_alerts ?? [];
  const pendingAlerts = incidentAlerts.filter(
    (i) => i.state === IncidentAlertStateEnum.Pending,
  );
  const alertsRequireAction = doPendingAlertsRequireAction({
    pendingAlerts,
    selectedStatusID: canceledStatus.id,
    statuses: statuses,
  });

  const refetchTimeline = useAPIRefetch("incidentTimelineListTimelineItems", {
    incidentId: incident.id,
    timezone: localTimezone,
  });
  const refetchUpdates = useAPIRefetch("incidentUpdatesListForIncident", {
    incidentId: incident.id,
  });
  const {
    trigger: createUpdate,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (apiClient, formData: UpdateFormData) => {
      // Only include custom fields if we're doing a full update
      // This is because we only show them for a full update

      await apiClient.incidentsCreateUpdate({
        incidentId: incident.id,
        createUpdateRequestBody: {
          ...formData,
          custom_field_entries: [],
          to_incident_status_id: canceledStatus.id,
          message: marshallTextDocumentPayload(formData.message),
        },
      });

      refetchTimeline();
      refetchUpdates();
    },
    {
      onSuccess: onClose,
      setError,
    },
  );

  const {
    data: { streams },
    isLoading: streamsLoading,
  } = useAPI(
    "streamsList",
    { parentId: incident.id },
    { fallbackData: { streams: [] } },
  );
  const openStreams = streams.filter(
    (stream) => stream.status.category === IncidentStatusCategoryEnum.Active,
  );

  if (streamsLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  if (openStreams.length > 0) {
    return (
      <OpenStreamsBlockResolvingModal
        openStreams={openStreams}
        incident={incident}
        onClose={onClose}
        title={"Cancel incident"}
        verb={"canceling"}
      />
    );
  }

  return (
    <Form.Modal
      formMethods={formMethods}
      onSubmit={() => setShowConfirmation(true)}
      title={"Cancel incident"}
      analyticsTrackingId="update-incident"
      disableQuickClose
      onClose={onClose}
      genericError={genericError}
      footer={
        <ModalFooter
          onClose={onClose}
          analyticsTrackingId="update-incident-submit"
          saving={saving}
          confirmButtonType="submit"
          disabled={isLoadingPendingAlerts || alertsRequireAction}
          requiredScope={ScopeNameEnum.IncidentsUpdate}
          confirmButtonText={"Yes, cancel it"}
          cancelButtonText={"No, don't cancel"}
        />
      }
    >
      <PendingAlertsBlockerWarning
        alertsRequireAction={alertsRequireAction}
        pendingAlerts={pendingAlerts}
        incident={incident}
      />
      <div>
        <TemplatedTextInputV2
          formMethods={formMethods}
          label="Message"
          required={false}
          name="message.text_node"
          placeholder={
            "e.g. We've realised this is actually working as expected"
          }
          format="slack_rich_text"
          includeVariables={false}
          includeExpressions={false}
        />
        <p className="mt-1 text-xs text-slate-700">
          Provide a reason to help others understand why this incident was
          canceled. This will be shown on the incident update, which gets sent
          to the channel and will appear on the timeline.
        </p>
      </div>{" "}
      {showConfirmation && (
        <ConfirmationDialog
          onCancel={() => setShowConfirmation(false)}
          onConfirm={() => createUpdate(getValues())}
          isOpen={true}
          title={`Confirm incident cancellation`}
          analyticsTrackingId="confirm-canceling-incidents"
          saving={saving}
        >
          <p>Are you sure you want to cancel this incident?</p>
        </ConfirmationDialog>
      )}
    </Form.Modal>
  );
};
