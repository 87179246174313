import {
  Button,
  ButtonTheme,
  Modal,
  ModalContent,
  ModalFooter,
} from "@incident-ui";

import graphic from "./introducing-insights-v3.svg";

export const IntroducingInsightsV3Modal = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}): React.ReactElement => {
  return (
    <Modal
      isOpen
      title={""} // hidden
      hideHeader
      onClose={onClose}
      analyticsTrackingId={null}
      className="!w-[600px]"
      isExtraLarge
    >
      <ModalContent className="!p-0 !m-0">
        <img className="w-full" src={graphic} />
        <div className="flex flex-col items-center p-10 gap-3 text-center">
          <div className="text-2xl-bold">Introducing custom dashboards</div>
          <div className="text-sm-normal flex flex-col gap-2">
            <div>
              Tell engaging stories about your team&rsquo;s incident response
              through data. Plus, all your previously saved views are now easy
              to access as custom dashboards.
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter hideBorder>
        <div className="w-4"></div>
        <Button
          analyticsTrackingId={null}
          onClick={onClose}
          theme={ButtonTheme.Primary}
          loading={loading}
          disabled={loading}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
