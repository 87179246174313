import { UpgradeRequiredProps } from "@incident-shared/gates/GatedButton/GatedButton";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  ButtonTheme,
  GenericErrorMessage,
  Heading,
  Icon,
  IconEnum,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import _ from "lodash";
import React from "react";
import { CreateInternalStatusPageModal } from "src/components/legacy/status-page-config/internal/create/CreateInternalStatusPage";
import { InternalStatusPagesList } from "src/components/legacy/status-page-config/internal/InternalStatusPagesList";
import { ScopeNameEnum } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { ImportCTA } from "../atlassian-import/ImportCTA";
import {
  ChoosePageTypeModal,
  PAGE_TYPE_TO_ICON,
} from "../create/ChoosePageTypeCreateModal";
import { ChoosePublicPageTypeModal } from "../create/ChoosePublicPageTypeModal";
import { CreateStandalonePublicStatusPageModal } from "../create/StandalonePageCreateModal";
import { StatusPagesListEmptyState } from "../list/StatusPagesListEmptyState";
import { StatusPagesList } from "../list/StatusPagesListPage";

export enum CreateStatusPageModals {
  Choose = "choose",
  ChoosePublic = "choose_public",
  Internal = "internal",
  PublicStandalone = "standalone",
}

export type CreateModalProps = {
  canCreatePublicPages?: boolean;
  canCreateInternalPages?: boolean;
  canCreateCustomerPages?: boolean;
};

const createStatusPageModalComponents: {
  [key in CreateStatusPageModals]: (
    props: CreateModalProps,
  ) => React.ReactElement;
} = {
  [CreateStatusPageModals.Choose]: (props) => (
    <ChoosePageTypeModal {...props} />
  ),
  [CreateStatusPageModals.Internal]: (props) => (
    <CreateInternalStatusPageModal {...props} />
  ),
  [CreateStatusPageModals.PublicStandalone]: (props) => (
    <CreateStandalonePublicStatusPageModal {...props} />
  ),
  [CreateStatusPageModals.ChoosePublic]: (props) => (
    <ChoosePublicPageTypeModal {...props} />
  ),
};

export const StatusPagesHomepage = ({
  showCreateModal,
}: {
  showCreateModal: CreateStatusPageModals | null;
}): React.ReactElement => {
  const { identity } = useIdentity();

  const {
    data: { status_pages: pages },
    error: pagesError,
    isLoading: pagesLoading,
  } = useAPI("statusPageList", undefined, {
    fallbackData: { status_pages: [] },
  });

  const [standardPages, customerPages] = _.partition(
    pages,
    (page) => page.page_type !== "customer",
  );

  const {
    data: { internal_status_pages: internalPages },
    error: internalError,
    isLoading: internalsLoading,
  } = useAPI("internalStatusPageListPages", undefined, {
    fallbackData: { internal_status_pages: [] },
  });

  const error = pagesError || internalError;
  const isLoading = pagesLoading || internalsLoading;

  if (error) {
    captureException(error);
    console.error(error);
    return <GenericErrorMessage error={error} />;
  }

  const publicPageLimit = identity?.feature_gates.status_pages_count;
  const publicLivePageCount = pages.filter(
    (page) => !page.mirroring_atlassian_page,
  ).length;
  const internalPageLimit = identity?.feature_gates.internal_status_pages_count;
  const internalLivePagesCount = internalPages.length;

  const orgCanCreatePublicStatusPage =
    publicPageLimit === undefined
      ? true
      : publicLivePageCount < publicPageLimit;
  const orgCanCreateInternalStatusPage =
    internalPageLimit === undefined
      ? true
      : internalLivePagesCount < internalPageLimit;
  const orgCanCreateCustomerStatusPage =
    identity?.feature_gates.status_pages_customer_pages;

  const CreateStatusPageModal =
    showCreateModal == null
      ? null
      : createStatusPageModalComponents[showCreateModal];

  const showEmptyState =
    standardPages.length + internalPages.length + customerPages.length === 0;
  const fullWidth = showEmptyState;

  return (
    <>
      {CreateStatusPageModal != null && (
        <CreateStatusPageModal
          canCreateCustomerPages={orgCanCreateCustomerStatusPage}
          canCreateInternalPages={orgCanCreateInternalStatusPage}
          canCreatePublicPages={orgCanCreatePublicStatusPage}
        />
      )}
      <PageWrapper
        width={fullWidth ? PageWidth.Full : PageWidth.Narrow}
        icon={IconEnum.StatusPage}
        loading={isLoading}
        title="Status pages"
      >
        {showEmptyState ? (
          <div className="flex flex-col items-center grow ">
            <StatusPagesListEmptyState />
          </div>
        ) : (
          <div className={tcx("p-6 space-y-6 max-w-4xl mx-auto")}>
            <ImportCTA />
            <div className="space-y-6">
              <div className="space-y-4">
                <ListPagesHeading
                  icon={PAGE_TYPE_TO_ICON.public}
                  title="Public status pages"
                  hasAtLeastOne={standardPages.length > 0}
                  explanation={
                    "Effortlessly communicate real-time status updates publicly to all your users."
                  }
                  accessory={
                    <AddPageButton
                      pageType="public"
                      upgradeRequired={!orgCanCreatePublicStatusPage}
                      upgradeRequiredProps={{
                        gate: {
                          type: "numeric",
                          value: identity?.feature_gates.status_pages_count,
                          featureNameSingular: "status page",
                        },
                        featureName: "status pages",
                      }}
                    />
                  }
                />

                {standardPages.length === 0 ? (
                  <EmptyState info="Use public status pages to keep your users up-to-date with what's going on." />
                ) : (
                  <StatusPagesList pages={standardPages} />
                )}
              </div>
              <div className="space-y-4">
                <ListPagesHeading
                  icon={PAGE_TYPE_TO_ICON.customer}
                  hasAtLeastOne={customerPages.length > 0}
                  title="Customer status pages"
                  explanation={
                    "Use customer pages to provide private status updates tailored to specific customers."
                  }
                  accessory={
                    <AddPageButton
                      pageType="customer"
                      upgradeRequired={!orgCanCreateCustomerStatusPage}
                      upgradeRequiredProps={{
                        gate: {
                          type: "boolean",
                        },
                        featureName: "customer status pages",
                      }}
                    />
                  }
                />

                {customerPages.length === 0 ? (
                  <EmptyState info="Use customer pages to give your customers a dedicated place to see the status of their service." />
                ) : (
                  <StatusPagesList pages={customerPages} />
                )}
              </div>
              <div className="space-y-4">
                <ListPagesHeading
                  icon={PAGE_TYPE_TO_ICON.internal}
                  title="Internal status pages"
                  hasAtLeastOne={internalPages.length > 0}
                  explanation={
                    "Share active status updates with your internal employees, only accessible to your incident.io users."
                  }
                  accessory={
                    <AddPageButton
                      pageType="internal"
                      upgradeRequired={!orgCanCreateInternalStatusPage}
                      upgradeRequiredProps={{
                        gate: {
                          type: "numeric",
                          value:
                            identity?.feature_gates.internal_status_pages_count,
                          featureNameSingular: "internal status page",
                        },
                        featureName: "internal status pages",
                      }}
                    />
                  }
                />
                {internalPages.length === 0 ? (
                  <EmptyState info="Use internal status pages to communicate within your organisation about active incidents. They can only be accessed by people who can log in to incident.io." />
                ) : (
                  <InternalStatusPagesList pages={internalPages} />
                )}
              </div>
            </div>
          </div>
        )}
      </PageWrapper>
    </>
  );
};

const ListPagesHeading = ({
  hasAtLeastOne,
  explanation,
  title,
  accessory,
  icon,
}: {
  hasAtLeastOne: boolean;
  explanation: string;
  title: string;
  icon: IconEnum;
  accessory: React.ReactNode;
}) => {
  return (
    <div
      className={"my-2 px-2 sm:px-0 flex items-center justify-between gap-8"}
    >
      <div>
        <Heading level={2} className="shrink-0 flex gap-1 items-center">
          <Icon id={icon} />
          {title}
        </Heading>
        {hasAtLeastOne && (
          <div className="text-sm text-content-tertiary mt-1">
            {explanation}
          </div>
        )}
      </div>
      {accessory}
    </div>
  );
};

const AddPageButton = ({
  pageType,
  upgradeRequired,
  upgradeRequiredProps,
}: {
  pageType: "public" | "internal" | "customer";
  upgradeRequired: boolean;
  upgradeRequiredProps: UpgradeRequiredProps;
}): React.ReactElement => {
  return (
    <GatedButton
      requiredScope={ScopeNameEnum.StatusPagesConfigure}
      theme={ButtonTheme.Secondary}
      analyticsTrackingId={`create-${pageType}-page`}
      href={
        pageType === "public"
          ? "/status-pages/create-public"
          : `/status-pages/${pageType}/create`
      }
      upgradeRequired={upgradeRequired}
      upgradeRequiredProps={upgradeRequiredProps}
    >
      Create {pageType} page
    </GatedButton>
  );
};

const EmptyState = ({ info }: { info: React.ReactNode }) => {
  return (
    <div className="bg-surface-secondary rounded-2 w-full flex flex-row gap-2 text-slate-600 p-4 text-sm justify-between items-center">
      <div className="max-w-prose">{info}</div>
    </div>
  );
};
