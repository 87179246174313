import { Product } from "@incident-shared/billing";
import { ExploDashboard } from "@incident-shared/explo/ExploDashboard";
import { FollowUpCreateModal } from "@incident-shared/follow-ups";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import type { Tab } from "@incident-ui";
import {
  AddNewButton,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
  TabPane,
  TabSection,
  Txt,
} from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ExploDashboardID } from "src/components/insights/v3/dashboards/common/types";
import { Timeline } from "src/components/timeline/Timeline";
import {
  ActionStatusEnum as ActionStatus,
  IncidentModeEnum,
  IncidentStatusCategoryEnum as IncidentStatusCategory,
  IncidentVisibilityEnum,
  PolicyViolationLevelEnum,
  PolicyViolationPolicyTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { IncidentHeaderModal } from "src/routes/legacy/IncidentRoute";
import { useDebriefName, usePostmortemName } from "src/utils/postmortem-name";
import { assertUnreachable } from "src/utils/utils";

import { useNavigateToModal } from "../../../../utils/query-params";
import { ActionCreateModal } from "../actions/ActionCreateModal";
import { ActionsTab } from "../actions/ActionsTab";
import { EmptyTab } from "../EmptyTab";
import { FollowUpsTab } from "../followups/FollowUpsTab";
import { incidentInEditableStatus } from "../helpers";
import {
  useActions,
  useFlatActivityLogs,
  useIncident,
  usePoliciesAndViolations,
  useSuggestedFollowUps,
} from "../hooks";
import { PostIncidentTab } from "../postincidentflow/PostIncidentTab";
import { ProductAccessCondtionalComponent } from "../sidebar/IncidentSidebar";
import { Category } from "../statuses/status-utils";
import { IncidentUpdatesTab } from "./IncidentUpdatesTab";
import { Summary, SummaryContext } from "./Summary";

export enum BodyTabs {
  PostIncident = "post-incident",
  Updates = "updates",
  Timeline = "timeline",
  Actions = "actions",
  FollowUps = "follow-ups",
  Pulse = "pulse",
}

export const IncidentBody = ({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement => {
  const [showCreateActionModal, setShowCreateActionModal] = useState(false);
  const [showCreateFollowUpModal, setShowCreateFollowUpModal] = useState(false);
  const [timelineMinimized, setTimelineMinimized] = useState<string[]>([]);
  const [timelineCommentsOpen, setTimelineCommentsOpen] = useState<string[]>(
    [],
  );

  const { incident } = useIncident(incidentId);
  const { actions } = useActions(incidentId);
  const { activities } = useFlatActivityLogs(incidentId);
  const { policyViolations } = usePoliciesAndViolations(incidentId);
  const { identity } = useIdentity();
  const { suggestedFollowUps } = useSuggestedFollowUps(incidentId);

  const { hasResponse } = useProductAccess();

  const [currentTab, setCurrentTab] = useState<BodyTabs>(BodyTabs.Updates);
  const navigate = useOrgAwareNavigate();
  const navigateToModal = useNavigateToModal();

  const isClosed =
    incident?.incident_status?.category === IncidentStatusCategory.Closed;

  // Note that we'll show the post-incident tab if the incident is in a post-incident OR closed state.
  const didOptOutOfPostIncidentFlow =
    incident?.did_opt_out_of_post_incident_flow ?? false;
  const shouldShowPostIncidentTab =
    incident?.post_incident_flow_id &&
    !didOptOutOfPostIncidentFlow &&
    incident?.mode !== IncidentModeEnum.Tutorial &&
    incident?.incident_status?.category !== IncidentStatusCategory.Active &&
    hasResponse;

  // If we have lab insights enabled, we want to show an insights pane. And for
  // local development, permit overriding which incident we display via a local
  // storage setting.
  const { insightsLab, hidePulseTab } = useFlags();
  const insightsIncidentID =
    window.localStorage.getItem("incident-insights-override") ??
    incidentId ??
    "";

  const onTabChange = (newTab: string) => {
    const newSearch = new URLSearchParams(search);
    newSearch.set("tab", newTab);
    navigate(
      {
        pathname,
        search: newSearch.toString(),
      },
      { replace: true },
    );
    setCurrentTab(newTab as BodyTabs);
  };

  const { postmortemNameWithArticle } = usePostmortemName(incident);
  const { debriefNameWithArticleLower } = useDebriefName();

  const [isEditingSummary, setIsEditingSummary] = useState(false);

  const { search, pathname } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get("tab") as BodyTabs;
    if (tab === currentTab) return;
    const defaultTab =
      shouldShowPostIncidentTab &&
      incident?.incident_status.category === Category.PostIncident
        ? BodyTabs.PostIncident
        : BodyTabs.Updates;
    setCurrentTab(tab || defaultTab);
  }, [
    search,
    currentTab,
    shouldShowPostIncidentTab,
    incident?.incident_status.category,
  ]);

  const openActions = actions?.filter(
    (action) => action.status === ActionStatus.Outstanding,
  );

  const getAccessory = (): React.ReactElement | null => {
    if (!incident) return null;
    if (!incidentInEditableStatus(incident)) return null;

    switch (currentTab) {
      case BodyTabs.Updates:
        return (
          <AddNewButton
            title="Share update"
            analyticsTrackingId="share-update"
            onClick={() => navigateToModal(IncidentHeaderModal.UpdateIncident)}
          />
        );
      case BodyTabs.Actions:
        if (!isClosed) {
          return (
            <AddNewButton
              title="New action"
              analyticsTrackingId="new-action"
              onClick={() => setShowCreateActionModal(true)}
            />
          );
        }
        return null;
      case BodyTabs.FollowUps:
        return (
          <AddNewButton
            title="New follow-up"
            analyticsTrackingId="new-follow-up"
            onClick={() => setShowCreateFollowUpModal(true)}
          />
        );
      case BodyTabs.Timeline:
        return null;
      case BodyTabs.Pulse:
        return null;
      case BodyTabs.PostIncident:
        return null;
      default:
        if (currentTab === "learning") {
          // This is a hack to make the 'learning' tab work, so old URLs lying around still work.
          return null;
        }
        assertUnreachable(currentTab);
    }
    return null;
  };

  const pulseGraphsAvailable =
    insightsLab &&
    hasResponse &&
    identity.feature_gates.advanced_insights &&
    incident &&
    incident.visibility === IncidentVisibilityEnum.Public &&
    incident.mode === IncidentModeEnum.Standard;

  const tabs: Tab[] = [];
  if (shouldShowPostIncidentTab) {
    tabs.push({ id: BodyTabs.PostIncident, label: "Post-incident" });
  }
  tabs.push(
    ...[
      { id: BodyTabs.Updates, label: "Updates" },
      {
        id: BodyTabs.Timeline,
        label: "Timeline",
        className: "intercom-timeline",
      },
      {
        id: BodyTabs.Actions,
        label: "Actions",
        className: "intercom-actions",
        // Closed incidents should not have any open actions
        violations: isClosed ? openActions?.length : undefined,
        hidden: !hasResponse,
      },
      {
        id: BodyTabs.FollowUps,
        label: "Follow-ups",
        className: "intercom-follow-ups",
        violations: policyViolations.filter(
          (v) =>
            v.policy_type === PolicyViolationPolicyTypeEnum.FollowUp &&
            v.level === PolicyViolationLevelEnum.Error,
        )?.length,
        aiSuggestions: suggestedFollowUps.length,
        hidden: !hasResponse,
      },
      {
        id: BodyTabs.Pulse,
        label: "Pulse",
        className: "intercom-pulse",
        hidden: !pulseGraphsAvailable || hidePulseTab,
      },
    ],
  );

  const defaultChatChannelType = identity.ms_teams_info
    ? "Microsoft Teams"
    : "Slack";

  const primaryCommsChannelMissing =
    incident != null &&
    incident?.slack_channel_url == null &&
    incident?.ms_teams_channel_url == null;

  return (
    <SummaryContext.Provider value={{ setIsEditingSummary, isEditingSummary }}>
      <div className="grow pb-16" id="incident-content">
        {primaryCommsChannelMissing && (
          <Callout
            theme={CalloutTheme.Warning}
            className="mb-4 !bg-orange-50 !border !border-orange-400"
          >
            <div className="text-content-primary font-medium text-sm mb-1">
              We couldn&apos;t create a {defaultChatChannelType} Channel
            </div>
            <div className="text-xs space-y-2 max-w-xl">
              <div>
                This usually means {defaultChatChannelType} is having some form
                of outage. We&apos;ll keep trying to create the channel.
              </div>
              <div>
                In the meantime, share updates here to keep the rest of your
                team updated. All non {defaultChatChannelType} automations, such
                as subscriptions and workflows, will run as normal.
              </div>
              <ProductAccessCondtionalComponent
                requiredProduct={Product.Response}
              >
                <div>
                  {incident.call_url ? (
                    <>
                      You can also{" "}
                      <a href={incident.call_url} className="underline">
                        join the incident call.
                      </a>
                    </>
                  ) : (
                    <>
                      You can also{" "}
                      <Button
                        analyticsTrackingId={"set-call-url"}
                        theme={ButtonTheme.Naked}
                        className="text-xs"
                        onClick={() =>
                          navigateToModal(IncidentHeaderModal.UpdateCall)
                        }
                      >
                        add an incident call
                      </Button>
                      .
                    </>
                  )}
                </div>
              </ProductAccessCondtionalComponent>
            </div>
          </Callout>
        )}
        <div className="mb-5">
          <Summary incidentId={incidentId} />
        </div>
        <TabSection
          withIndicator
          value={currentTab}
          onTabChange={onTabChange}
          tabClassName="!text-sm text-content-tertiary"
          tabs={tabs}
          tabBarAccessory={getAccessory()}
          tabBarClassName="border-b border-stroke"
        >
          <TabPane tabId={BodyTabs.PostIncident}>
            <PostIncidentTab incidentId={incidentId} />
          </TabPane>
          <TabPane tabId={BodyTabs.Updates}>
            <IncidentUpdatesTab incidentId={incidentId} />
          </TabPane>
          <TabPane tabId={BodyTabs.Timeline}>
            <Timeline
              incidentId={incidentId}
              minimized={timelineMinimized}
              setMinimized={setTimelineMinimized}
              commentBoxOpen={timelineCommentsOpen}
              setCommentBoxOpen={setTimelineCommentsOpen}
              className="pt-2"
            />
          </TabPane>
          <ProductAccessCondtionalComponent requiredProduct={Product.Response}>
            <TabPane tabId={BodyTabs.Actions} className="intercom-actions-body">
              <div className="mt-2">
                <ActionsTab
                  incidentId={incidentId}
                  isClosed={isClosed}
                  onTabChange={onTabChange}
                />
              </div>
            </TabPane>
          </ProductAccessCondtionalComponent>
          <ProductAccessCondtionalComponent requiredProduct={Product.Response}>
            <TabPane
              tabId={BodyTabs.FollowUps}
              className="intercom-follow-ups-body"
            >
              <div className="mt-2">
                <FollowUpsTab incidentId={incidentId} />
              </div>
            </TabPane>
          </ProductAccessCondtionalComponent>
          {!hidePulseTab && (
            <ProductAccessCondtionalComponent
              requiredProduct={Product.Response}
            >
              <TabPane tabId={BodyTabs.Pulse}>
                {activities.length < 25 ? (
                  <div className="mt-2">
                    <EmptyTab
                      icon={IconEnum.Activity}
                      subtitle="This incident hasn't had enough activity to build a pulse graph yet."
                    />
                  </div>
                ) : (
                  <ContentBox className="p-2 mt-2 overflow-hidden">
                    <Txt className="p-2">
                      <p>
                        See how activity varied over the course of an incident,
                        as measured by incident actions, messages in the Slack
                        channel, and activity detected in integrations.
                      </p>
                      <p>
                        {`This can be a great prompt before writing ${postmortemNameWithArticle} or
                  running ${debriefNameWithArticleLower}, and can help answer:`}
                      </p>
                      <ol className="my-2 list-decimal list-outside px-5">
                        <li>When was the incident most active?</li>
                        <li>
                          Did we have an appropriate number of people in the
                          channel?
                        </li>
                      </ol>
                    </Txt>
                    <ExploDashboard
                      dashboardEmbedID={
                        ExploDashboardID.IncidentPulseGraphDashboard
                      }
                      dashboardVariables={{
                        incident_id: insightsIncidentID,
                      }}
                      initialHeight="676px" // 676px is the height of the dashboard
                    />
                    <ExploDashboard
                      dashboardEmbedID={
                        ExploDashboardID.IncidentWorkloadPerUserDashboard
                      }
                      dashboardVariables={{
                        incident_id: insightsIncidentID,
                      }}
                      initialHeight="412px" // 412px is the height of the dashboard
                    />
                  </ContentBox>
                )}
              </TabPane>
            </ProductAccessCondtionalComponent>
          )}
        </TabSection>
        <AnimatePresence>
          {incident && showCreateActionModal && (
            <ActionCreateModal
              incidentId={incident.id}
              onClose={() => setShowCreateActionModal(false)}
              isPrivateIncident={
                incident.visibility === IncidentVisibilityEnum.Private
              }
            />
          )}
          {incident && showCreateFollowUpModal && (
            <FollowUpCreateModal
              incidentId={incident.id}
              onClose={() => setShowCreateFollowUpModal(false)}
              isPrivateIncident={
                incident.visibility === IncidentVisibilityEnum.Private
              }
            />
          )}
        </AnimatePresence>
      </div>
    </SummaryContext.Provider>
  );
};
