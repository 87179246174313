/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentTimelineCreateTimelineItemRequestBody,
    IncidentTimelineCreateTimelineItemRequestBodyFromJSON,
    IncidentTimelineCreateTimelineItemRequestBodyToJSON,
    IncidentTimelineCreateTimelineItemResponseBody,
    IncidentTimelineCreateTimelineItemResponseBodyFromJSON,
    IncidentTimelineCreateTimelineItemResponseBodyToJSON,
    IncidentTimelineEditTimelineItemRequestBody,
    IncidentTimelineEditTimelineItemRequestBodyFromJSON,
    IncidentTimelineEditTimelineItemRequestBodyToJSON,
    IncidentTimelineEditTimelineItemResponseBody,
    IncidentTimelineEditTimelineItemResponseBodyFromJSON,
    IncidentTimelineEditTimelineItemResponseBodyToJSON,
    IncidentTimelineListActivityLogResponseBody,
    IncidentTimelineListActivityLogResponseBodyFromJSON,
    IncidentTimelineListActivityLogResponseBodyToJSON,
    IncidentTimelineListTimelineItemsResponseBody,
    IncidentTimelineListTimelineItemsResponseBodyFromJSON,
    IncidentTimelineListTimelineItemsResponseBodyToJSON,
} from '../models';

export interface IncidentTimelineCreateTimelineItemRequest {
    incidentId: string;
    createTimelineItemRequestBody: IncidentTimelineCreateTimelineItemRequestBody;
}

export interface IncidentTimelineDestroyTimelineItemRequest {
    incidentId: string;
    timelineItemId: string;
}

export interface IncidentTimelineEditTimelineItemRequest {
    incidentId: string;
    timelineItemId: string;
    editTimelineItemRequestBody: IncidentTimelineEditTimelineItemRequestBody;
}

export interface IncidentTimelineListActivityLogRequest {
    timezone: string;
    incidentId: string;
}

export interface IncidentTimelineListTimelineItemsRequest {
    timezone: string;
    incidentId: string;
}

/**
 * 
 */
export class IncidentTimelineApi extends runtime.BaseAPI {

    /**
     * Create a new timeline item for an incident
     * CreateTimelineItem IncidentTimeline
     */
    async incidentTimelineCreateTimelineItemRaw(requestParameters: IncidentTimelineCreateTimelineItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimelineCreateTimelineItemResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentTimelineCreateTimelineItem.');
        }

        if (requestParameters.createTimelineItemRequestBody === null || requestParameters.createTimelineItemRequestBody === undefined) {
            throw new runtime.RequiredError('createTimelineItemRequestBody','Required parameter requestParameters.createTimelineItemRequestBody was null or undefined when calling incidentTimelineCreateTimelineItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident-timelines/{incident_id}/timeline`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimelineCreateTimelineItemRequestBodyToJSON(requestParameters.createTimelineItemRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimelineCreateTimelineItemResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new timeline item for an incident
     * CreateTimelineItem IncidentTimeline
     */
    async incidentTimelineCreateTimelineItem(requestParameters: IncidentTimelineCreateTimelineItemRequest, initOverrides?: RequestInit): Promise<IncidentTimelineCreateTimelineItemResponseBody> {
        const response = await this.incidentTimelineCreateTimelineItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a timeline item from an incident
     * DestroyTimelineItem IncidentTimeline
     */
    async incidentTimelineDestroyTimelineItemRaw(requestParameters: IncidentTimelineDestroyTimelineItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentTimelineDestroyTimelineItem.');
        }

        if (requestParameters.timelineItemId === null || requestParameters.timelineItemId === undefined) {
            throw new runtime.RequiredError('timelineItemId','Required parameter requestParameters.timelineItemId was null or undefined when calling incidentTimelineDestroyTimelineItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident-timelines/{incident_id}/timeline/{timeline_item_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"timeline_item_id"}}`, encodeURIComponent(String(requestParameters.timelineItemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a timeline item from an incident
     * DestroyTimelineItem IncidentTimeline
     */
    async incidentTimelineDestroyTimelineItem(requestParameters: IncidentTimelineDestroyTimelineItemRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentTimelineDestroyTimelineItemRaw(requestParameters, initOverrides);
    }

    /**
     * Edit a timeline item for an incident
     * EditTimelineItem IncidentTimeline
     */
    async incidentTimelineEditTimelineItemRaw(requestParameters: IncidentTimelineEditTimelineItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimelineEditTimelineItemResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentTimelineEditTimelineItem.');
        }

        if (requestParameters.timelineItemId === null || requestParameters.timelineItemId === undefined) {
            throw new runtime.RequiredError('timelineItemId','Required parameter requestParameters.timelineItemId was null or undefined when calling incidentTimelineEditTimelineItem.');
        }

        if (requestParameters.editTimelineItemRequestBody === null || requestParameters.editTimelineItemRequestBody === undefined) {
            throw new runtime.RequiredError('editTimelineItemRequestBody','Required parameter requestParameters.editTimelineItemRequestBody was null or undefined when calling incidentTimelineEditTimelineItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incident-timelines/{incident_id}/timeline/{timeline_item_id}`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"timeline_item_id"}}`, encodeURIComponent(String(requestParameters.timelineItemId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentTimelineEditTimelineItemRequestBodyToJSON(requestParameters.editTimelineItemRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimelineEditTimelineItemResponseBodyFromJSON(jsonValue));
    }

    /**
     * Edit a timeline item for an incident
     * EditTimelineItem IncidentTimeline
     */
    async incidentTimelineEditTimelineItem(requestParameters: IncidentTimelineEditTimelineItemRequest, initOverrides?: RequestInit): Promise<IncidentTimelineEditTimelineItemResponseBody> {
        const response = await this.incidentTimelineEditTimelineItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List activities from an incident
     * ListActivityLog IncidentTimeline
     */
    async incidentTimelineListActivityLogRaw(requestParameters: IncidentTimelineListActivityLogRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimelineListActivityLogResponseBody>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling incidentTimelineListActivityLog.');
        }

        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentTimelineListActivityLog.');
        }

        const queryParameters: any = {};

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident-timelines/{incident_id}/activity_log`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimelineListActivityLogResponseBodyFromJSON(jsonValue));
    }

    /**
     * List activities from an incident
     * ListActivityLog IncidentTimeline
     */
    async incidentTimelineListActivityLog(requestParameters: IncidentTimelineListActivityLogRequest, initOverrides?: RequestInit): Promise<IncidentTimelineListActivityLogResponseBody> {
        const response = await this.incidentTimelineListActivityLogRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List timeline items from an incident
     * ListTimelineItems IncidentTimeline
     */
    async incidentTimelineListTimelineItemsRaw(requestParameters: IncidentTimelineListTimelineItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentTimelineListTimelineItemsResponseBody>> {
        if (requestParameters.timezone === null || requestParameters.timezone === undefined) {
            throw new runtime.RequiredError('timezone','Required parameter requestParameters.timezone was null or undefined when calling incidentTimelineListTimelineItems.');
        }

        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentTimelineListTimelineItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident-timelines/{incident_id}/timeline`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentTimelineListTimelineItemsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List timeline items from an incident
     * ListTimelineItems IncidentTimeline
     */
    async incidentTimelineListTimelineItems(requestParameters: IncidentTimelineListTimelineItemsRequest, initOverrides?: RequestInit): Promise<IncidentTimelineListTimelineItemsResponseBody> {
        const response = await this.incidentTimelineListTimelineItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
