import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { SettingsListItemButtonsProps } from "@incident-shared/settings/SettingsList/SettingsListButtons";
import { ButtonTheme, EmptyState, IconEnum, StackedList } from "@incident-ui";
import _ from "lodash";
import React from "react";
import {
  ScopeNameEnum,
  SlimPostmortemTemplate,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { usePostmortemName } from "src/utils/postmortem-name";

import { SettingsListItem } from "../../../@shared/settings/SettingsList/SettingsListItem";
import { SettingsSubHeading } from "../../SettingsSubHeading";

export const PostmortemTemplateList = ({
  templates,
}: {
  templates: SlimPostmortemTemplate[];
}): React.ReactElement => {
  const { identity } = useIdentity();

  const maxTemplatesAvailable =
    identity?.feature_gates.postmortem_templates_count ??
    Number.MAX_SAFE_INTEGER;
  const orgCanCreateTemplates = templates.length < maxTemplatesAvailable;

  return (
    <div>
      <SettingsSubHeading
        title="Templates"
        titleHeadingLevel={2}
        explanation={
          <>
            Configure sections for your post-mortem. Responders will complete
            these sections when drafting the post-mortem, and then export it to
            a destination.
          </>
        }
        className="!mb-4"
      />
      {templates.length > 0 ? (
        <StackedList>
          {_.orderBy(templates, ["name"], ["asc"]).map((template, index) => (
            <PostmortemTemplateRow key={index} postMortemTemplate={template} />
          ))}
        </StackedList>
      ) : (
        <EmptyState
          icon={IconEnum.Doc}
          title={"You have no templates yet"}
          content="Get started with post-mortems by creating a template."
          cta={
            <AddTemplateButton orgCanCreateTemplates={orgCanCreateTemplates} />
          }
        />
      )}
    </div>
  );
};

const PostmortemTemplateRow = ({
  postMortemTemplate,
}: {
  postMortemTemplate: SlimPostmortemTemplate;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const { postmortemNameFormatted } = usePostmortemName(null);

  const buttons: SettingsListItemButtonsProps = {
    requiredScope: ScopeNameEnum.OrganisationSettingsUpdate,
    delete: {
      resourceTitle: postMortemTemplate.name,
      deleteConfirmationTitle: `Delete ${postmortemNameFormatted} template`,
      deleteConfirmationContent: (
        <span>
          Are you sure you want to delete the{" "}
          <span className="font-semibold">{postMortemTemplate.name}</span>{" "}
          template?
        </span>
      ),
      // TODO in Delete chunk: implement deleting
      onDelete: () => undefined,
    },
    // TODO [PINC-3346]: Check that editing takes you to the drawer correctly
    edit: {
      editHref: `/settings/post-mortem/templates-v2/${postMortemTemplate.id}/edit`,
    },
    view: {
      viewHref: `/settings/post-mortem/templates-v2/${postMortemTemplate.id}/edit`,
    },
  };

  if (hasScope(ScopeNameEnum.OrganisationSettingsUpdate)) {
    delete buttons.view;
  } else {
    delete buttons.edit;
  }

  return <SettingsListItem title={postMortemTemplate.name} buttons={buttons} />;
};

const AddTemplateButton = ({
  orgCanCreateTemplates,
}: {
  orgCanCreateTemplates: boolean;
}): React.ReactElement => {
  return (
    <GatedButton
      // TODO [PINC-3346]: Check that this button takes you to the drawer correctly
      href="/settings/post-mortem/templates-v2/create"
      requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
      analyticsTrackingId="create-postmortem-template-v2"
      icon={IconEnum.Add}
      theme={ButtonTheme.Secondary}
      upgradeRequired={!orgCanCreateTemplates}
      upgradeRequiredProps={{
        gate: {
          type: "numeric",
          value: 5,
          featureNameSingular: `post-mortem template`,
        },
        featureName: `post-mortem templates`,
      }}
    >
      Add template
    </GatedButton>
  );
};
