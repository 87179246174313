import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import { TemplatedTextDisplay } from "@incident-shared/forms/v1/TemplatedText/TemplatedTextDisplay";
import { ConditionGroupsEditorV2 } from "@incident-shared/forms/v2/editors/ConditionGroupsEditorV2";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { CheckboxV2 } from "@incident-shared/forms/v2/inputs/CheckboxV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { SecondaryNavSubPageWrapper } from "@incident-shared/layout/SecondaryNav";
import {
  OrgAwareNavLink,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { SlackChannelNameEditFormInner } from "@incident-shared/settings";
import { AutoCloseDelayOptions } from "@incident-shared/settings";
import {
  Button,
  ButtonTheme,
  ContentBox,
  FloatingFooter,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Link,
  Loader,
  ModalContent,
  ModalFooter,
  StackedList,
  StackedListItem,
  Toggle,
  Tooltip,
  Txt,
} from "@incident-ui";
import { StaticSingleSelectWithObj } from "@incident-ui/Select/StaticSingleSelect";
import { SelectOption, SelectValue } from "@incident-ui/Select/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import _, { sortBy } from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  CustomField,
  IncidentApplicableFields,
  IncidentChannelConfig,
  IncidentChannelConfigFormatEnum,
  IncidentChannelSettings,
  IncidentLifecycle,
  IncidentRole,
  IncidentRoleRoleTypeEnum as RoleTypeEnum,
  IncidentType,
  IncidentTypesCreateRequestBody,
  IncidentTypesUpdateRequestBody,
  IncidentTypesUpdateRequestBodyCreateInTriageEnum,
  IncidentTypeWithConditions,
  IncidentTypeWithConditionsCreateInTriageEnum,
  LegacyPostmortemTemplate,
  PostmortemDestination,
  Severity,
  SeverityAliasPayload,
  TextNode,
} from "src/contexts/ClientContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useSettings } from "src/hooks/useSettings";
import { usePostmortemName } from "src/utils/postmortem-name";
import { getEmptyScope } from "src/utils/scope";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { useIncidentManualTriageIncidentsEnabled } from "../../../../utils/incident-manual-triage";
import {
  customFieldTypeToIcon,
  customFieldTypeToTypeName,
} from "../../custom-fields/common/utils";
import { PostmortemNameInput } from "../../post-mortem/sensible-defaults/PostmortemNameForm";
import styles from "./IncidentTypes.module.scss";
import { TypeAttributeListItem } from "./TypeAttributeListItem";

type SeverityAliasFormType = Omit<
  SeverityAliasPayload,
  "override_description"
> & {
  // typescript is sad about circular deps :sob:
  override_description?: {
    markdown?: string;
    text_node: TextNode;
  }; // eslint-disable-line @typescript-eslint/no-explicit-any
};
type SeverityAliasMap = {
  [severityId: string]: SeverityAliasFormType;
};

type IncidentChannelConfigFormData = IncidentChannelConfig & {
  // Typescript gets upset about different enums for request and response bodies, despite them being identical
  format: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  // Typescript can't deal with the nested nature of the TextNode type
  custom_format: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type IncidentTypeFormT = IncidentTypeWithConditions & {
  includedCustomFieldIDs: string[];
  includedCustomRoleIDs: string[];
  severityAliases: SeverityAliasMap;
  must_use_triage: boolean;
  channel_config: IncidentChannelConfigFormData | null;
  // The API uses number for these fields but SelectOption values must be strings
  auto_close_incidents_delay_days_string: string;
  auto_archive_slack_channels_delay_days_string: string;
};

export const IncidentTypesCreateEditForm = ({
  mode,
  initialData,
}: CreateEditFormProps<IncidentTypeWithConditions>): React.ReactElement => {
  const incidentTypeID = initialData?.id;

  const {
    data: { custom_fields: unsortedCustomFields },
    error: customFieldError,
    isLoading: customFieldsLoading,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const customFields = sortBy(unsortedCustomFields, "rank");

  const {
    data: { incident_roles: roles },
    error: rolesError,
    isLoading: rolesLoading,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const {
    data: { severities },
    error: severitiesError,
    isLoading: severitiesLoading,
  } = useAPI("severitiesList", undefined, { fallbackData: { severities: [] } });

  const { data: applicableFields, error: applicableFieldsError } = useAPI(
    "incidentTypesBuildApplicableFields",
    {
      buildApplicableFieldsRequestBody: {
        incident_type_id: incidentTypeID,
      },
    },
  );

  const {
    data: { postmortem_templates: postmortemTemplates },
    error: postmortemTemplatesError,
    isLoading: postmortemTemplatesLoading,
  } = useAPI(
    "postmortemsListLegacyTemplates",
    {},
    {
      fallbackData: { postmortem_templates: [] },
    },
  );

  const {
    data: { destinations: postmortemDestinations },
    error: postmortemDestinationsError,
    isLoading: postmortemDestinationsLoading,
  } = useAPI("postmortemsListDestinations", undefined, {
    fallbackData: { destinations: [] },
  });

  const {
    data: { incident_lifecycles: lifecycles },
    error: lifecyclesError,
    isLoading: lifecyclesLoading,
  } = useAPI("incidentLifecyclesList", undefined, {
    fallbackData: { incident_lifecycles: [] },
  });

  const isLoading =
    customFieldsLoading ||
    rolesLoading ||
    severitiesLoading ||
    postmortemTemplatesLoading ||
    postmortemDestinationsLoading ||
    lifecyclesLoading;

  const error =
    customFieldError ||
    rolesError ||
    severitiesError ||
    applicableFieldsError ||
    postmortemTemplatesError ||
    postmortemDestinationsError ||
    lifecyclesError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (isLoading || !applicableFields) {
    return <Loader />;
  }

  return (
    <ActualForm
      allCustomFields={customFields}
      allRoles={roles}
      allSeverities={_.sortBy(severities, ({ rank }) => -rank)}
      applicableFields={applicableFields}
      incidentType={initialData}
      mode={mode}
      postmortemTemplates={postmortemTemplates}
      postmortemDestinations={postmortemDestinations}
      lifecycles={lifecycles}
    />
  );
};

const ActualForm = ({
  allCustomFields,
  allRoles,
  allSeverities,
  applicableFields,
  incidentType,
  mode,
  postmortemTemplates,
  postmortemDestinations,
  lifecycles,
}: {
  allCustomFields: CustomField[];
  allRoles: IncidentRole[];
  allSeverities: Severity[];
  applicableFields: IncidentApplicableFields;
  incidentType?: IncidentTypeWithConditions;
  mode: Mode;
  postmortemTemplates: LegacyPostmortemTemplate[];
  postmortemDestinations: PostmortemDestination[];
  lifecycles: IncidentLifecycle[];
}): React.ReactElement => {
  const isEditing = mode === Mode.Edit && incidentType != null;
  const navigate = useOrgAwareNavigate();

  const { settings } = useSettings();

  const flags = useFlags();

  const commsPlatform = usePrimaryCommsPlatform();

  // We don't show the reporter role _at all_ here: for now the only core role is the lead.
  const coreRoles = allRoles.filter(
    (role) => role.role_type !== RoleTypeEnum.Custom,
  );
  const visibleCoreRoles = coreRoles.filter(
    (role) => role.role_type !== RoleTypeEnum.Reporter,
  );
  const customRoles = allRoles.filter(
    (role) => role.role_type === RoleTypeEnum.Custom,
  );
  const customRoleIDs = customRoles.map((role) => role.id);

  const [selectedCustomFieldIDs, setSelectedCustomFieldIDs] = useState<
    string[]
  >(applicableFields.custom_fields);
  const [selectedRoleIDs, setSelectedCustomRoleIDs] = useState<string[]>(
    applicableFields.roles.filter((roleID) => customRoleIDs.includes(roleID)),
  );

  const isIncidentManualTriageEnabled =
    useIncidentManualTriageIncidentsEnabled(settings);

  // If we have an incident type, convert its severity aliases into the
  // structure the form can use.
  const initialAliases = {};
  (incidentType?.severity_aliases || []).forEach((alias) => {
    initialAliases[alias.severity_id] = alias;
  });

  let lifecycle = lifecycles.find((x) => x.is_default);
  if (incidentType?.override_incident_lifecycle_id) {
    lifecycle = lifecycles.find(
      (x) => x.id === incidentType.override_incident_lifecycle_id,
    );
  }

  const formMethods = useForm<IncidentTypeFormT>({
    defaultValues: {
      override_auto_archive_slack_channels: false,
      override_auto_close_incidents: false,
      // This will do nothing for the create form
      ...(incidentType ?? {}),
      must_use_triage:
        incidentType?.create_in_triage ===
        IncidentTypeWithConditionsCreateInTriageEnum.Always,
      channel_config:
        incidentType?.incident_channel_name_override?.channel_config,
      auto_archive_slack_channels_delay_days_string:
        incidentType?.auto_archive_slack_channels_delay_days.toString(),
      auto_close_incidents_delay_days_string:
        incidentType?.auto_close_incidents_delay_days.toString(),
      condition_groups: incidentType?.condition_groups ?? [],
    },
  });
  const {
    formState: { errors },
    setError,
    clearErrors,
    watch,
    setValue,
  } = formMethods;

  const [
    overrideAutoCloseIncidents,
    autoCloseIncidents,
    autoCloseIncidentsDelayDaysString,
    overrideAutoArchiveSlackChannels,
    autoArchiveSlackChannels,
    autoArchiveSlackChannelsDelayDaysString,
  ] = watch([
    "override_auto_close_incidents",
    "auto_close_incidents",
    "auto_close_incidents_delay_days_string",
    "override_auto_archive_slack_channels",
    "auto_archive_slack_channels",
    "auto_archive_slack_channels_delay_days_string",
  ]);

  const [severityAliases, setSeverityAliases] =
    useState<SeverityAliasMap>(initialAliases);

  const getSeverityAlias = (severity: Severity): SeverityAliasFormType =>
    severityAliases[severity.id] || defaultAlias(severity);

  const hasConfig = (data: {
    channel_config: IncidentChannelConfig | undefined | null;
  }): data is IncidentChannelSettings => !!data.channel_config;

  const sanitizeNameOverride = (formData: {
    channel_config: IncidentChannelConfig | undefined | null;
  }): IncidentChannelSettings | undefined => {
    if (!hasConfig(formData)) {
      return undefined;
    }
    if (
      formData.channel_config.format !== IncidentChannelConfigFormatEnum.Custom
    ) {
      delete formData.channel_config.custom_format;
    }
    if (
      [
        IncidentChannelConfigFormatEnum.Inc,
        IncidentChannelConfigFormatEnum.IncId,
      ].includes(formData.channel_config.format)
    ) {
      delete formData.channel_config.timezone;
    }
    return formData;
  };

  const refetchType = useAPIRefetch("incidentTypesShow", {
    id: incidentType?.id as unknown as string, // We're only going to call this if we have an incident type
  });
  const refetchApplicableCustomFields = useAPIRefetch(
    "incidentTypesBuildApplicableFields",
    {
      buildApplicableFieldsRequestBody: {
        incident_type_id: incidentType?.id,
      },
    },
  );
  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "incidentTypesList",
    undefined,
    async (apiClient, data: IncidentTypeFormT) => {
      const {
        name,
        description,
        private_incidents_only,
        default_postmortem_destination_id,
        default_postmortem_template_id,
        must_use_triage,
        channel_config,
        postmortem_name_override,
        condition_groups,
      } = data;

      // Core roles are always applicable, so add them to the list of opted-in
      // custom roles
      const allApplicableRoleIDs = coreRoles
        .map((role) => role.id)
        .concat(selectedRoleIDs);

      const body: IncidentTypesUpdateRequestBody = {
        name,
        description,
        private_incidents_only: private_incidents_only || false,
        applicable_fields: {
          custom_fields: selectedCustomFieldIDs,
          roles: allApplicableRoleIDs,
        },
        severity_aliases: Object.values(severityAliases).map(
          (a): SeverityAliasPayload => ({
            hidden: a.hidden,
            severity_id: a.severity_id,
            override_description: a.override_description,
          }),
        ),
        default_postmortem_destination_id,
        default_postmortem_template_id,
        create_in_triage: must_use_triage
          ? IncidentTypesUpdateRequestBodyCreateInTriageEnum.Always
          : IncidentTypesUpdateRequestBodyCreateInTriageEnum.Optional,
        incident_channel_name_override: sanitizeNameOverride({
          channel_config,
        }),
        override_auto_archive_slack_channels:
          overrideAutoArchiveSlackChannels ?? false,
        auto_archive_slack_channels: overrideAutoArchiveSlackChannels
          ? !!autoArchiveSlackChannels
          : false,
        auto_archive_slack_channels_delay_days:
          overrideAutoArchiveSlackChannels && autoArchiveSlackChannels
            ? parseInt(autoArchiveSlackChannelsDelayDaysString)
            : 0,
        override_auto_close_incidents: overrideAutoCloseIncidents ?? false,
        auto_close_incidents: overrideAutoCloseIncidents
          ? !!autoCloseIncidents
          : false,
        auto_close_incidents_delay_days:
          overrideAutoCloseIncidents && autoCloseIncidents
            ? parseInt(autoCloseIncidentsDelayDaysString)
            : 0,
        postmortem_name_override: overridePostmortemName
          ? postmortem_name_override
          : undefined,
        condition_groups: conditionGroupsToGroupPayloads(condition_groups),
      };

      if (isEditing) {
        await apiClient.incidentTypesUpdate({
          id: incidentType.id,
          updateRequestBody: body,
        });
      } else {
        await apiClient.incidentTypesCreate({
          createRequestBody: body as unknown as IncidentTypesCreateRequestBody,
        });
      }
    },
    {
      setError: (name, error) => {
        if (name.startsWith("applicable_fields.custom_fields")) {
          return setError("includedCustomFieldIDs", error);
        }

        if (name.startsWith("applicable_fields.roles")) {
          return setError("includedCustomRoleIDs", error);
        }

        if (name.startsWith("severity_aliases")) {
          return setError("severityAliases", error);
        }

        return setError(name, error);
      },
      onSuccess: () => {
        // We need to invalidate the "show" cache for this type as well as "list" so
        // we load the correct defaults when we next open the form
        refetchType();
        // We also need to invalidate the cache of which custom fields have been applied.
        refetchApplicableCustomFields();

        navigate("/settings/incident-types");
      },
    },
  );
  const [usingCustomChannelName, setUsingCustomChannelName] = useState(
    !!incidentType?.incident_channel_name_override,
  );

  const [overridePostmortemName, setOverridePostmortemName] = useState(
    !!incidentType?.postmortem_name_override,
  );

  const [editingSeverity, setEditingSeverity] = useState<Severity | null>(null);

  function onAddRole(roleToAdd: SelectValue) {
    if (roleToAdd == null) return;
    const newRole = customRoles.find((r) => r.id === roleToAdd);
    if (newRole == null) return;

    setSelectedCustomRoleIDs([...selectedRoleIDs, newRole.id]);
    clearErrors();
  }

  function onRemoveRole(roleId: string) {
    const newRoleIDs = selectedRoleIDs.filter((id) => id !== roleId);
    setSelectedCustomRoleIDs(newRoleIDs);
    clearErrors();
  }

  const visibleRoles = visibleCoreRoles.concat(
    customRoles.filter((role) => selectedRoleIDs.includes(role.id)),
  );
  const addableRoles = customRoles.filter(
    (role) => !selectedRoleIDs.includes(role.id),
  );

  function onAddCustomField(customFieldToAdd: SelectValue) {
    if (customFieldToAdd == null) return;
    const newField = allCustomFields.find((f) => f.id === customFieldToAdd);
    if (newField == null) return;

    setSelectedCustomFieldIDs([...selectedCustomFieldIDs, newField.id]);
    clearErrors();
  }

  function onRemoveCustomField(fieldId: string) {
    const newFieldIDs = selectedCustomFieldIDs.filter((id) => id !== fieldId);
    setSelectedCustomFieldIDs(newFieldIDs);
    clearErrors();
  }

  const includedCustomFields = allCustomFields.filter((field) =>
    selectedCustomFieldIDs.includes(field.id),
  );
  const addableCustomFields = allCustomFields.filter(
    (field) => !selectedCustomFieldIDs.includes(field.id),
  );

  const updateSeverityAlias = (
    severity: Severity,
    changes: Partial<SeverityAliasFormType>,
  ) => {
    const alias = getSeverityAlias(severity);

    setSeverityAliases({
      ...severityAliases,
      [severity.id]: {
        ...alias,
        ...changes,
      },
    });
    clearErrors();
  };

  const hideSeverity = (severity: Severity) => {
    updateSeverityAlias(severity, { hidden: true });
  };

  const showSeverity = (severity: Severity) => {
    updateSeverityAlias(severity, { hidden: false });
  };

  // You can only hide severities if at least 2 are visible (i.e. you can't hide
  // the last one!)
  const canHideSeverity =
    allSeverities.filter((sev) => !getSeverityAlias(sev).hidden).length > 1;

  const postmortemTemplateOptions = postmortemTemplates.map(
    (i): SelectOption => {
      return { label: i.name, value: i.id };
    },
  );

  const postmortemDestinationOptions = postmortemDestinations.map(
    (i): SelectOption => {
      return { label: i.name, value: i.id };
    },
  );

  const { postmortemName, postmortemNameFormatted, postmortemNameWithArticle } =
    usePostmortemName({
      incident_type: incidentType as unknown as IncidentType,
    });

  const clearAutoCloseIncidentsDelayDays = () => {
    setValue<"auto_close_incidents_delay_days_string">(
      "auto_close_incidents_delay_days_string",
      "0",
    );
  };

  const clearAutoArchiveSlackChannelsDelayDays = () => {
    setValue<"auto_archive_slack_channels_delay_days_string">(
      "auto_archive_slack_channels_delay_days_string",
      "0",
    );
  };

  const {
    data: scopeData,
    isLoading: scopeLoading,
    error: scopeError,
  } = useAPI("engineBuildScope", {
    buildScopeRequestBody: {
      scope: [
        {
          type: "User",
          label: "User",
          key: "user",
          array: false,
        },
      ],
    },
  });

  if (scopeError) {
    return <GenericErrorMessage error={scopeError} />;
  }

  if (scopeLoading) {
    return <Loader />;
  }

  return (
    <SecondaryNavSubPageWrapper
      title={isEditing ? "Edit incident type" : "Create incident type"}
      backHref="/settings/incident-types"
      icon={IconEnum.IncidentType}
      crumbs={[
        {
          title: "Incident types",
          to: "/settings/incident-types",
        },
      ]}
    >
      <Form.Root
        onSubmit={onSubmit}
        formMethods={formMethods}
        genericError={genericError}
      >
        <ContentBox className="p-4">
          <InputV2
            formMethods={formMethods}
            name="name"
            label="Name"
            helptext="An easily identifiable name that people can select when creating a new incident."
            data-testid="name-input"
            required="Please enter a name"
          />
        </ContentBox>
        <ContentBox className="p-4">
          <TextareaV2
            formMethods={formMethods}
            name="description"
            label="Description"
            helptext="A short description of this incident type."
            data-testid="description-textbox"
            required="Please enter a description"
          />
        </ContentBox>
        <ContentBox className="p-4 space-y-4">
          <div>
            <Heading level={2} className="font-medium">
              Custom fields
            </Heading>
            <Form.Helptext>
              Choose the custom fields that should apply to this incident type.
            </Form.Helptext>
          </div>
          <Form.ErrorMessage errors={errors} name={"includedCustomFieldIDs"} />
          <StackedList className={styles.attributeList}>
            {includedCustomFields.map((field) => (
              <TypeAttributeListItem
                key={field.id}
                icon={customFieldTypeToIcon(field.field_type)}
                iconTooltip={customFieldTypeToTypeName(field.field_type)}
                name={field.name}
                onDelete={() => {
                  onRemoveCustomField(field.id);
                }}
              />
            ))}
          </StackedList>
          <div>
            <Form.Label htmlFor="customField" required={false}>
              Add custom field
            </Form.Label>
            <Form.Helptext>
              Choose another custom field to assign to this incident type
            </Form.Helptext>
            <div
              className={`${styles.attributeSelect} flex-center-y space-x-2`}
            >
              <StaticSingleSelectWithObj
                options={addableCustomFields.map(customFieldToSelectOption)}
                value={null}
                onChange={(opt: SelectOption) => onAddCustomField(opt?.value)}
                placeholder="Choose a field"
                isClearable={false}
              />
            </div>
          </div>
        </ContentBox>
        <ContentBox className="p-4 space-y-4">
          <div>
            <Heading level={2} className="font-medium">
              Roles
            </Heading>
            <p className="text-sm text-slate-700">
              Choose the roles that should apply to this incident type.
            </p>
          </div>
          <Form.ErrorMessage errors={errors} name={"includedCustomRoleIDs"} />
          <StackedList className={styles.attributeList}>
            {visibleRoles.map((role) => (
              <TypeAttributeListItem
                key={role.id}
                icon={IconEnum.User}
                name={role.name}
                onDelete={
                  role.role_type === RoleTypeEnum.Custom
                    ? () => {
                        onRemoveRole(role.id);
                      }
                    : undefined
                }
              />
            ))}
          </StackedList>
          <div>
            <Form.Label htmlFor="incidentRole">Add role</Form.Label>
            <Form.Helptext>
              Choose another role to assign to this incident type
            </Form.Helptext>
            <div
              className={`${styles.attributeSelect} flex-center-y space-x-2`}
            >
              <StaticSingleSelectWithObj
                options={addableRoles.map(roleToSelectOption)}
                value={null}
                onChange={(opt: SelectOption) => onAddRole(opt?.value)}
                placeholder="Choose a role"
                isClearable={false}
              />
            </div>
          </div>
        </ContentBox>
        <ContentBox className="p-4 space-y-4">
          <div>
            <Heading level={2} className="font-medium">
              Severities
            </Heading>
            <Txt grey>
              <p className="!mb-2">
                Choose how your severities appear for this incident type.
              </p>
              <p>
                Add override description text to give more context on what a
                severity level means for this type.
                <br />
                Hide the severity level if it is not relevant for this incident
                type.
              </p>
            </Txt>
          </div>
          <Form.ErrorMessage errors={errors} name={"severityAlias"} />
          <StackedList>
            {allSeverities.map((severity) => (
              <SeverityRow
                key={`severity.${severity.id}`}
                severity={severity}
                severityAlias={getSeverityAlias(severity)}
                setEditingSeverity={setEditingSeverity}
                canHideSeverity={canHideSeverity}
                hideSeverity={hideSeverity}
                showSeverity={showSeverity}
              />
            ))}
          </StackedList>
        </ContentBox>
        {lifecycles.length > 1 && (
          <ContentBox className="p-4 space-y-2">
            <Heading level={2} className="font-medium">
              Lifecycle
            </Heading>
            <Txt grey>
              Incidents of this type will use the{" "}
              <Txt inline bold>
                {lifecycle?.name}
              </Txt>{" "}
              lifecycle. You can view your lifecycle settings in{" "}
              <OrgAwareNavLink
                className="underline"
                to="/settings/lifecycle"
                target="_blank"
              >
                Settings → Lifecycle
              </OrgAwareNavLink>
              .
            </Txt>
          </ContentBox>
        )}
        <ContentBox className="p-4 flex flex-col space-y-4">
          <div>
            <Heading level={2} className="font-medium">
              Channel naming
            </Heading>
            <p className="text-sm text-slate-700 mb-4">
              Configure the naming convention for this incident type
            </p>
            <Toggle
              id="custom-channel-name"
              on={usingCustomChannelName}
              align="left"
              label="Override default naming convention"
              onToggle={() => {
                if (usingCustomChannelName) {
                  formMethods.resetField<"channel_config">("channel_config", {
                    defaultValue: null,
                  });
                }
                setUsingCustomChannelName((prev) => !prev);
              }}
            />
          </div>
          {usingCustomChannelName && (
            <SlackChannelNameEditFormInner formMethods={formMethods} />
          )}
        </ContentBox>
        <ContentBox className="p-4 space-y-4">
          <div>
            <Heading level={2} className="font-medium">
              {postmortemName}
            </Heading>
            <Txt grey>
              {`Choose default ${postmortemNameFormatted} settings for this incident type.`}
            </Txt>
          </div>
          <StaticSingleSelectV2
            formMethods={formMethods}
            name="default_postmortem_template_id"
            placeholder={`Select ${postmortemNameWithArticle} template`}
            options={postmortemTemplateOptions}
            label="Default template"
            helptext={`Select the default ${postmortemNameFormatted} template for this incident type. You'll be able to change the selection on export if needed.`}
          />
          {postmortemDestinationOptions.length > 0 && (
            <StaticSingleSelectV2
              formMethods={formMethods}
              name="default_postmortem_destination_id"
              placeholder={`Select ${postmortemNameWithArticle} destination`}
              options={postmortemDestinationOptions}
              label="Default destination"
              helptext={`Select the default ${postmortemNameFormatted} destination for this incident type. You'll be able to change the selection on export if needed.`}
            />
          )}
          <div className="space-y-2">
            <Toggle
              id="custom-postmortem-name"
              on={overridePostmortemName}
              align="left"
              label={`Override name`}
              onToggle={() => {
                setOverridePostmortemName((prev) => {
                  if (prev) {
                    formMethods.resetField<"postmortem_name_override">(
                      "postmortem_name_override",
                      {
                        defaultValue: undefined,
                      },
                    );
                  }
                  return !prev;
                });
              }}
            />
            {overridePostmortemName && (
              <PostmortemNameInput
                formMethods={formMethods}
                name="postmortem_name_override"
              />
            )}
          </div>
        </ContentBox>
        <ContentBox className="p-4 pb-5 space-y-2">
          <Heading level={2} className="font-medium">
            Auto-close incidents
          </Heading>
          <Txt grey>
            <p className=" mb-4">
              With this feature enabled, we&apos;ll attempt to automatically
              close incidents of this incident type where we haven&apos;t seen
              any activity within the configured time period.
            </p>
            <p className=" mb-4">
              You can set this for all incident types{" "}
              <Link
                analyticsTrackingId="incident-type-settings-auto-close-link"
                href="/settings/automation"
              >
                in your automation settings
              </Link>
              , and override it for this specific incident type here.
            </p>
          </Txt>
          <ToggleV2
            name="override_auto_close_incidents"
            formMethods={formMethods}
            label="Override default auto-close settings"
            align="left"
            onValueChange={(toggled) => {
              if (!toggled) {
                clearAutoCloseIncidentsDelayDays();
              }
            }}
          />
          {overrideAutoCloseIncidents && (
            <div className="flex flew-row items-center gap-2">
              <CheckboxV2
                name="auto_close_incidents"
                formMethods={formMethods}
                label="Auto-close incidents of this type after"
                onValueChange={(checked) => {
                  if (!checked) {
                    clearAutoCloseIncidentsDelayDays();
                  }
                }}
              />

              <StaticSingleSelectV2
                options={AutoCloseDelayOptions}
                name={"auto_close_incidents_delay_days_string"}
                formMethods={formMethods}
                isClearable={false}
                disabled={!autoCloseIncidents}
                rules={{
                  validate: (value, _data) => {
                    if (
                      !!_data.override_auto_close_incidents &&
                      !!_data.auto_close_incidents &&
                      !parseInt(value)
                    ) {
                      return "Please select a delay";
                    }
                    return true;
                  },
                }}
              />
            </div>
          )}
        </ContentBox>
        {commsPlatform === CommsPlatform.Slack && (
          <ContentBox className="p-4 pb-5 space-y-2">
            <Heading level={2} className="font-medium">
              Auto-archive Slack channels
            </Heading>
            <Txt grey>
              <p className="mb-4">
                With this feature enabled, we&apos;ll attempt to automatically
                archive incident Slack channels for incidents of this type where
                we haven&apos;t seen any activity within the configured time
                period.
              </p>
              <p className="mb-4">
                You can set this for all incident types{" "}
                <Link
                  analyticsTrackingId="incident-type-settings-auto-close-link"
                  href="/settings/automation"
                >
                  in your automation settings
                </Link>
                , and override it for this specific incident type here.
              </p>
            </Txt>
            <ToggleV2
              name="override_auto_archive_slack_channels"
              formMethods={formMethods}
              align="left"
              label="Override default auto-archive settings"
              onValueChange={(toggled) => {
                if (!toggled) {
                  clearAutoArchiveSlackChannelsDelayDays();
                }
              }}
            />
            {overrideAutoArchiveSlackChannels && (
              <div className="flex flew-row items-center gap-2">
                <CheckboxV2
                  name="auto_archive_slack_channels"
                  formMethods={formMethods}
                  label="Auto-archive Slack channels for incidents of this type after"
                  onValueChange={(checked) => {
                    if (!checked) {
                      clearAutoArchiveSlackChannelsDelayDays();
                    }
                  }}
                />

                <StaticSingleSelectV2
                  options={AutoCloseDelayOptions}
                  name={"auto_archive_slack_channels_delay_days_string"}
                  formMethods={formMethods}
                  isClearable={false}
                  disabled={!autoArchiveSlackChannels}
                  rules={{
                    validate: (value, _data) => {
                      if (
                        !!_data.override_auto_archive_slack_channels &&
                        !!_data.auto_archive_slack_channels &&
                        !parseInt(value)
                      ) {
                        return "Please select a delay";
                      }
                      return true;
                    },
                  }}
                />
              </div>
            )}
          </ContentBox>
        )}
        <ContentBox className="p-4 pb-5 space-y-2">
          <Heading level={2} className="font-medium">
            Restrictions
          </Heading>
          {/*
              Feature flag here should be used lightly!

              https://linear.app/incident-io/issue/PR-692/create-a-feature-flag-to-allow-orgs-to-restrict-the-default-type
           */}
          {incidentType?.is_default && !flags.restrictDefaultIncidents ? (
            <Txt grey>
              <p className="mb-4">
                This type is currently set as your <b>default</b>, and cannot be
                restricted.
              </p>
            </Txt>
          ) : (
            <>
              <Txt grey>
                Optionally restrict which users can declare an active incident
                or accept a triage incident of this type.
              </Txt>
              <ConditionGroupsEditorV2
                formMethods={formMethods}
                name="condition_groups"
                scope={scopeData?.scope || getEmptyScope()}
                entityNameLabel={"incident type"}
                subjectsLabel={"users"}
                explanationStyle="available"
                wrapperClassName="mt-4"
              />
            </>
          )}
        </ContentBox>
        <ContentBox className="p-4 pb-5 space-y-2">
          <Heading level={2} className="font-medium">
            Options
          </Heading>
          <div className="flex flex-col space-y-2">
            {commsPlatform === CommsPlatform.Slack && (
              <ToggleV2
                formMethods={formMethods}
                name="private_incidents_only"
                align="left"
                label="Make incidents of this type private by default"
                description="If enabled, we'll make all new incidents of this type private"
              />
            )}
            {isIncidentManualTriageEnabled && (
              <ToggleV2
                formMethods={formMethods}
                name="must_use_triage"
                align="left"
                label="Make incidents of this type always start with triage"
                description={
                  <span>
                    If enabled, incidents of this type will be created in the
                    triage status. Responders can choose to accept or decline an
                    incident in triage, once they&apos;ve investigated the
                    potential issue.{" "}
                    <Link
                      href={
                        "https://help.incident.io/en/articles/6878776-triaging-your-incidents"
                      }
                      openInNewTab
                      analyticsTrackingId={"find-out-more-triage"}
                    >
                      Find out more about triage incidents
                    </Link>
                    .
                  </span>
                }
              />
            )}
          </div>
        </ContentBox>
        <FloatingFooter error={genericError}>
          <div className="flex gap-2">
            <Button
              analyticsTrackingId="incident-type-create-edit-form-cancel"
              href="/settings/incident-types"
              className="flex-center"
            >
              Cancel
            </Button>
            <Button
              analyticsTrackingId="incident-type-create-edit-form-submit"
              type="submit"
              theme={ButtonTheme.Primary}
              className="flex-center"
              loading={saving}
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </div>
        </FloatingFooter>
      </Form.Root>
      {editingSeverity ? (
        <OverrideSeverityDescriptionModal
          severity={editingSeverity}
          severityAlias={getSeverityAlias(editingSeverity)}
          onConfirm={(data: OverrideFormData) => {
            // Apply the new description to the severity alias
            editingSeverity &&
              updateSeverityAlias(
                editingSeverity,
                data as Partial<SeverityAliasFormType>,
              );

            // Close the modal
            setEditingSeverity(null);
          }}
          onClose={() => {
            // Close the modal
            setEditingSeverity(null);
          }}
        />
      ) : null}
    </SecondaryNavSubPageWrapper>
  );
};

const SeverityRow = ({
  severityAlias,
  severity,
  setEditingSeverity,
  canHideSeverity,
  hideSeverity,
  showSeverity,
}: {
  severityAlias: SeverityAliasFormType;
  severity: Severity;
  setEditingSeverity: (severity: Severity) => void;
  canHideSeverity: boolean;
  hideSeverity: (severity: Severity) => void;
  showSeverity: (severity: Severity) => void;
}): React.ReactElement => {
  let description: React.ReactElement | null = (
    <TemplatedTextDisplay
      className="text-sm text-slate-700 mt-2"
      value={severity.description.text_node}
    />
  );

  if (severityAlias.override_description) {
    description = (
      <>
        <TemplatedTextDisplay
          className="text-sm text-content-tertiary line-through mt-2 mb-4"
          value={severity.description.text_node}
        />
        <div className="text-sm font-medium mt-2 mb-1">
          Will be overriden with:
        </div>
        <TemplatedTextDisplay
          className="text-sm text-slate-700"
          value={severityAlias.override_description.text_node}
        />
      </>
    );
  }

  if (severityAlias.hidden) {
    description = null;
  }

  return (
    <StackedListItem
      title={
        <span className={severityAlias.hidden ? "line-through opacity-50" : ""}>
          {severity.name}
        </span>
      }
      description={description}
      accessory={
        <SeverityButtons
          severity={severity}
          severityAlias={severityAlias}
          setEditingSeverity={setEditingSeverity}
          canHideSeverity={canHideSeverity}
          hideSeverity={hideSeverity}
          showSeverity={showSeverity}
        />
      }
    />
  );
};

const SeverityButtons = ({
  severity,
  severityAlias,
  setEditingSeverity,
  canHideSeverity,
  hideSeverity,
  showSeverity,
}: {
  severity: Severity;
  severityAlias: SeverityAliasFormType;
  setEditingSeverity: (severity: Severity) => void;
  canHideSeverity: boolean;
  hideSeverity: (severity: Severity) => void;
  showSeverity: (severity: Severity) => void;
}): React.ReactElement => {
  // The "hide/show" button is disabled if:
  // 1. the sev is not hidden (i.e. you can always un-hide a sev!)
  // 2. canHideSeverity is false: this means we're the last lonely severity and
  //    can't be disabled
  const disabled = severityAlias.hidden ? false : !canHideSeverity;
  const showHideButton = (
    <Button
      theme={ButtonTheme.Tertiary}
      iconProps={{ size: IconSize.Medium }}
      icon={severityAlias.hidden ? IconEnum.View : IconEnum.Hide}
      analyticsTrackingId={
        severityAlias.hidden
          ? "incident-types-severity-show"
          : "incident-types-severity-hide"
      }
      className="!px-2"
      title={
        severityAlias.hidden
          ? "Show severity for this type"
          : "Hide severity for this type"
      }
      disabled={severityAlias.hidden ? false : !canHideSeverity}
      onClick={
        severityAlias.hidden
          ? () => showSeverity(severity)
          : () => hideSeverity(severity)
      }
    />
  );

  const showHideButtonWithTooltip = disabled ? (
    <Tooltip content={"At least one severity must be enabled"} side="left">
      <span>{showHideButton}</span>
    </Tooltip>
  ) : (
    showHideButton
  );

  return (
    <>
      {severityAlias.hidden ? null : (
        <Button
          icon={IconEnum.Edit}
          iconProps={{ size: IconSize.Medium }}
          analyticsTrackingId="incident-types-severity-override-description"
          className={"!px-2"}
          title="Edit"
          onClick={() => setEditingSeverity(severity)}
          theme={ButtonTheme.Tertiary}
        />
      )}
      {showHideButtonWithTooltip}
    </>
  );
};

type OverrideFormData = {
  override_description: {
    markdown?: string;
    text_node?: TextNode;
  };
};

const OverrideSeverityDescriptionModal = ({
  severity,
  severityAlias,
  onConfirm,
  onClose,
}: {
  severity: Severity;
  severityAlias: SeverityAliasFormType;
  onConfirm: (data: OverrideFormData) => void;
  onClose: () => void;
}): React.ReactElement | null => {
  const formMethods = useForm<OverrideFormData>({
    defaultValues: {
      override_description: severityAlias.override_description,
    },
  });

  return (
    <Form.Modal<OverrideFormData>
      analyticsTrackingId="incident-types-edit-severity-description"
      title="Customise description"
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={onConfirm}
      footer={
        <ModalFooter
          confirmButtonType="submit"
          confirmButtonText="Set"
          cancelButtonText="Cancel"
          onClose={onClose}
        />
      }
    >
      <ModalContent>
        <ContentBox className="p-4 mb-4">
          <p className="text-sm font-semibold mb-2">
            Default description for {severity.name}:
          </p>
          <TemplatedTextDisplay
            className="text-sm text-slate-700 border-l-2 border-slate-500 pl-2"
            value={severity.description.text_node}
          />
        </ContentBox>
        <Form.Label htmlFor="description" required>
          Enter a new description
        </Form.Label>
        <TemplatedTextInputV2
          formMethods={formMethods}
          includeVariables={false}
          includeExpressions={false}
          placeholder="Enter a new description"
          format="mrkdwn"
          name="override_description.text_node"
          autoFocus={true}
          required="Please provide a description"
        />
      </ModalContent>
    </Form.Modal>
  );
};

function customFieldToSelectOption(field: CustomField): SelectOption {
  return {
    label: field.name,
    value: field.id,
    icon: customFieldTypeToIcon(field.field_type),
  };
}

function roleToSelectOption(role: IncidentRole): SelectOption {
  return {
    label: role.name,
    value: role.id,
    icon: IconEnum.User,
  };
}

function defaultAlias(severity: Severity): SeverityAliasFormType {
  return {
    severity_id: severity.id,
    hidden: false,
  };
}
