/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';
import {
    CustomFieldEntry,
    CustomFieldEntryFromJSON,
    CustomFieldEntryFromJSONTyped,
    CustomFieldEntryToJSON,
} from './CustomFieldEntry';
import {
    ExternalIssueReference,
    ExternalIssueReferenceFromJSON,
    ExternalIssueReferenceFromJSONTyped,
    ExternalIssueReferenceToJSON,
} from './ExternalIssueReference';
import {
    FollowUp,
    FollowUpFromJSON,
    FollowUpFromJSONTyped,
    FollowUpToJSON,
} from './FollowUp';
import {
    IncidentDurationMetricWithValue,
    IncidentDurationMetricWithValueFromJSON,
    IncidentDurationMetricWithValueFromJSONTyped,
    IncidentDurationMetricWithValueToJSON,
} from './IncidentDurationMetricWithValue';
import {
    IncidentManualEdit,
    IncidentManualEditFromJSON,
    IncidentManualEditFromJSONTyped,
    IncidentManualEditToJSON,
} from './IncidentManualEdit';
import {
    IncidentRoleAssignment,
    IncidentRoleAssignmentFromJSON,
    IncidentRoleAssignmentFromJSONTyped,
    IncidentRoleAssignmentToJSON,
} from './IncidentRoleAssignment';
import {
    IncidentStatus,
    IncidentStatusFromJSON,
    IncidentStatusFromJSONTyped,
    IncidentStatusToJSON,
} from './IncidentStatus';
import {
    IncidentTimestampWithValue,
    IncidentTimestampWithValueFromJSON,
    IncidentTimestampWithValueFromJSONTyped,
    IncidentTimestampWithValueToJSON,
} from './IncidentTimestampWithValue';
import {
    IncidentType,
    IncidentTypeFromJSON,
    IncidentTypeFromJSONTyped,
    IncidentTypeToJSON,
} from './IncidentType';
import {
    PostIncidentTaskSlim,
    PostIncidentTaskSlimFromJSON,
    PostIncidentTaskSlimFromJSONTyped,
    PostIncidentTaskSlimToJSON,
} from './PostIncidentTaskSlim';
import {
    Severity,
    SeverityFromJSON,
    SeverityFromJSONTyped,
    SeverityToJSON,
} from './Severity';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface Incident
 */
export interface Incident {
    /**
     * Whether or not the incident partakes in announcements, defaults to true
     * @type {boolean}
     * @memberof Incident
     */
    announcements_enabled?: boolean;
    /**
     * The call URL attached to this incident
     * @type {string}
     * @memberof Incident
     */
    call_url?: string;
    /**
     * Whether the chat channel has been deleted or archived
     * @type {boolean}
     * @memberof Incident
     */
    chat_channel_archived_or_deleted?: boolean;
    /**
     * When the incident was created
     * @type {Date}
     * @memberof Incident
     */
    created_at: Date;
    /**
     * 
     * @type {Actor}
     * @memberof Incident
     */
    creator: Actor;
    /**
     * The ID of the alert that created the incident.
     * @type {string}
     * @memberof Incident
     */
    creator_alert_id?: string;
    /**
     * Custom field entries for this incident
     * @type {Array<CustomFieldEntry>}
     * @memberof Incident
     */
    custom_field_entries: Array<CustomFieldEntry>;
    /**
     * If a user opted out of the post-incident flow, this is set to true
     * @type {boolean}
     * @memberof Incident
     */
    did_opt_out_of_post_incident_flow: boolean;
    /**
     * A list of duration metrics for this incident
     * @type {Array<IncidentDurationMetricWithValue>}
     * @memberof Incident
     */
    duration_metrics?: Array<IncidentDurationMetricWithValue>;
    /**
     * If the incident is in a closed status, the length of time from creation through to closure. If the incident is ongoing, this will be null.
     * @type {number}
     * @memberof Incident
     */
    duration_seconds?: number;
    /**
     * External identifier for the incident - often displayed with an INC- prefix
     * @type {number}
     * @memberof Incident
     */
    external_id: number;
    /**
     * 
     * @type {ExternalIssueReference}
     * @memberof Incident
     */
    external_issue_reference?: ExternalIssueReference;
    /**
     * A list of follow-ups associated with this incident
     * @type {Array<FollowUp>}
     * @memberof Incident
     */
    follow_ups?: Array<FollowUp>;
    /**
     * If this incident has a debrief attached
     * @type {boolean}
     * @memberof Incident
     */
    has_debrief?: boolean;
    /**
     * Unique identifier for the incident
     * @type {string}
     * @memberof Incident
     */
    id: string;
    /**
     * Unique string used to de-duplicate incident create requests
     * @type {string}
     * @memberof Incident
     */
    idempotency_key: string;
    /**
     * A list of who is assigned to each role for this incident
     * @type {Array<IncidentRoleAssignment>}
     * @memberof Incident
     */
    incident_role_assignments: Array<IncidentRoleAssignment>;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof Incident
     */
    incident_status: IncidentStatus;
    /**
     * A list of timestamps for this incident
     * @type {Array<IncidentTimestampWithValue>}
     * @memberof Incident
     */
    incident_timestamps?: Array<IncidentTimestampWithValue>;
    /**
     * 
     * @type {IncidentType}
     * @memberof Incident
     */
    incident_type?: IncidentType;
    /**
     * When the incident last recorded 'activity'
     * @type {Date}
     * @memberof Incident
     */
    last_activity_at: Date;
    /**
     * Manual edits for this incident
     * @type {Array<IncidentManualEdit>}
     * @memberof Incident
     */
    manual_edits?: Array<IncidentManualEdit>;
    /**
     * Whether the incident is real, a test, a tutorial, or importing as a retrospective incident
     * @type {string}
     * @memberof Incident
     */
    mode: IncidentModeEnum;
    /**
     * URL to link to the Microsoft Teams channel
     * @type {string}
     * @memberof Incident
     */
    ms_teams_channel_url?: string;
    /**
     * Explanation of the incident
     * @type {string}
     * @memberof Incident
     */
    name: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof Incident
     */
    opt_out_of_post_incident_flow_reason?: TextDocument;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof Incident
     */
    organisation_id: string;
    /**
     * The ID of the parent incident, if this incident is a stream
     * @type {string}
     * @memberof Incident
     */
    parent_incident_id?: string;
    /**
     * 
     * @type {IncidentStatus}
     * @memberof Incident
     */
    paused_in_status?: IncidentStatus;
    /**
     * If the incident ever entered the post-incident flow, this is the ID of that flow
     * @type {string}
     * @memberof Incident
     */
    post_incident_flow_id?: string;
    /**
     * A list of tasks associated with this incident
     * @type {Array<PostIncidentTaskSlim>}
     * @memberof Incident
     */
    post_incident_tasks?: Array<PostIncidentTaskSlim>;
    /**
     * Description of the incident
     * @type {string}
     * @memberof Incident
     */
    postmortem_document_url?: string;
    /**
     * Reference to this incident, as displayed across the product
     * @type {string}
     * @memberof Incident
     */
    reference: string;
    /**
     * If there's a reported at timestamp, that value, otherwise fall back to the incident's created_at value
     * @type {Date}
     * @memberof Incident
     */
    reported_at: Date;
    /**
     * 
     * @type {Severity}
     * @memberof Incident
     */
    severity?: Severity;
    /**
     * ID of the Slack channel in the organisation Slack workspace. Note that the channel is sometimes created asynchronously, so may not be present when the incident is just created.
     * @type {string}
     * @memberof Incident
     */
    slack_channel_id: string;
    /**
     * Name of the slack channel
     * @type {string}
     * @memberof Incident
     */
    slack_channel_name?: string;
    /**
     * URL to link to the slack channel
     * @type {string}
     * @memberof Incident
     */
    slack_channel_url?: string;
    /**
     * ID of the Slack team / workspace. This is only required if you are using a Slack Enterprise Grid with multiple teams.
     * @type {string}
     * @memberof Incident
     */
    slack_team_id: string;
    /**
     * Channel ID of the source message, if this incident was created from one
     * @type {string}
     * @memberof Incident
     */
    source_message_channel_id?: string;
    /**
     * Slack Team ID of the source message, if this incident was created from one
     * @type {string}
     * @memberof Incident
     */
    source_message_team_id?: string;
    /**
     * Timestamp of the source message, if this incident was created from one
     * @type {string}
     * @memberof Incident
     */
    source_message_timestamp?: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof Incident
     */
    summary?: TextDocument;
    /**
     * When the summary was last updated
     * @type {string}
     * @memberof Incident
     */
    summary_updated_at?: string;
    /**
     * When the incident was last updated
     * @type {Date}
     * @memberof Incident
     */
    updated_at: Date;
    /**
     * Whether the incident should be open to anyone in your Slack workspace (public), or invite-only (private). For more information on Private Incidents see our [help centre](https://help.incident.io/en/articles/5947963-can-we-mark-incidents-as-sensitive-and-restrict-access).
     * @type {string}
     * @memberof Incident
     */
    visibility: IncidentVisibilityEnum;
    /**
     * Amount of time spent on the incident in late hours
     * @type {number}
     * @memberof Incident
     */
    workload_minutes_late?: number;
    /**
     * Amount of time spent on the incident in sleeping hours
     * @type {number}
     * @memberof Incident
     */
    workload_minutes_sleeping?: number;
    /**
     * Amount of time spent on the incident in total
     * @type {number}
     * @memberof Incident
     */
    workload_minutes_total?: number;
    /**
     * Amount of time spent on the incident in working hours
     * @type {number}
     * @memberof Incident
     */
    workload_minutes_working?: number;
}

/**
* @export
* @enum {string}
*/
export enum IncidentModeEnum {
    Standard = 'standard',
    Retrospective = 'retrospective',
    Test = 'test',
    Tutorial = 'tutorial'
}/**
* @export
* @enum {string}
*/
export enum IncidentVisibilityEnum {
    Public = 'public',
    Private = 'private'
}

export function IncidentFromJSON(json: any): Incident {
    return IncidentFromJSONTyped(json, false);
}

export function IncidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Incident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'announcements_enabled': !exists(json, 'announcements_enabled') ? undefined : json['announcements_enabled'],
        'call_url': !exists(json, 'call_url') ? undefined : json['call_url'],
        'chat_channel_archived_or_deleted': !exists(json, 'chat_channel_archived_or_deleted') ? undefined : json['chat_channel_archived_or_deleted'],
        'created_at': (new Date(json['created_at'])),
        'creator': ActorFromJSON(json['creator']),
        'creator_alert_id': !exists(json, 'creator_alert_id') ? undefined : json['creator_alert_id'],
        'custom_field_entries': ((json['custom_field_entries'] as Array<any>).map(CustomFieldEntryFromJSON)),
        'did_opt_out_of_post_incident_flow': json['did_opt_out_of_post_incident_flow'],
        'duration_metrics': !exists(json, 'duration_metrics') ? undefined : ((json['duration_metrics'] as Array<any>).map(IncidentDurationMetricWithValueFromJSON)),
        'duration_seconds': !exists(json, 'duration_seconds') ? undefined : json['duration_seconds'],
        'external_id': json['external_id'],
        'external_issue_reference': !exists(json, 'external_issue_reference') ? undefined : ExternalIssueReferenceFromJSON(json['external_issue_reference']),
        'follow_ups': !exists(json, 'follow_ups') ? undefined : ((json['follow_ups'] as Array<any>).map(FollowUpFromJSON)),
        'has_debrief': !exists(json, 'has_debrief') ? undefined : json['has_debrief'],
        'id': json['id'],
        'idempotency_key': json['idempotency_key'],
        'incident_role_assignments': ((json['incident_role_assignments'] as Array<any>).map(IncidentRoleAssignmentFromJSON)),
        'incident_status': IncidentStatusFromJSON(json['incident_status']),
        'incident_timestamps': !exists(json, 'incident_timestamps') ? undefined : ((json['incident_timestamps'] as Array<any>).map(IncidentTimestampWithValueFromJSON)),
        'incident_type': !exists(json, 'incident_type') ? undefined : IncidentTypeFromJSON(json['incident_type']),
        'last_activity_at': (new Date(json['last_activity_at'])),
        'manual_edits': !exists(json, 'manual_edits') ? undefined : ((json['manual_edits'] as Array<any>).map(IncidentManualEditFromJSON)),
        'mode': json['mode'],
        'ms_teams_channel_url': !exists(json, 'ms_teams_channel_url') ? undefined : json['ms_teams_channel_url'],
        'name': json['name'],
        'opt_out_of_post_incident_flow_reason': !exists(json, 'opt_out_of_post_incident_flow_reason') ? undefined : TextDocumentFromJSON(json['opt_out_of_post_incident_flow_reason']),
        'organisation_id': json['organisation_id'],
        'parent_incident_id': !exists(json, 'parent_incident_id') ? undefined : json['parent_incident_id'],
        'paused_in_status': !exists(json, 'paused_in_status') ? undefined : IncidentStatusFromJSON(json['paused_in_status']),
        'post_incident_flow_id': !exists(json, 'post_incident_flow_id') ? undefined : json['post_incident_flow_id'],
        'post_incident_tasks': !exists(json, 'post_incident_tasks') ? undefined : ((json['post_incident_tasks'] as Array<any>).map(PostIncidentTaskSlimFromJSON)),
        'postmortem_document_url': !exists(json, 'postmortem_document_url') ? undefined : json['postmortem_document_url'],
        'reference': json['reference'],
        'reported_at': (new Date(json['reported_at'])),
        'severity': !exists(json, 'severity') ? undefined : SeverityFromJSON(json['severity']),
        'slack_channel_id': json['slack_channel_id'],
        'slack_channel_name': !exists(json, 'slack_channel_name') ? undefined : json['slack_channel_name'],
        'slack_channel_url': !exists(json, 'slack_channel_url') ? undefined : json['slack_channel_url'],
        'slack_team_id': json['slack_team_id'],
        'source_message_channel_id': !exists(json, 'source_message_channel_id') ? undefined : json['source_message_channel_id'],
        'source_message_team_id': !exists(json, 'source_message_team_id') ? undefined : json['source_message_team_id'],
        'source_message_timestamp': !exists(json, 'source_message_timestamp') ? undefined : json['source_message_timestamp'],
        'summary': !exists(json, 'summary') ? undefined : TextDocumentFromJSON(json['summary']),
        'summary_updated_at': !exists(json, 'summary_updated_at') ? undefined : json['summary_updated_at'],
        'updated_at': (new Date(json['updated_at'])),
        'visibility': json['visibility'],
        'workload_minutes_late': !exists(json, 'workload_minutes_late') ? undefined : json['workload_minutes_late'],
        'workload_minutes_sleeping': !exists(json, 'workload_minutes_sleeping') ? undefined : json['workload_minutes_sleeping'],
        'workload_minutes_total': !exists(json, 'workload_minutes_total') ? undefined : json['workload_minutes_total'],
        'workload_minutes_working': !exists(json, 'workload_minutes_working') ? undefined : json['workload_minutes_working'],
    };
}

export function IncidentToJSON(value?: Incident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'announcements_enabled': value.announcements_enabled,
        'call_url': value.call_url,
        'chat_channel_archived_or_deleted': value.chat_channel_archived_or_deleted,
        'created_at': (value.created_at.toISOString()),
        'creator': ActorToJSON(value.creator),
        'creator_alert_id': value.creator_alert_id,
        'custom_field_entries': ((value.custom_field_entries as Array<any>).map(CustomFieldEntryToJSON)),
        'did_opt_out_of_post_incident_flow': value.did_opt_out_of_post_incident_flow,
        'duration_metrics': value.duration_metrics === undefined ? undefined : ((value.duration_metrics as Array<any>).map(IncidentDurationMetricWithValueToJSON)),
        'duration_seconds': value.duration_seconds,
        'external_id': value.external_id,
        'external_issue_reference': ExternalIssueReferenceToJSON(value.external_issue_reference),
        'follow_ups': value.follow_ups === undefined ? undefined : ((value.follow_ups as Array<any>).map(FollowUpToJSON)),
        'has_debrief': value.has_debrief,
        'id': value.id,
        'idempotency_key': value.idempotency_key,
        'incident_role_assignments': ((value.incident_role_assignments as Array<any>).map(IncidentRoleAssignmentToJSON)),
        'incident_status': IncidentStatusToJSON(value.incident_status),
        'incident_timestamps': value.incident_timestamps === undefined ? undefined : ((value.incident_timestamps as Array<any>).map(IncidentTimestampWithValueToJSON)),
        'incident_type': IncidentTypeToJSON(value.incident_type),
        'last_activity_at': (value.last_activity_at.toISOString()),
        'manual_edits': value.manual_edits === undefined ? undefined : ((value.manual_edits as Array<any>).map(IncidentManualEditToJSON)),
        'mode': value.mode,
        'ms_teams_channel_url': value.ms_teams_channel_url,
        'name': value.name,
        'opt_out_of_post_incident_flow_reason': TextDocumentToJSON(value.opt_out_of_post_incident_flow_reason),
        'organisation_id': value.organisation_id,
        'parent_incident_id': value.parent_incident_id,
        'paused_in_status': IncidentStatusToJSON(value.paused_in_status),
        'post_incident_flow_id': value.post_incident_flow_id,
        'post_incident_tasks': value.post_incident_tasks === undefined ? undefined : ((value.post_incident_tasks as Array<any>).map(PostIncidentTaskSlimToJSON)),
        'postmortem_document_url': value.postmortem_document_url,
        'reference': value.reference,
        'reported_at': (value.reported_at.toISOString()),
        'severity': SeverityToJSON(value.severity),
        'slack_channel_id': value.slack_channel_id,
        'slack_channel_name': value.slack_channel_name,
        'slack_channel_url': value.slack_channel_url,
        'slack_team_id': value.slack_team_id,
        'source_message_channel_id': value.source_message_channel_id,
        'source_message_team_id': value.source_message_team_id,
        'source_message_timestamp': value.source_message_timestamp,
        'summary': TextDocumentToJSON(value.summary),
        'summary_updated_at': value.summary_updated_at,
        'updated_at': (value.updated_at.toISOString()),
        'visibility': value.visibility,
        'workload_minutes_late': value.workload_minutes_late,
        'workload_minutes_sleeping': value.workload_minutes_sleeping,
        'workload_minutes_total': value.workload_minutes_total,
        'workload_minutes_working': value.workload_minutes_working,
    };
}

