/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentsBuildScopeResponseBody,
    IncidentsBuildScopeResponseBodyFromJSON,
    IncidentsBuildScopeResponseBodyToJSON,
    IncidentsBulkCreateUpdateRequestBody,
    IncidentsBulkCreateUpdateRequestBodyFromJSON,
    IncidentsBulkCreateUpdateRequestBodyToJSON,
    IncidentsBulkCreateUpdateResponseBody,
    IncidentsBulkCreateUpdateResponseBodyFromJSON,
    IncidentsBulkCreateUpdateResponseBodyToJSON,
    IncidentsBulkUpdateCustomFieldEntriesRequestBody,
    IncidentsBulkUpdateCustomFieldEntriesRequestBodyFromJSON,
    IncidentsBulkUpdateCustomFieldEntriesRequestBodyToJSON,
    IncidentsBulkUpdateCustomFieldEntriesResponseBody,
    IncidentsBulkUpdateCustomFieldEntriesResponseBodyFromJSON,
    IncidentsBulkUpdateCustomFieldEntriesResponseBodyToJSON,
    IncidentsBulkUpdateIncidentTypesRequestBody,
    IncidentsBulkUpdateIncidentTypesRequestBodyFromJSON,
    IncidentsBulkUpdateIncidentTypesRequestBodyToJSON,
    IncidentsBulkUpdateIncidentTypesResponseBody,
    IncidentsBulkUpdateIncidentTypesResponseBodyFromJSON,
    IncidentsBulkUpdateIncidentTypesResponseBodyToJSON,
    IncidentsBulkUpdateModeRequestBody,
    IncidentsBulkUpdateModeRequestBodyFromJSON,
    IncidentsBulkUpdateModeRequestBodyToJSON,
    IncidentsBulkUpdateModeResponseBody,
    IncidentsBulkUpdateModeResponseBodyFromJSON,
    IncidentsBulkUpdateModeResponseBodyToJSON,
    IncidentsBulkUpdateRoleAssignmentsRequestBody,
    IncidentsBulkUpdateRoleAssignmentsRequestBodyFromJSON,
    IncidentsBulkUpdateRoleAssignmentsRequestBodyToJSON,
    IncidentsBulkUpdateRoleAssignmentsResponseBody,
    IncidentsBulkUpdateRoleAssignmentsResponseBodyFromJSON,
    IncidentsBulkUpdateRoleAssignmentsResponseBodyToJSON,
    IncidentsChangeVisibilityRequestBody,
    IncidentsChangeVisibilityRequestBodyFromJSON,
    IncidentsChangeVisibilityRequestBodyToJSON,
    IncidentsChangeVisibilityResponseBody,
    IncidentsChangeVisibilityResponseBodyFromJSON,
    IncidentsChangeVisibilityResponseBodyToJSON,
    IncidentsCreateRequestBody,
    IncidentsCreateRequestBodyFromJSON,
    IncidentsCreateRequestBodyToJSON,
    IncidentsCreateResponseBody,
    IncidentsCreateResponseBodyFromJSON,
    IncidentsCreateResponseBodyToJSON,
    IncidentsCreateUpdateRequestBody,
    IncidentsCreateUpdateRequestBodyFromJSON,
    IncidentsCreateUpdateRequestBodyToJSON,
    IncidentsCreateUpdateResponseBody,
    IncidentsCreateUpdateResponseBodyFromJSON,
    IncidentsCreateUpdateResponseBodyToJSON,
    IncidentsGenerateSummarySuggestionResponseBody,
    IncidentsGenerateSummarySuggestionResponseBodyFromJSON,
    IncidentsGenerateSummarySuggestionResponseBodyToJSON,
    IncidentsGetSummarySuggestionResponseBody,
    IncidentsGetSummarySuggestionResponseBodyFromJSON,
    IncidentsGetSummarySuggestionResponseBodyToJSON,
    IncidentsIncidentSnippetResponseBody,
    IncidentsIncidentSnippetResponseBodyFromJSON,
    IncidentsIncidentSnippetResponseBodyToJSON,
    IncidentsListFeedbackResponseBody,
    IncidentsListFeedbackResponseBodyFromJSON,
    IncidentsListFeedbackResponseBodyToJSON,
    IncidentsListResponseBody,
    IncidentsListResponseBodyFromJSON,
    IncidentsListResponseBodyToJSON,
    IncidentsRejectSummarySuggestionResponseBody,
    IncidentsRejectSummarySuggestionResponseBodyFromJSON,
    IncidentsRejectSummarySuggestionResponseBodyToJSON,
    IncidentsResolveRequestBody,
    IncidentsResolveRequestBodyFromJSON,
    IncidentsResolveRequestBodyToJSON,
    IncidentsResolveResponseBody,
    IncidentsResolveResponseBodyFromJSON,
    IncidentsResolveResponseBodyToJSON,
    IncidentsShowInternalIDResponseBody,
    IncidentsShowInternalIDResponseBodyFromJSON,
    IncidentsShowInternalIDResponseBodyToJSON,
    IncidentsShowResponseBody,
    IncidentsShowResponseBodyFromJSON,
    IncidentsShowResponseBodyToJSON,
    IncidentsUpdateCustomFieldEntriesRequestBody,
    IncidentsUpdateCustomFieldEntriesRequestBodyFromJSON,
    IncidentsUpdateCustomFieldEntriesRequestBodyToJSON,
    IncidentsUpdateCustomFieldEntriesResponseBody,
    IncidentsUpdateCustomFieldEntriesResponseBodyFromJSON,
    IncidentsUpdateCustomFieldEntriesResponseBodyToJSON,
    IncidentsUpdateIncidentTypeRequestBody,
    IncidentsUpdateIncidentTypeRequestBodyFromJSON,
    IncidentsUpdateIncidentTypeRequestBodyToJSON,
    IncidentsUpdateIncidentTypeResponseBody,
    IncidentsUpdateIncidentTypeResponseBodyFromJSON,
    IncidentsUpdateIncidentTypeResponseBodyToJSON,
    IncidentsUpdateModeRequestBody,
    IncidentsUpdateModeRequestBodyFromJSON,
    IncidentsUpdateModeRequestBodyToJSON,
    IncidentsUpdateModeResponseBody,
    IncidentsUpdateModeResponseBodyFromJSON,
    IncidentsUpdateModeResponseBodyToJSON,
    IncidentsUpdateNameRequestBody,
    IncidentsUpdateNameRequestBodyFromJSON,
    IncidentsUpdateNameRequestBodyToJSON,
    IncidentsUpdateNameResponseBody,
    IncidentsUpdateNameResponseBodyFromJSON,
    IncidentsUpdateNameResponseBodyToJSON,
    IncidentsUpdateRoleAssignmentsRequestBody,
    IncidentsUpdateRoleAssignmentsRequestBodyFromJSON,
    IncidentsUpdateRoleAssignmentsRequestBodyToJSON,
    IncidentsUpdateRoleAssignmentsResponseBody,
    IncidentsUpdateRoleAssignmentsResponseBodyFromJSON,
    IncidentsUpdateRoleAssignmentsResponseBodyToJSON,
    IncidentsUpdateSummaryFromSuggestionRequestBody,
    IncidentsUpdateSummaryFromSuggestionRequestBodyFromJSON,
    IncidentsUpdateSummaryFromSuggestionRequestBodyToJSON,
    IncidentsUpdateSummaryFromSuggestionResponseBody,
    IncidentsUpdateSummaryFromSuggestionResponseBodyFromJSON,
    IncidentsUpdateSummaryFromSuggestionResponseBodyToJSON,
    IncidentsUpdateSummaryRequestBody,
    IncidentsUpdateSummaryRequestBodyFromJSON,
    IncidentsUpdateSummaryRequestBodyToJSON,
    IncidentsUpdateSummaryResponseBody,
    IncidentsUpdateSummaryResponseBodyFromJSON,
    IncidentsUpdateSummaryResponseBodyToJSON,
    IncidentsUpdateTimestampsRequestBody,
    IncidentsUpdateTimestampsRequestBodyFromJSON,
    IncidentsUpdateTimestampsRequestBodyToJSON,
    IncidentsUpdateTimestampsResponseBody,
    IncidentsUpdateTimestampsResponseBodyFromJSON,
    IncidentsUpdateTimestampsResponseBodyToJSON,
} from '../models';

export interface IncidentsBuildScopeRequest {
    context: IncidentsBuildScopeContextEnum;
}

export interface IncidentsBulkCreateUpdateRequest {
    bulkCreateUpdateRequestBody: IncidentsBulkCreateUpdateRequestBody;
}

export interface IncidentsBulkUpdateCustomFieldEntriesRequest {
    bulkUpdateCustomFieldEntriesRequestBody: IncidentsBulkUpdateCustomFieldEntriesRequestBody;
}

export interface IncidentsBulkUpdateIncidentTypesRequest {
    bulkUpdateIncidentTypesRequestBody: IncidentsBulkUpdateIncidentTypesRequestBody;
}

export interface IncidentsBulkUpdateModeRequest {
    bulkUpdateModeRequestBody: IncidentsBulkUpdateModeRequestBody;
}

export interface IncidentsBulkUpdateRoleAssignmentsRequest {
    bulkUpdateRoleAssignmentsRequestBody: IncidentsBulkUpdateRoleAssignmentsRequestBody;
}

export interface IncidentsChangeVisibilityRequest {
    id: string;
    changeVisibilityRequestBody: IncidentsChangeVisibilityRequestBody;
}

export interface IncidentsCreateRequest {
    createRequestBody: IncidentsCreateRequestBody;
}

export interface IncidentsCreateUpdateRequest {
    incidentId: string;
    createUpdateRequestBody: IncidentsCreateUpdateRequestBody;
}

export interface IncidentsGenerateSummarySuggestionRequest {
    id: string;
}

export interface IncidentsGetSummarySuggestionRequest {
    id: string;
}

export interface IncidentsIncidentSnippetRequest {
    id: string;
}

export interface IncidentsListRequest {
    pageSize?: number;
    after?: string;
    includeFollowUps?: boolean;
    includePostIncidentTasks?: boolean;
    sortBy?: IncidentsListSortByEnum;
    id?: any;
    reference?: any;
    fullTextSearch?: any;
    status?: any;
    slackTeamId?: any;
    source?: any;
    severity?: any;
    incidentType?: any;
    incidentTimestamp?: any;
    participants?: any;
    statusCategory?: any;
    hasOutstandingFollowUps?: any;
    hasStatusPage?: any;
    hasPostmortem?: any;
    hasDebrief?: any;
    postmortemStatus?: any;
    includePrivate?: any;
    createdAt?: any;
    updatedAt?: any;
    incidentRole?: any;
    customField?: any;
    mode?: any;
    optedOutOfPostIncidentFlow?: any;
    followUpStatus?: any;
    followUpIsExported?: any;
    followUpPriority?: any;
    followUpOwner?: any;
    followUpCreator?: any;
    followUpCreatedAt?: any;
    followUpCompletedAt?: any;
    followUpWithPolicyViolation?: any;
    postIncidentTaskOwner?: any;
    postIncidentTaskStatus?: any;
    postIncidentTaskOverdue?: any;
    postIncidentTaskConfig?: any;
    postIncidentTaskFlow?: any;
    postIncidentTaskDueAt?: any;
}

export interface IncidentsListFeedbackRequest {
    id: string;
}

export interface IncidentsRejectSummarySuggestionRequest {
    incidentId: string;
    suggestionId: string;
}

export interface IncidentsRejoinChannelRequest {
    id: string;
}

export interface IncidentsRequestAccessRequest {
    id: string;
}

export interface IncidentsResolveRequest {
    incidentId: string;
    resolveRequestBody: IncidentsResolveRequestBody;
}

export interface IncidentsShowRequest {
    id: string;
}

export interface IncidentsShowInternalIDRequest {
    id: string;
}

export interface IncidentsUpdateCustomFieldEntriesRequest {
    id: string;
    updateCustomFieldEntriesRequestBody: IncidentsUpdateCustomFieldEntriesRequestBody;
}

export interface IncidentsUpdateIncidentTypeRequest {
    id: string;
    updateIncidentTypeRequestBody: IncidentsUpdateIncidentTypeRequestBody;
}

export interface IncidentsUpdateModeRequest {
    id: string;
    updateModeRequestBody: IncidentsUpdateModeRequestBody;
}

export interface IncidentsUpdateNameRequest {
    id: string;
    updateNameRequestBody: IncidentsUpdateNameRequestBody;
}

export interface IncidentsUpdateRoleAssignmentsRequest {
    id: string;
    updateRoleAssignmentsRequestBody: IncidentsUpdateRoleAssignmentsRequestBody;
}

export interface IncidentsUpdateSummaryRequest {
    id: string;
    updateSummaryRequestBody: IncidentsUpdateSummaryRequestBody;
}

export interface IncidentsUpdateSummaryFromSuggestionRequest {
    id: string;
    updateSummaryFromSuggestionRequestBody: IncidentsUpdateSummaryFromSuggestionRequestBody;
}

export interface IncidentsUpdateTimestampsRequest {
    id: string;
    updateTimestampsRequestBody: IncidentsUpdateTimestampsRequestBody;
}

/**
 * 
 */
export class IncidentsApi extends runtime.BaseAPI {

    /**
     * Build an incident scope, expanding root-level references into their child references, for the context provided (e.g. for incident modals)
     * BuildScope Incidents
     */
    async incidentsBuildScopeRaw(requestParameters: IncidentsBuildScopeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBuildScopeResponseBody>> {
        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling incidentsBuildScope.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/incident_scope`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build an incident scope, expanding root-level references into their child references, for the context provided (e.g. for incident modals)
     * BuildScope Incidents
     */
    async incidentsBuildScope(requestParameters: IncidentsBuildScopeRequest, initOverrides?: RequestInit): Promise<IncidentsBuildScopeResponseBody> {
        const response = await this.incidentsBuildScopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update incidents in bulk
     * BulkCreateUpdate Incidents
     */
    async incidentsBulkCreateUpdateRaw(requestParameters: IncidentsBulkCreateUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBulkCreateUpdateResponseBody>> {
        if (requestParameters.bulkCreateUpdateRequestBody === null || requestParameters.bulkCreateUpdateRequestBody === undefined) {
            throw new runtime.RequiredError('bulkCreateUpdateRequestBody','Required parameter requestParameters.bulkCreateUpdateRequestBody was null or undefined when calling incidentsBulkCreateUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/bulk_actions/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsBulkCreateUpdateRequestBodyToJSON(requestParameters.bulkCreateUpdateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBulkCreateUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update incidents in bulk
     * BulkCreateUpdate Incidents
     */
    async incidentsBulkCreateUpdate(requestParameters: IncidentsBulkCreateUpdateRequest, initOverrides?: RequestInit): Promise<IncidentsBulkCreateUpdateResponseBody> {
        const response = await this.incidentsBulkCreateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update custom field entries for a set of incidents
     * BulkUpdateCustomFieldEntries Incidents
     */
    async incidentsBulkUpdateCustomFieldEntriesRaw(requestParameters: IncidentsBulkUpdateCustomFieldEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBulkUpdateCustomFieldEntriesResponseBody>> {
        if (requestParameters.bulkUpdateCustomFieldEntriesRequestBody === null || requestParameters.bulkUpdateCustomFieldEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('bulkUpdateCustomFieldEntriesRequestBody','Required parameter requestParameters.bulkUpdateCustomFieldEntriesRequestBody was null or undefined when calling incidentsBulkUpdateCustomFieldEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/custom_field_entries`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsBulkUpdateCustomFieldEntriesRequestBodyToJSON(requestParameters.bulkUpdateCustomFieldEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBulkUpdateCustomFieldEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update custom field entries for a set of incidents
     * BulkUpdateCustomFieldEntries Incidents
     */
    async incidentsBulkUpdateCustomFieldEntries(requestParameters: IncidentsBulkUpdateCustomFieldEntriesRequest, initOverrides?: RequestInit): Promise<IncidentsBulkUpdateCustomFieldEntriesResponseBody> {
        const response = await this.incidentsBulkUpdateCustomFieldEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the incident type for a given list of incidents
     * BulkUpdateIncidentTypes Incidents
     */
    async incidentsBulkUpdateIncidentTypesRaw(requestParameters: IncidentsBulkUpdateIncidentTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBulkUpdateIncidentTypesResponseBody>> {
        if (requestParameters.bulkUpdateIncidentTypesRequestBody === null || requestParameters.bulkUpdateIncidentTypesRequestBody === undefined) {
            throw new runtime.RequiredError('bulkUpdateIncidentTypesRequestBody','Required parameter requestParameters.bulkUpdateIncidentTypesRequestBody was null or undefined when calling incidentsBulkUpdateIncidentTypes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/incident_types`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsBulkUpdateIncidentTypesRequestBodyToJSON(requestParameters.bulkUpdateIncidentTypesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBulkUpdateIncidentTypesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the incident type for a given list of incidents
     * BulkUpdateIncidentTypes Incidents
     */
    async incidentsBulkUpdateIncidentTypes(requestParameters: IncidentsBulkUpdateIncidentTypesRequest, initOverrides?: RequestInit): Promise<IncidentsBulkUpdateIncidentTypesResponseBody> {
        const response = await this.incidentsBulkUpdateIncidentTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the mode of a set of incidents, i.e. whether it is standard, retrospective, test or tutorial
     * BulkUpdateMode Incidents
     */
    async incidentsBulkUpdateModeRaw(requestParameters: IncidentsBulkUpdateModeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBulkUpdateModeResponseBody>> {
        if (requestParameters.bulkUpdateModeRequestBody === null || requestParameters.bulkUpdateModeRequestBody === undefined) {
            throw new runtime.RequiredError('bulkUpdateModeRequestBody','Required parameter requestParameters.bulkUpdateModeRequestBody was null or undefined when calling incidentsBulkUpdateMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/bulk_actions/update_mode`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsBulkUpdateModeRequestBodyToJSON(requestParameters.bulkUpdateModeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBulkUpdateModeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the mode of a set of incidents, i.e. whether it is standard, retrospective, test or tutorial
     * BulkUpdateMode Incidents
     */
    async incidentsBulkUpdateMode(requestParameters: IncidentsBulkUpdateModeRequest, initOverrides?: RequestInit): Promise<IncidentsBulkUpdateModeResponseBody> {
        const response = await this.incidentsBulkUpdateModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update role assignments for a given list of incidents
     * BulkUpdateRoleAssignments Incidents
     */
    async incidentsBulkUpdateRoleAssignmentsRaw(requestParameters: IncidentsBulkUpdateRoleAssignmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsBulkUpdateRoleAssignmentsResponseBody>> {
        if (requestParameters.bulkUpdateRoleAssignmentsRequestBody === null || requestParameters.bulkUpdateRoleAssignmentsRequestBody === undefined) {
            throw new runtime.RequiredError('bulkUpdateRoleAssignmentsRequestBody','Required parameter requestParameters.bulkUpdateRoleAssignmentsRequestBody was null or undefined when calling incidentsBulkUpdateRoleAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/role_assignments`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsBulkUpdateRoleAssignmentsRequestBodyToJSON(requestParameters.bulkUpdateRoleAssignmentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsBulkUpdateRoleAssignmentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update role assignments for a given list of incidents
     * BulkUpdateRoleAssignments Incidents
     */
    async incidentsBulkUpdateRoleAssignments(requestParameters: IncidentsBulkUpdateRoleAssignmentsRequest, initOverrides?: RequestInit): Promise<IncidentsBulkUpdateRoleAssignmentsResponseBody> {
        const response = await this.incidentsBulkUpdateRoleAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change the visibility of an incident, currenly only supports making an incident private.
     * ChangeVisibility Incidents
     */
    async incidentsChangeVisibilityRaw(requestParameters: IncidentsChangeVisibilityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsChangeVisibilityResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsChangeVisibility.');
        }

        if (requestParameters.changeVisibilityRequestBody === null || requestParameters.changeVisibilityRequestBody === undefined) {
            throw new runtime.RequiredError('changeVisibilityRequestBody','Required parameter requestParameters.changeVisibilityRequestBody was null or undefined when calling incidentsChangeVisibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/change_visibility`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsChangeVisibilityRequestBodyToJSON(requestParameters.changeVisibilityRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsChangeVisibilityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Change the visibility of an incident, currenly only supports making an incident private.
     * ChangeVisibility Incidents
     */
    async incidentsChangeVisibility(requestParameters: IncidentsChangeVisibilityRequest, initOverrides?: RequestInit): Promise<IncidentsChangeVisibilityResponseBody> {
        const response = await this.incidentsChangeVisibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new incident
     * Create Incidents
     */
    async incidentsCreateRaw(requestParameters: IncidentsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling incidentsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new incident
     * Create Incidents
     */
    async incidentsCreate(requestParameters: IncidentsCreateRequest, initOverrides?: RequestInit): Promise<IncidentsCreateResponseBody> {
        const response = await this.incidentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Provide an incident update
     * CreateUpdate Incidents
     */
    async incidentsCreateUpdateRaw(requestParameters: IncidentsCreateUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsCreateUpdateResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentsCreateUpdate.');
        }

        if (requestParameters.createUpdateRequestBody === null || requestParameters.createUpdateRequestBody === undefined) {
            throw new runtime.RequiredError('createUpdateRequestBody','Required parameter requestParameters.createUpdateRequestBody was null or undefined when calling incidentsCreateUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{incident_id}/actions/update`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsCreateUpdateRequestBodyToJSON(requestParameters.createUpdateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsCreateUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Provide an incident update
     * CreateUpdate Incidents
     */
    async incidentsCreateUpdate(requestParameters: IncidentsCreateUpdateRequest, initOverrides?: RequestInit): Promise<IncidentsCreateUpdateResponseBody> {
        const response = await this.incidentsCreateUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a summary suggestion for a particular incident
     * GenerateSummarySuggestion Incidents
     */
    async incidentsGenerateSummarySuggestionRaw(requestParameters: IncidentsGenerateSummarySuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsGenerateSummarySuggestionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsGenerateSummarySuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/actions/generate_summary_suggestion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsGenerateSummarySuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generate a summary suggestion for a particular incident
     * GenerateSummarySuggestion Incidents
     */
    async incidentsGenerateSummarySuggestion(requestParameters: IncidentsGenerateSummarySuggestionRequest, initOverrides?: RequestInit): Promise<IncidentsGenerateSummarySuggestionResponseBody> {
        const response = await this.incidentsGenerateSummarySuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the latest generated AI-generated summary for this incident
     * GetSummarySuggestion Incidents
     */
    async incidentsGetSummarySuggestionRaw(requestParameters: IncidentsGetSummarySuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsGetSummarySuggestionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsGetSummarySuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/summary_suggestion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsGetSummarySuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the latest generated AI-generated summary for this incident
     * GetSummarySuggestion Incidents
     */
    async incidentsGetSummarySuggestion(requestParameters: IncidentsGetSummarySuggestionRequest, initOverrides?: RequestInit): Promise<IncidentsGetSummarySuggestionResponseBody> {
        const response = await this.incidentsGetSummarySuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate the snippet for an incident that we can use in AI models
     * IncidentSnippet Incidents
     */
    async incidentsIncidentSnippetRaw(requestParameters: IncidentsIncidentSnippetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsIncidentSnippetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsIncidentSnippet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/ai_hidden_features/snippet`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsIncidentSnippetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Generate the snippet for an incident that we can use in AI models
     * IncidentSnippet Incidents
     */
    async incidentsIncidentSnippet(requestParameters: IncidentsIncidentSnippetRequest, initOverrides?: RequestInit): Promise<IncidentsIncidentSnippetResponseBody> {
        const response = await this.incidentsIncidentSnippetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all incidents
     * List Incidents
     */
    async incidentsListRaw(requestParameters: IncidentsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.includeFollowUps !== undefined) {
            queryParameters['include_follow_ups'] = requestParameters.includeFollowUps;
        }

        if (requestParameters.includePostIncidentTasks !== undefined) {
            queryParameters['include_post_incident_tasks'] = requestParameters.includePostIncidentTasks;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.reference !== undefined) {
            queryParameters['reference'] = requestParameters.reference;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.slackTeamId !== undefined) {
            queryParameters['slack_team_id'] = requestParameters.slackTeamId;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.severity !== undefined) {
            queryParameters['severity'] = requestParameters.severity;
        }

        if (requestParameters.incidentType !== undefined) {
            queryParameters['incident_type'] = requestParameters.incidentType;
        }

        if (requestParameters.incidentTimestamp !== undefined) {
            queryParameters['incident_timestamp'] = requestParameters.incidentTimestamp;
        }

        if (requestParameters.participants !== undefined) {
            queryParameters['participants'] = requestParameters.participants;
        }

        if (requestParameters.statusCategory !== undefined) {
            queryParameters['status_category'] = requestParameters.statusCategory;
        }

        if (requestParameters.hasOutstandingFollowUps !== undefined) {
            queryParameters['has_outstanding_follow_ups'] = requestParameters.hasOutstandingFollowUps;
        }

        if (requestParameters.hasStatusPage !== undefined) {
            queryParameters['has_status_page'] = requestParameters.hasStatusPage;
        }

        if (requestParameters.hasPostmortem !== undefined) {
            queryParameters['has_postmortem'] = requestParameters.hasPostmortem;
        }

        if (requestParameters.hasDebrief !== undefined) {
            queryParameters['has_debrief'] = requestParameters.hasDebrief;
        }

        if (requestParameters.postmortemStatus !== undefined) {
            queryParameters['postmortem_status'] = requestParameters.postmortemStatus;
        }

        if (requestParameters.includePrivate !== undefined) {
            queryParameters['include_private'] = requestParameters.includePrivate;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.incidentRole !== undefined) {
            queryParameters['incident_role'] = requestParameters.incidentRole;
        }

        if (requestParameters.customField !== undefined) {
            queryParameters['custom_field'] = requestParameters.customField;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.optedOutOfPostIncidentFlow !== undefined) {
            queryParameters['opted_out_of_post_incident_flow'] = requestParameters.optedOutOfPostIncidentFlow;
        }

        if (requestParameters.followUpStatus !== undefined) {
            queryParameters['follow_up_status'] = requestParameters.followUpStatus;
        }

        if (requestParameters.followUpIsExported !== undefined) {
            queryParameters['follow_up_is_exported'] = requestParameters.followUpIsExported;
        }

        if (requestParameters.followUpPriority !== undefined) {
            queryParameters['follow_up_priority'] = requestParameters.followUpPriority;
        }

        if (requestParameters.followUpOwner !== undefined) {
            queryParameters['follow_up_owner'] = requestParameters.followUpOwner;
        }

        if (requestParameters.followUpCreator !== undefined) {
            queryParameters['follow_up_creator'] = requestParameters.followUpCreator;
        }

        if (requestParameters.followUpCreatedAt !== undefined) {
            queryParameters['follow_up_created_at'] = requestParameters.followUpCreatedAt;
        }

        if (requestParameters.followUpCompletedAt !== undefined) {
            queryParameters['follow_up_completed_at'] = requestParameters.followUpCompletedAt;
        }

        if (requestParameters.followUpWithPolicyViolation !== undefined) {
            queryParameters['follow_up_with_policy_violation'] = requestParameters.followUpWithPolicyViolation;
        }

        if (requestParameters.postIncidentTaskOwner !== undefined) {
            queryParameters['post_incident_task_owner'] = requestParameters.postIncidentTaskOwner;
        }

        if (requestParameters.postIncidentTaskStatus !== undefined) {
            queryParameters['post_incident_task_status'] = requestParameters.postIncidentTaskStatus;
        }

        if (requestParameters.postIncidentTaskOverdue !== undefined) {
            queryParameters['post_incident_task_overdue'] = requestParameters.postIncidentTaskOverdue;
        }

        if (requestParameters.postIncidentTaskConfig !== undefined) {
            queryParameters['post_incident_task_config'] = requestParameters.postIncidentTaskConfig;
        }

        if (requestParameters.postIncidentTaskFlow !== undefined) {
            queryParameters['post_incident_task_flow'] = requestParameters.postIncidentTaskFlow;
        }

        if (requestParameters.postIncidentTaskDueAt !== undefined) {
            queryParameters['post_incident_task_due_at'] = requestParameters.postIncidentTaskDueAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all incidents
     * List Incidents
     */
    async incidentsList(requestParameters: IncidentsListRequest, initOverrides?: RequestInit): Promise<IncidentsListResponseBody> {
        const response = await this.incidentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List feedback associated with an incident
     * ListFeedback Incidents
     */
    async incidentsListFeedbackRaw(requestParameters: IncidentsListFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsListFeedbackResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsListFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/feedback`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsListFeedbackResponseBodyFromJSON(jsonValue));
    }

    /**
     * List feedback associated with an incident
     * ListFeedback Incidents
     */
    async incidentsListFeedback(requestParameters: IncidentsListFeedbackRequest, initOverrides?: RequestInit): Promise<IncidentsListFeedbackResponseBody> {
        const response = await this.incidentsListFeedbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject the specified AI-generated summary for this incident
     * RejectSummarySuggestion Incidents
     */
    async incidentsRejectSummarySuggestionRaw(requestParameters: IncidentsRejectSummarySuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsRejectSummarySuggestionResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentsRejectSummarySuggestion.');
        }

        if (requestParameters.suggestionId === null || requestParameters.suggestionId === undefined) {
            throw new runtime.RequiredError('suggestionId','Required parameter requestParameters.suggestionId was null or undefined when calling incidentsRejectSummarySuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{incident_id}/summary_suggestion/{suggestion_id}/reject`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))).replace(`{${"suggestion_id"}}`, encodeURIComponent(String(requestParameters.suggestionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsRejectSummarySuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Reject the specified AI-generated summary for this incident
     * RejectSummarySuggestion Incidents
     */
    async incidentsRejectSummarySuggestion(requestParameters: IncidentsRejectSummarySuggestionRequest, initOverrides?: RequestInit): Promise<IncidentsRejectSummarySuggestionResponseBody> {
        const response = await this.incidentsRejectSummarySuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rejoin the channel for a private incident you are a member of
     * RejoinChannel Incidents
     */
    async incidentsRejoinChannelRaw(requestParameters: IncidentsRejoinChannelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsRejoinChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/actions/rejoin_channel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Rejoin the channel for a private incident you are a member of
     * RejoinChannel Incidents
     */
    async incidentsRejoinChannel(requestParameters: IncidentsRejoinChannelRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentsRejoinChannelRaw(requestParameters, initOverrides);
    }

    /**
     * Update whether an incident is a test
     * RequestAccess Incidents
     */
    async incidentsRequestAccessRaw(requestParameters: IncidentsRequestAccessRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsRequestAccess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/actions/request_access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update whether an incident is a test
     * RequestAccess Incidents
     */
    async incidentsRequestAccess(requestParameters: IncidentsRequestAccessRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentsRequestAccessRaw(requestParameters, initOverrides);
    }

    /**
     * Mark an incident as resolved
     * Resolve Incidents
     */
    async incidentsResolveRaw(requestParameters: IncidentsResolveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsResolveResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling incidentsResolve.');
        }

        if (requestParameters.resolveRequestBody === null || requestParameters.resolveRequestBody === undefined) {
            throw new runtime.RequiredError('resolveRequestBody','Required parameter requestParameters.resolveRequestBody was null or undefined when calling incidentsResolve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{incident_id}/actions/resolve`.replace(`{${"incident_id"}}`, encodeURIComponent(String(requestParameters.incidentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsResolveRequestBodyToJSON(requestParameters.resolveRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsResolveResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark an incident as resolved
     * Resolve Incidents
     */
    async incidentsResolve(requestParameters: IncidentsResolveRequest, initOverrides?: RequestInit): Promise<IncidentsResolveResponseBody> {
        const response = await this.incidentsResolveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an incident
     * Show Incidents
     */
    async incidentsShowRaw(requestParameters: IncidentsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an incident
     * Show Incidents
     */
    async incidentsShow(requestParameters: IncidentsShowRequest, initOverrides?: RequestInit): Promise<IncidentsShowResponseBody> {
        const response = await this.incidentsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the internal incident ID for either an external or internal ID
     * ShowInternalID Incidents
     */
    async incidentsShowInternalIDRaw(requestParameters: IncidentsShowInternalIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsShowInternalIDResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsShowInternalID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incidents/{id}/internal_id`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsShowInternalIDResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get the internal incident ID for either an external or internal ID
     * ShowInternalID Incidents
     */
    async incidentsShowInternalID(requestParameters: IncidentsShowInternalIDRequest, initOverrides?: RequestInit): Promise<IncidentsShowInternalIDResponseBody> {
        const response = await this.incidentsShowInternalIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update custom field entries for an incident
     * UpdateCustomFieldEntries Incidents
     */
    async incidentsUpdateCustomFieldEntriesRaw(requestParameters: IncidentsUpdateCustomFieldEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateCustomFieldEntriesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateCustomFieldEntries.');
        }

        if (requestParameters.updateCustomFieldEntriesRequestBody === null || requestParameters.updateCustomFieldEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('updateCustomFieldEntriesRequestBody','Required parameter requestParameters.updateCustomFieldEntriesRequestBody was null or undefined when calling incidentsUpdateCustomFieldEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/custom_field_entries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateCustomFieldEntriesRequestBodyToJSON(requestParameters.updateCustomFieldEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateCustomFieldEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update custom field entries for an incident
     * UpdateCustomFieldEntries Incidents
     */
    async incidentsUpdateCustomFieldEntries(requestParameters: IncidentsUpdateCustomFieldEntriesRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateCustomFieldEntriesResponseBody> {
        const response = await this.incidentsUpdateCustomFieldEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the type of an incident.
     * UpdateIncidentType Incidents
     */
    async incidentsUpdateIncidentTypeRaw(requestParameters: IncidentsUpdateIncidentTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateIncidentTypeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateIncidentType.');
        }

        if (requestParameters.updateIncidentTypeRequestBody === null || requestParameters.updateIncidentTypeRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentTypeRequestBody','Required parameter requestParameters.updateIncidentTypeRequestBody was null or undefined when calling incidentsUpdateIncidentType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/update_incident_type`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateIncidentTypeRequestBodyToJSON(requestParameters.updateIncidentTypeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateIncidentTypeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the type of an incident.
     * UpdateIncidentType Incidents
     */
    async incidentsUpdateIncidentType(requestParameters: IncidentsUpdateIncidentTypeRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateIncidentTypeResponseBody> {
        const response = await this.incidentsUpdateIncidentTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the mode of an incident, i.e. whether it is standard, retrospective, test or tutorial
     * UpdateMode Incidents
     */
    async incidentsUpdateModeRaw(requestParameters: IncidentsUpdateModeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateModeResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateMode.');
        }

        if (requestParameters.updateModeRequestBody === null || requestParameters.updateModeRequestBody === undefined) {
            throw new runtime.RequiredError('updateModeRequestBody','Required parameter requestParameters.updateModeRequestBody was null or undefined when calling incidentsUpdateMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/update_mode`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateModeRequestBodyToJSON(requestParameters.updateModeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateModeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update the mode of an incident, i.e. whether it is standard, retrospective, test or tutorial
     * UpdateMode Incidents
     */
    async incidentsUpdateMode(requestParameters: IncidentsUpdateModeRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateModeResponseBody> {
        const response = await this.incidentsUpdateModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident\'s name
     * UpdateName Incidents
     */
    async incidentsUpdateNameRaw(requestParameters: IncidentsUpdateNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateNameResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateName.');
        }

        if (requestParameters.updateNameRequestBody === null || requestParameters.updateNameRequestBody === undefined) {
            throw new runtime.RequiredError('updateNameRequestBody','Required parameter requestParameters.updateNameRequestBody was null or undefined when calling incidentsUpdateName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/rename`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateNameRequestBodyToJSON(requestParameters.updateNameRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateNameResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident\'s name
     * UpdateName Incidents
     */
    async incidentsUpdateName(requestParameters: IncidentsUpdateNameRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateNameResponseBody> {
        const response = await this.incidentsUpdateNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update role assignments for an incident
     * UpdateRoleAssignments Incidents
     */
    async incidentsUpdateRoleAssignmentsRaw(requestParameters: IncidentsUpdateRoleAssignmentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateRoleAssignmentsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateRoleAssignments.');
        }

        if (requestParameters.updateRoleAssignmentsRequestBody === null || requestParameters.updateRoleAssignmentsRequestBody === undefined) {
            throw new runtime.RequiredError('updateRoleAssignmentsRequestBody','Required parameter requestParameters.updateRoleAssignmentsRequestBody was null or undefined when calling incidentsUpdateRoleAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/role_assignments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateRoleAssignmentsRequestBodyToJSON(requestParameters.updateRoleAssignmentsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateRoleAssignmentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update role assignments for an incident
     * UpdateRoleAssignments Incidents
     */
    async incidentsUpdateRoleAssignments(requestParameters: IncidentsUpdateRoleAssignmentsRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateRoleAssignmentsResponseBody> {
        const response = await this.incidentsUpdateRoleAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident\'s summary
     * UpdateSummary Incidents
     */
    async incidentsUpdateSummaryRaw(requestParameters: IncidentsUpdateSummaryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateSummaryResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateSummary.');
        }

        if (requestParameters.updateSummaryRequestBody === null || requestParameters.updateSummaryRequestBody === undefined) {
            throw new runtime.RequiredError('updateSummaryRequestBody','Required parameter requestParameters.updateSummaryRequestBody was null or undefined when calling incidentsUpdateSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/update_summary`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateSummaryRequestBodyToJSON(requestParameters.updateSummaryRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateSummaryResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident\'s summary
     * UpdateSummary Incidents
     */
    async incidentsUpdateSummary(requestParameters: IncidentsUpdateSummaryRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateSummaryResponseBody> {
        const response = await this.incidentsUpdateSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident\'s summary from an AI-generated suggestion
     * UpdateSummaryFromSuggestion Incidents
     */
    async incidentsUpdateSummaryFromSuggestionRaw(requestParameters: IncidentsUpdateSummaryFromSuggestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateSummaryFromSuggestionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateSummaryFromSuggestion.');
        }

        if (requestParameters.updateSummaryFromSuggestionRequestBody === null || requestParameters.updateSummaryFromSuggestionRequestBody === undefined) {
            throw new runtime.RequiredError('updateSummaryFromSuggestionRequestBody','Required parameter requestParameters.updateSummaryFromSuggestionRequestBody was null or undefined when calling incidentsUpdateSummaryFromSuggestion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/update_summary_from_suggestion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateSummaryFromSuggestionRequestBodyToJSON(requestParameters.updateSummaryFromSuggestionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateSummaryFromSuggestionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident\'s summary from an AI-generated suggestion
     * UpdateSummaryFromSuggestion Incidents
     */
    async incidentsUpdateSummaryFromSuggestion(requestParameters: IncidentsUpdateSummaryFromSuggestionRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateSummaryFromSuggestionResponseBody> {
        const response = await this.incidentsUpdateSummaryFromSuggestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an incident\'s timestamps
     * UpdateTimestamps Incidents
     */
    async incidentsUpdateTimestampsRaw(requestParameters: IncidentsUpdateTimestampsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentsUpdateTimestampsResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling incidentsUpdateTimestamps.');
        }

        if (requestParameters.updateTimestampsRequestBody === null || requestParameters.updateTimestampsRequestBody === undefined) {
            throw new runtime.RequiredError('updateTimestampsRequestBody','Required parameter requestParameters.updateTimestampsRequestBody was null or undefined when calling incidentsUpdateTimestamps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/incidents/{id}/actions/update_timestamps`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentsUpdateTimestampsRequestBodyToJSON(requestParameters.updateTimestampsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentsUpdateTimestampsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an incident\'s timestamps
     * UpdateTimestamps Incidents
     */
    async incidentsUpdateTimestamps(requestParameters: IncidentsUpdateTimestampsRequest, initOverrides?: RequestInit): Promise<IncidentsUpdateTimestampsResponseBody> {
        const response = await this.incidentsUpdateTimestampsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum IncidentsBuildScopeContextEnum {
    AiConfig = 'ai_config',
    AnnouncementRules = 'announcement_rules',
    AutoSubscribe = 'auto_subscribe',
    DebriefInviteRules = 'debrief_invite_rules',
    DebriefSettings = 'debrief_settings',
    Reminders = 'reminders',
    FullScope = 'full_scope',
    JiraSyncConditions = 'jira_sync_conditions',
    Nudges = 'nudges',
    Policies = 'policies',
    QuickActionCustomConditions = 'quick_action_custom_conditions',
    SlackBookmarkTemplatedUrl = 'slack_bookmark_templated_url',
    SlackBookmarkCustomConditions = 'slack_bookmark_custom_conditions',
    AutomationRules = 'automation_rules',
    VantaSync = 'vanta_sync',
    PostIncidentTaskConfig = 'post_incident_task_config',
    IncidentForm = 'incident_form',
    IncidentDeclareForm = 'incident_declare_form',
    ApplicableFields = 'applicable_fields',
    PostIncidentFlow = 'post_incident_flow'
}
/**
    * @export
    * @enum {string}
    */
export enum IncidentsListSortByEnum {
    CreatedAtNewestFirst = 'created_at_newest_first',
    NewestFirst = 'newest_first',
    OldestFirst = 'oldest_first',
    TotalFollowUps = 'total_follow_ups',
    TotalOutstandingFollowUps = 'total_outstanding_follow_ups',
    OldestPostIncidentFirst = 'oldest_post_incident_first'
}
