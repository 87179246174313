import {
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
} from "@incident-ui";
import { useCommandPaletteContext } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import React, { useState } from "react";
import { NotificationsDrawer } from "src/components/notifications/NotificationsDrawer";
import { useNotifications } from "src/components/notifications/useNotifications";
import { useIsAuthenticated } from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useLogoutAll } from "src/hooks/useLogoutAll";
import { tcx } from "src/utils/tailwind-classes";

import { useUserHasOnCallOnboarded } from "../../../../hooks/useUserHasOnCallOnboarded";
import { NavigationLink } from "./Navigation";
import { OnCallHelper } from "./OnCallHelper";
import { SidebarDropdownMenu } from "./SidebarDropdownMenu";
import { useSidebarItems } from "./useSidebarItems";

type Props = {
  showNotifications: boolean;
  openNotificationsDrawer: () => void;
  closeNotificationsDrawer: () => void;
};

export function MainSidebar({
  showNotifications,
  openNotificationsDrawer,
  closeNotificationsDrawer,
}: Props): React.ReactElement | null {
  const isAuthenticated = useIsAuthenticated();
  const { identity, isImpersonating } = useIdentity();
  const sidebarItems = useSidebarItems();
  const userOnCallOnboarded = useUserHasOnCallOnboarded();

  // TODO(ONC-4149): Temporarily stop showing the on-call widget.
  // const { hasOnCall } = useProductAccess();
  // const { data: schedulesForUserData } = useAPI(
  //   identity && hasOnCall ? "schedulesListForUser" : null,
  //   {
  //     user: identity?.user_id || "",
  //   },
  // );

  // const isOnASchedule = (schedulesForUserData?.schedules || []).length > 0;
  // const showOnCallHelper = hasOnCall && (needsOnCallSetup || isOnASchedule);
  const needsOnCallSetup =
    !userOnCallOnboarded && identity?.first_time_on_call_email_sent;
  const showOnCallHelper = false;

  const { isExpanded, setIsExpanded } = useSidebarExpanded();
  const shouldShowNav = identity?.app_installed;

  return (
    <>
      <div
        id="main-sidebar"
        className={tcx(
          // Only visible on non-tiny screens
          "hidden md:flex",
          "h-screen",
          "z-20",
          "pl-4 pr-2 pb-4 flex-col",
          "transition-all ease-in-out overflow-x-hidden",
          isExpanded
            ? "w-[248px] min-w-[248px] max-w-[248px]"
            : "w-[64px] min-w-[64px] max-w-[64px]",
          "bg-surface-invert text-slate-300",
          { ["bg-purple-800"]: process.env.REACT_APP_DEV_SIDEBAR === "true" },
          { ["bg-red-700"]: isImpersonating },
        )}
      >
        <MainSidebarHeader
          showNotifications={showNotifications}
          openNotificationsDrawer={openNotificationsDrawer}
          closeNotificationsDrawer={closeNotificationsDrawer}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        {shouldShowNav && (
          <div className="flex flex-col gap-2">
            <SearchBar isExpanded={isExpanded} />
            <nav className="flex flex-col pt-0 gap-0.5">
              {sidebarItems.map((item) => (
                <NavigationLink
                  key={item.slug}
                  item={item}
                  isExpanded={isExpanded}
                />
              ))}
            </nav>
          </div>
        )}
        {/* Grow to fill the space between the sidebar and 'bottom' section */}
        <div className="grow" />
        {isAuthenticated && identity && (
          <div className={tcx("flex flex-col gap-3 mt-2")}>
            {showOnCallHelper && (
              <OnCallHelper
                identity={identity}
                needsSetup={needsOnCallSetup}
                isExpanded={isExpanded}
              />
            )}
          </div>
        )}
      </div>
      <NotificationsDrawer
        isOpen={showNotifications}
        closeSidebar={() => closeNotificationsDrawer()}
      />
    </>
  );
}

const MainSidebarHeader = ({
  showNotifications,
  openNotificationsDrawer,
  closeNotificationsDrawer,
  isExpanded,
  setIsExpanded,
}: {
  showNotifications: boolean;
  openNotificationsDrawer: () => void;
  closeNotificationsDrawer: () => void;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
}) => (
  <div className="h-24 relative">
    <div
      className={tcx(
        "pt-8 pb-6 flex items-center gap-3 justify-between",
        !isExpanded && "invisible w-0",
      )}
    >
      <TeamAvatarAndMenu isExpanded={isExpanded} />
      <div className="flex gap-4 text-slate-200">
        <WithUnreadIndicator>
          <Button
            icon={IconEnum.Notification}
            theme={ButtonTheme.Unstyled}
            title=""
            analyticsTrackingId="show-notifications"
            className="hover:text-white"
            onClick={() => {
              if (showNotifications) {
                closeNotificationsDrawer();
              } else {
                openNotificationsDrawer();
              }
            }}
          />
        </WithUnreadIndicator>
        <Button
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId="collapse-sidebar"
          icon={IconEnum.SidebarLeftHide}
          title=""
          onClick={() => setIsExpanded(false)}
          className="hover:text-white"
        />
      </div>
    </div>
    <div
      className={tcx(
        "absolute top-0 left-0",
        "pt-10 pb-6 pl-2.5",
        isExpanded && "hidden",
      )}
    >
      <WithUnreadIndicator>
        <Button
          theme={ButtonTheme.Unstyled}
          analyticsTrackingId="expand-sidebar"
          icon={IconEnum.SidebarLeftShow}
          title=""
          onClick={() => setIsExpanded(true)}
          className="hover:text-white"
        />
      </WithUnreadIndicator>
    </div>
  </div>
);

const WithUnreadIndicator = ({ children }: { children: React.ReactNode }) => {
  const { unreadCount } = useNotifications();

  return (
    <div className="relative h-5">
      {children}
      <div
        className={tcx(
          "absolute -top-2.5 z-25",
          "rounded-full h-5 bg-brand text-white",
          "flex items-center justify-center text-xs",
          {
            "w-5 -right-2.5": unreadCount <= 9,
            "w-6 -right-3": unreadCount > 9,
            "w-8 -right-4": unreadCount > 99,
          },
          unreadCount === 0 && "hidden",
        )}
      >
        {unreadCount > 99 ? "99+" : unreadCount}
      </div>
    </div>
  );
};

const SearchBar = ({ isExpanded }: { isExpanded: boolean }) => {
  const { setOpen: setCommandPaletteOpen } = useCommandPaletteContext();

  return (
    <Button
      analyticsTrackingId="command_palette_open_sidebar"
      theme={ButtonTheme.UnstyledPill}
      icon={IconEnum.Search}
      className={tcx(
        "flex w-full bg-slate-900 grow hover:text-white justify-start px-2",
      )}
      onClick={() => setCommandPaletteOpen(true)}
    >
      <div
        className={tcx(
          "gap-2 flex grow justify-between items-center",
          !isExpanded && "hidden",
        )}
      >
        Search
        <div className="flex items-center gap-1 text-[10px]">
          <FakeKeyboardKey text="⌘" />
          <FakeKeyboardKey text="K" />
        </div>
      </div>
    </Button>
  );
};

const FakeKeyboardKey = ({ text }: { text: string }) => (
  <div className="rounded-[6px] w-5 h-5 border border-b-2 border-slate-700 text-slate-200 pb-[1px]">
    <span className="relative -top-[1px]">{text}</span>
  </div>
);

const TeamAvatarAndMenu = ({ isExpanded }: { isExpanded: boolean }) => {
  const { identity } = useIdentity();
  const logoutAll = useLogoutAll();
  if (!identity) {
    return null;
  }

  return (
    <SidebarDropdownMenu
      identity={identity}
      logoutAll={logoutAll}
      offset={4}
      renderTriggerButton={() => (
        <button
          type="button"
          className={tcx(
            "flex items-center gap-0.5 group shrink-0",
            !isExpanded && "hidden opacity-0 transition",
          )}
        >
          <SlackTeamAvatar
            title=""
            url={identity.organisation_icon_url}
            className="w-10 h-10 rounded-2"
            placeholderImg="/slack-team-avatar-placeholder-dark.svg"
          />
          <Icon
            id={IconEnum.Expand}
            className="text-content-tertiary group-hover:text-white"
            size={IconSize.Small}
          />
        </button>
      )}
    />
  );
};

const SIDEBAR_LOCAL_STORAGE_KEY = "main-sidebar-state";
type SidebarState = "expanded" | "collapsed";

const useSidebarExpanded = (): {
  isExpanded: boolean;
  setIsExpanded: (exp: boolean) => void;
} => {
  let localValue = window.localStorage.getItem(SIDEBAR_LOCAL_STORAGE_KEY);

  if (localValue == null) {
    window.localStorage.setItem(SIDEBAR_LOCAL_STORAGE_KEY, "expanded");
    localValue = "expanded";
  }

  const [sidebarState, setSidebarState] = useState<SidebarState>(
    localValue === "collapsed" ? "collapsed" : "expanded",
  );

  return {
    isExpanded: sidebarState === "expanded",
    setIsExpanded: (expanded: boolean) => {
      const value = expanded ? "expanded" : "collapsed";
      window.localStorage.setItem(SIDEBAR_LOCAL_STORAGE_KEY, value);
      setSidebarState(value);
    },
  };
};

export const useIsMainSidebarExpanded = (): boolean => {
  const localValue = window.localStorage.getItem(SIDEBAR_LOCAL_STORAGE_KEY);
  return localValue === "expanded";
};
