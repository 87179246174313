import { CustomDashboard, ScopeNameEnum } from "@incident-io/api";
import { AppliedFiltersBannerReadOnly } from "@incident-shared/filters";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  ButtonTheme,
  GenericErrorMessage,
  IconEnum,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { useParams } from "react-router";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { InsightsCustomDashboardEmptyState } from "../common/InsightsCustomDashboardEmptyState";
import { InsightsDateBadge } from "../common/InsightsDatePicker";
import { InsightsPanel } from "../common/InsightsPanel";
import { dateRangeToFormState } from "../common/marshall";
import { InsightsShowCustomDashboardProvider } from "./InsightsShowCustomDashboardContext";

export const InsightsCustomDashboardRoute = () => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;

  const { id } = useParams<{ id: string }>() as { id: string };

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading: dashboardLoading,
  } = useAPI("insightsShowCustomDashboard", {
    id,
  });

  if (!insightsFeatureGate) {
    return <OrgAwareNavigate to="/insights" />;
  }

  const error = dashboardError;

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (dashboardLoading || !dashboardData) {
    return <FullPageLoader />;
  }

  return <InsightsCustomDashboardPage dashboard={dashboardData.dashboard} />;
};

const InsightsCustomDashboardPage = ({
  dashboard,
}: {
  dashboard: CustomDashboard;
}) => {
  const noPanels = dashboard.panels.length === 0;

  const navigate = useOrgAwareNavigate();

  const navigateToEditAndOpenDrawer = () => {
    navigate(`/insights/dashboards/custom/${dashboard.id}/edit`, {
      state: {
        openAddDrawer: true,
      },
    });
  };

  return (
    <InsightsShowCustomDashboardProvider dashboard={dashboard}>
      <PageWrapper
        width={PageWidth.Wide}
        icon={dashboard.icon}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        className={noPanels ? "" : "bg-surface-secondary"}
        crumbs={[
          {
            title: "Insights",
            to: "/insights",
          },
        ]}
        backHref="/insights"
        noPadding
        accessory={
          // TODO: filters
          <div className="flex items-center gap-2">
            <InsightsDateBadge
              dateRange={dateRangeToFormState(dashboard.date_range)}
              size={BadgeSize.Large}
            />
            <GatedButton
              requiredScope={ScopeNameEnum.InsightsCustomDashboardsUpdate}
              theme={ButtonTheme.Primary}
              icon={IconEnum.Edit}
              analyticsTrackingId="insights-edit-dashboard"
              href={`/insights/dashboards/custom/${dashboard.id}/edit`}
            >
              Edit dashboard
            </GatedButton>
          </div>
        }
        banner={
          <AppliedFiltersBannerReadOnly
            totalNumberOfItems={null}
            itemsLabel={null}
            style="wholeOfPage"
            badgeTheme="slate"
            className="bg-white border-b"
          />
        }
      >
        {noPanels ? (
          <InsightsCustomDashboardEmptyState
            openAddPanelDrawer={() => navigateToEditAndOpenDrawer()}
          />
        ) : (
          <div className="flex flex-col grow gap-6 p-6">
            {dashboard.panels.map((panel, idx) => (
              <InsightsPanel key={idx} panel={panel} panelIdx={idx} />
            ))}
          </div>
        )}
      </PageWrapper>
    </InsightsShowCustomDashboardProvider>
  );
};
