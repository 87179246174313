import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { formatLoginWithRedirectURL } from "@incident-shared/utils/loginWithRedirect";
import { Button, IconEnum } from "@incident-ui";
import React from "react";
import { useIsAuthenticated } from "src/contexts/ClientContext";
import { TrialStatusPlanNameEnum as PlanNameEnum } from "src/contexts/ClientContext";
import { MICROSOFT_ERRORS } from "src/routes/legacy/LoginRoute";
import { useQueryParams } from "src/utils/query-params";

import { SetupLogin } from "../SetupLogin";

// SetupMsTeamsLoginPage is the explanation and CTA for someone hitting the setup flow to
// log in (i.e. oauth using Slack/Teams to tell us who they are). If they log in, and
// we recognise that the org connected to that slack/microsoft team is setup already,
// we'll send them to the dashboard.
export const SetupMsTeamsLoginPage = (): React.ReactElement => {
  const isAuthenticated = useIsAuthenticated();

  const queryParams = useQueryParams();
  const queryPlan = queryParams.get("plan");
  const microsoftErrParam = queryParams.get("microsoft_error");
  let errorText: string | undefined;
  if (microsoftErrParam && MICROSOFT_ERRORS[microsoftErrParam]) {
    errorText = MICROSOFT_ERRORS[microsoftErrParam];
  }
  const plan =
    queryPlan && Object.values(PlanNameEnum).includes(queryPlan as PlanNameEnum)
      ? (queryPlan as PlanNameEnum)
      : undefined;

  const loginURL = formatLoginWithRedirectURL({
    loginURL: "/auth/microsoft_login",
    returnPath: "/setup/msteams",
    isSigningUp: true,
    plan,
  });

  if (isAuthenticated) {
    return <OrgAwareNavigate to={"/setup/msteams"} />;
  }

  return (
    <SetupLogin
      commsPlatform="msteams"
      loginButton={
        <Button
          analyticsTrackingId="login-with-micr"
          href={loginURL}
          className="inline-flex items-center cursor-pointer"
          icon={IconEnum.Microsoft}
        >
          Sign in with Microsoft
        </Button>
      }
      errorText={errorText}
    />
  );
};
