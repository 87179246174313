import { captureException, withScope } from "@sentry/react";
import {
  addSeconds,
  differenceInSeconds,
  format as formatDate,
  formatDuration,
  intervalToDuration,
  isBefore,
  parseJSON,
} from "date-fns";
import { toSeconds as durationToSeconds } from "duration-fns";
import { DateTime } from "luxon";

export function formatDateYyyyMmDd(timestamp: Date | string): string {
  return formatDate(parseJSON(timestamp), "yyyy-MM-dd HH:mm");
}

// Returns a string representation of a date and time, formatted
// according to the user's locale.
// if no timezone is provided, it will format in the local timezone.
// e.g. timestamp 2022-01-21T15:55:00Z
// en-GB 21/01/2022, 15:55:00
// en-US 01/21/2022, 3:55:00 PM
// de-DE 21.01.2022, 15:55:00
export function formatTimestampLocale({
  timestamp,
  timeZone,
  dateStyle,
  timeStyle,
  addWeekday,
}: {
  timestamp: Date;
  timeZone?: string;
  dateStyle?: "full" | "long" | "medium" | "short" | undefined;
  timeStyle?: "full" | "long" | "medium" | "short" | undefined;
  addWeekday?: boolean;
}): string {
  // Get the user's locale, so we can format the date in a way they're used to.
  const { locale } = Intl.DateTimeFormat().resolvedOptions();
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    dateStyle,
    timeStyle,
  };
  const formatted = timestamp.toLocaleString(locale, options);
  if (!addWeekday) {
    return formatted;
  }
  // We optionally add the weekday to the start of the string, e.g. "Fri 23 Sept 2023, 03:00".
  // This is because the weekday can be useful but you only see if if the dateStyle is full.
  const weekday = DateTime.fromJSDate(timestamp).setZone(timeZone).weekdayShort;
  return `${weekday} ${formatted}`;
}

const durationFields = [
  "years",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
];

export enum DurationEnum {
  years = 0,
  months = 1,
  weeks = 2,
  days = 3,
  hours = 4,
  minutes = 5,
  seconds = 6,
}

// appropriateDurationFormat rounds a duration| defaulting to 2 significant figures. So
// if something is 2 months, 3 weeks, 5 days, 4 hours etc., we return ['months','weeks'].
// this is designed to be passed to date-fns formatDuration as a format.
// Hopefully we can find a library to do this for us, because I am not that confident
// that this code will behave in all cases.
export const appropriateDurationFormat = (
  start: Date,
  end: Date,
  significantFigures?: number,
  minInterval: DurationEnum = DurationEnum.seconds,
): string[] => {
  // this returns an object like {years: 0, months: 1, weeks: 0 ... and}
  const duration = intervalToDuration({ start, end });
  // work out the most significant populated field
  let biggestPopulatedFieldIndex = 0;
  for (const field of durationFields) {
    if (duration[field] > 0) {
      break;
    }
    biggestPopulatedFieldIndex++;
  }
  // Duration is zero under our minInterval - we probably want to
  // show a different message here rather than "0 minutes/hours" etc, so return nothing
  if (biggestPopulatedFieldIndex > minInterval) {
    return [];
  }
  // if the only populated field is seconds lets just return ['seconds']
  if (!durationFields[biggestPopulatedFieldIndex + 1]) {
    return biggestPopulatedFieldIndex === durationFields.length
      ? [durationFields[biggestPopulatedFieldIndex - 1]]
      : [durationFields[biggestPopulatedFieldIndex]];
  }

  significantFigures = significantFigures || 2;

  const durationFormat: string[] = [];

  for (let i = 0; i < significantFigures && i <= minInterval; i++) {
    const fieldIndex = biggestPopulatedFieldIndex + i;
    if (fieldIndex > durationFields.length - 1 || fieldIndex > minInterval) {
      break;
    }
    durationFormat.push(durationFields[fieldIndex]);
  }
  return durationFormat;
};

export type DurationOptions = {
  max?: Duration;
  prefix?: string;
  suffix?: string;
  significantFigures?: number;
};

export const formatDurationFromHours = (hours: number): string => {
  const startDate = new Date(2000, 1, 1);
  const endDate = new Date(2000, 1, 1);
  endDate.setTime(endDate.getTime() + hours * 60 * 60 * 1000);

  return formatDurationShort(startDate, endDate);
};

export const formatDurationShort = (
  start: Date,
  end: Date,
  options?: DurationOptions,
): string => {
  const { max, prefix, suffix } = options || {};

  if (differenceInSeconds(start, end) === 0) {
    return "0s";
  }
  if (isBefore(end, start)) {
    return "-";
  }

  const durationFormat = appropriateDurationFormat(
    start,
    end,
    options?.significantFigures,
  );

  const duration = intervalToDuration({ start, end });
  if (max && durationToSeconds(duration) > durationToSeconds(max)) {
    return formatTimestampLocale({ timestamp: start });
  }

  const formattedDur = formatDuration(intervalToDuration({ start, end }), {
    format: durationFormat,
  });

  if (!formattedDur) {
    withScope(function (scope) {
      scope.setLevel("warning");

      captureException(
        new Error(
          `We attempted to format an invalid duration. We'll have returned 'N/A'`,
        ),
        {
          extra: {
            start,
            end,
          },
        },
      );
    });

    return "-";
  }

  const durationString = formattedDur
    .replace(" years", "y")
    .replace(" year", "y")
    .replace(" months", "mo")
    .replace(" month", "mo")
    .replace(" weeks", "w")
    .replace(" week", "w")
    .replace(" days", "d")
    .replace(" day", "d")
    .replace(" hours", "h")
    .replace(" hour", "h")
    .replace(" minutes", "m")
    .replace(" minute", "m")
    .replace(" seconds", "s")
    .replace(" second", "s");

  const durationWithSuffix = suffix
    ? `${durationString} ${suffix}`
    : durationString;
  return prefix ? `${prefix} ${durationWithSuffix}` : durationWithSuffix;
};

/** formatDurationInSeconds
 * takes a number of seconds and returns a duration using the largest appropriate units,
 * formatted as full words.
 * @param significantFigures This adds choice of granularity
 * (ie 1 days / 1 days, 3 hours / 1 days, 3 hours, 7 minutes etc). Defaults to 2.
 */
export const formatDurationInSeconds = (
  seconds: number,
  significantFigures?: number,
  minInterval?: DurationEnum,
  displaySign?: boolean,
  short?: boolean,
): string => {
  if (seconds === 0) {
    return "0";
  }

  // Special case for invalid durations
  if (seconds === -1) {
    return "invalid duration";
  }

  const prefix = displaySign && seconds < 0 ? "-" : "";
  const start = new Date();
  const end = addSeconds(start, seconds);

  const durationFormat = appropriateDurationFormat(
    start,
    end,
    significantFigures,
    minInterval,
  );

  const formattedDur =
    prefix +
    formatDuration(intervalToDuration({ start, end }), {
      format: durationFormat,
    });

  if (short) {
    return formattedDur
      .replace(" years", "y")
      .replace(" year", "y")
      .replace(" months", "mo")
      .replace(" month", "mo")
      .replace(" weeks", "w")
      .replace(" week", "w")
      .replace(" days", "d")
      .replace(" day", "d")
      .replace(" hours", "h")
      .replace(" hour", "h")
      .replace(" minutes", "m")
      .replace(" minute", "m")
      .replace(" seconds", "s")
      .replace(" second", "s");
  } else {
    return formattedDur;
  }
};

export const formatRelativeTimestamp = (timestamp: Date) => {
  const now = new Date();
  const diff = differenceInSeconds(now, timestamp);
  const durationString = formatDurationInSeconds(diff, 1, DurationEnum.minutes);

  return diff === 0 || !durationString
    ? "Just now"
    : now > timestamp
    ? `${durationString} ago`
    : durationString;
};

export const formatDurationInSecondsShort = (
  seconds: number,
  opts?: DurationOptions,
): string => {
  const start = new Date();
  const end = addSeconds(start, seconds);

  return formatDurationShort(start, end, opts);
};

export const getShortTimeZone = (date: Date) => {
  const [timeZone, timeZoneName] = getTimeZoneAndName(date);

  // This will return a short code timezone, like GMT+1
  // or fallback to Europe/London if not available (locale dependent)
  return timeZoneName ?? timeZone;
};

// getLocalTimeZone returns e.g. "Europe/London (BST)"
export const getLocalTimeZone = (date: Date) => {
  const [timeZone, timeZoneName] = getTimeZoneAndName(date);

  return timeZoneName ? `${timeZone} (${timeZoneName})` : timeZone;
};

const getTimeZoneAndName = (date: Date): [string, string | undefined] => {
  const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const formatter = new Intl.DateTimeFormat(locale, { timeZoneName: "short" });
  const parts = formatter.formatToParts(date);
  const timeZoneName = parts.find((part) => part.type === "timeZoneName")
    ?.value;

  return [timeZone, timeZoneName];
};
