import {
  getTypeaheadOptions,
  hydrateInitialSelectOptions,
  TypeaheadTypeEnum,
} from "@incident-shared/forms/Typeahead";
import { DynamicMultiSelectV2 } from "@incident-shared/forms/v2/inputs/DynamicSelectV2";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ToggleEnumV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconBadge, IconEnum, IconSize } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Path, UseFormReturn } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  IncidentsCreateRequestBodyVisibilityEnum as VisibilityEnum,
  IncidentType,
  Settings,
  useClient,
} from "src/contexts/ClientContext";

import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "../../../hooks/usePrimaryCommsPlatform";
import { uppercaseFirstLetterOnly } from "../../../utils/utils";

export type VisibilityData = {
  visibility: VisibilityEnum;
};

export const VisibilityElement = <TFormType extends VisibilityData>({
  formMethods,
  selectedIncidentType,
  hasSelectedIncidentTypeDontKnow,
  settings,
}: {
  formMethods: UseFormReturn<TFormType>;
  selectedIncidentType?: IncidentType;
  hasSelectedIncidentTypeDontKnow: boolean;
  settings: Settings | null;
}) => {
  const { privateIncidentsInMsTeams } = useFlags();
  const primaryCommsPlatform = usePrimaryCommsPlatform();
  const privateIncidentsOnly =
    selectedIncidentType?.private_incidents_only ?? false;
  const privateChannelCopy =
    primaryCommsPlatform === CommsPlatform.Slack
      ? "Slack channel"
      : "Microsoft Teams chat";
  const publicChannelCopy =
    primaryCommsPlatform === CommsPlatform.Slack
      ? "Slack channel"
      : "Microsoft Teams channel";
  const selectedOption = formMethods.watch("visibility" as Path<TFormType>);

  if (
    !settings?.misc?.private_incidents_enabled ||
    hasSelectedIncidentTypeDontKnow
  ) {
    return <></>;
  }

  if (privateIncidentsInMsTeams) {
    return (
      <>
        <RadioButtonGroupV2
          formMethods={formMethods}
          name={"visibility" as Path<TFormType>}
          label={"Incident visibility"}
          srLabel={"Incident visibility"}
          required
          boxed
          horizontal
          options={[
            {
              label: uppercaseFirstLetterOnly(VisibilityEnum.Public),
              value: VisibilityEnum.Public,
              description: "Visible to all members",
              prefixNode: (
                <IconBadge
                  icon={IconEnum.Globe}
                  color={ColorPaletteEnum.Slate}
                  size={IconSize.Medium}
                />
              ),
              isDisabled: privateIncidentsOnly,
              isDisabledTooltipContent: (
                <>
                  {selectedIncidentType?.name} incidents are required to be
                  private.
                </>
              ),
            },
            {
              label: uppercaseFirstLetterOnly(VisibilityEnum.Private),
              value: VisibilityEnum.Private,
              description: "Only invited members",
              prefixNode: (
                <IconBadge
                  icon={IconEnum.Lock}
                  color={ColorPaletteEnum.Slate}
                  size={IconSize.Medium}
                />
              ),
            },
          ]}
        />
        {selectedOption === VisibilityEnum.Private && (
          <>
            <Form.Helptext className="text-xs">
              If this incident is made private, a private {privateChannelCopy}{" "}
              is created. Only invited individuals can access incident
              information.
            </Form.Helptext>

            {primaryCommsPlatform === CommsPlatform.MSTeams && (
              <SelectInitialParticipants formMethods={formMethods} />
            )}
          </>
        )}
        {selectedOption === VisibilityEnum.Public && (
          <Form.Helptext className="text-xs">
            If this incident is made public, a public {publicChannelCopy} is
            created. All members can access incident information.
          </Form.Helptext>
        )}
      </>
    );
  }

  return (
    <div className="mt-2">
      <ToggleEnumV2<TFormType, VisibilityEnum>
        formMethods={formMethods}
        name={"visibility" as Path<TFormType>}
        label="Make this a private incident?"
        disabled={privateIncidentsOnly}
        trueValue={VisibilityEnum.Private}
        falseValue={VisibilityEnum.Public}
        contextText={
          selectedIncidentType?.private_incidents_only
            ? `
${selectedIncidentType?.name} incidents are required to be private.
We'll create a private Slack channel
and only invited individuals can access incident information.
`
            : `If this incident is made private, a private Slack channel is created. Only invited individuals can access incident information.`
        }
      />
    </div>
  );
};

const SelectInitialParticipants = <TFormType extends VisibilityData>({
  formMethods,
}: {
  formMethods: UseFormReturn<TFormType>;
}) => {
  const client = useClient();
  return (
    <DynamicMultiSelectV2
      formMethods={formMethods}
      name={"users_to_invite" as Path<TFormType>}
      label="Participants"
      loadOptions={getTypeaheadOptions(client, TypeaheadTypeEnum.User)}
      hydrateOptions={hydrateInitialSelectOptions(
        client,
        TypeaheadTypeEnum.User,
      )}
      required={false}
      placeholder="Select users to add to the incident"
      helptext="We'll invite these users to the Microsoft Teams chat"
    />
  );
};
