import { DevTool } from "@hookform/devtools";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";
import React, { useMemo } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";
import { Prompt } from "src/components/@shared/utils/Prompt";
import { Environment, getEnvironment } from "src/utils/environment";
import { tcx } from "src/utils/tailwind-classes";

import { FormErrors } from "../ErrorMessage";
import styles from "./FormInputWrapperV2.module.scss";

export type FormRootProps<FormData extends FieldValues> = {
  genericError?: string | null;
  formMethods: UseFormReturn<FormData>;
  onSubmit: SubmitHandler<FormData>;
  // innerClassName is applied to the div that contains the children
  innerClassName?: string;
  // loadingWrapperClassName is applied to the LoadingWrapper component
  loadingWrapperClassName?: string;
  // errorClassName is applied to the generic form error
  errorClassName?: string;
  // outerClassName is applied to the top level form component
  outerClassName?: string;
  children: React.ReactNode;
  saving?: boolean;
  doNotWrapText?: boolean;
  id?: string;
  // warnWhenDirty will show a prompt if the user tries to leave the page
  // with unsaved changes
  warnWhenDirty?: boolean;
  // overrideIsDirty will override the isDirty state of the form in the prompt check
  overrideIsDirty?: boolean;
  // fullHeight will make the formV2 take up the full height of the parent container
  fullHeight?: boolean;
};

export const FormRoot = <FormData extends FieldValues>({
  genericError,
  id,
  formMethods,
  onSubmit,
  children,
  outerClassName,
  innerClassName,
  loadingWrapperClassName,
  saving = false,
  doNotWrapText = false,
  warnWhenDirty = false,
  overrideIsDirty,
  errorClassName,
  fullHeight,
}: FormRootProps<FormData>): React.ReactElement => {
  const { handleSubmit, formState, control } = formMethods;

  const isDirty =
    overrideIsDirty ?? (formState.isDirty && !formState.isSubmitSuccessful);

  const environment = useMemo(() => getEnvironment(), []);

  return (
    <form
      id={id}
      onSubmit={(e) => {
        // We're surprised React's handleSubmit doesn't already do this for us, but we need to stop
        // people from double submitting a form by hitting enter twice.
        if (formState.isSubmitting) {
          return;
        }
        handleSubmit(onSubmit)(e);
        // Prevent this form from submitting any existing parent forms
        // https://github.com/facebook/react/issues/19637#issuecomment-811096805
        e.stopPropagation();
      }}
      className={tcx({ "h-full grow": fullHeight }, outerClassName)}
    >
      <FormProvider<FormData> {...formMethods}>
        {environment === Environment.Development && (
          <DevTool
            placement={"top-right"}
            control={formMethods.control}
            styles={{ button: { top: 0, paddingTop: 0 } }}
          />
        )}
        <div className="space-y-2 h-full">
          {genericError && (
            <div className={tcx(errorClassName)}>
              <FormErrors
                errors={formState.errors}
                fields={control._fields}
                genericError={genericError}
              />
            </div>
          )}
          {warnWhenDirty && (
            <Prompt
              when={isDirty}
              message="You have unsaved changes, are you sure you want to navigate away?"
            />
          )}
          <LoadingWrapper
            className={tcx(
              { "h-full grow": fullHeight },
              loadingWrapperClassName,
            )}
            loading={saving}
          >
            {/* styles.form means that helptext within this will have a max-width applied */}
            <div
              className={tcx(
                "space-y-4",
                doNotWrapText ? "" : styles.form,
                { "h-full grow": fullHeight },
                innerClassName,
              )}
            >
              {children}
            </div>
          </LoadingWrapper>
        </div>
      </FormProvider>
    </form>
  );
};
