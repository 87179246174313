import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Heading, HeadingLevel } from "@incident-ui/Heading/Heading";
import { ModalContent } from "@incident-ui/Modal/ModalContent";
import { ModalHeader } from "@incident-ui/Modal/ModalHeader";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { ErrorBoundary } from "@sentry/react";
import { motion } from "framer-motion";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "src/components/@ui/Loader/Loader";
import { AnalyticsMetadata, useAnalytics } from "src/contexts/AnalyticsContext";
import { PortalProvider } from "src/contexts/PortalContext";
import { tcx } from "src/utils/tailwind-classes";

import styles from "./Modal.module.scss";

export type FormProps =
  | {
      as: "form";
      onSubmit: (e: FormEvent) => void;
    }
  | { as?: "div"; onSubmit?: never };

export type ModalProps = {
  /* Whether or not to render the modal. You may wish to default this to true and render the entire modal conditionally. */
  isOpen: boolean;
  /* The modal content */
  children?: React.ReactNode;
  /* The title which is read out for assistive technology. You must provide this even if you're not using a header. */
  title: string;
  /* An optional node (usually a badge) to display next to the title in the header. */
  titleBadge?: React.ReactNode;
  /* The kebab-cased events name to send in the modal-opened event. */
  analyticsTrackingId: string | null;
  /* Any additional metadata to send in the tracking event. */
  analyticsTrackingMetadata?: AnalyticsMetadata;
  /* Optional styles for the modal container */
  className?: string;
  /* Whether to hide the grey header */
  hideHeader?: boolean;
  /* The appropriate heading level for the modal title. Defaults to 3 (h3) */
  headingLevel?: HeadingLevel;
  /* Whether to render a <Loader/> while waiting for the modal to be ready */
  loading?: boolean;
  /* Whether the modal should be 60% of the view width on tablet+ */
  isExtraLarge?: boolean;
  /* Whether the modal should be 70% of the view width on tablet+ */
  isXXL?: boolean;
  /* Whether the modal should be 90% of the view width on tablet+ */
  maximised?: boolean;
  /* Please don't do this, I just had to do this for the text editor so that its own modals worked, it is bad */
  doNotUse_dangerouslyBypassFocusLock?: boolean;
  /* Close method - required even if disableQuickClose is enabled now so that the confirmation modal can close */
  onClose: () => void;
  /* Whether we should show a confirmation modal when the user clicks ESC*/
  disableQuickClose?: boolean;

  /* this is used when you create one modal after another and don't want to reanimate the second modal */
  /* typical usecase is a 'loading' modal is created first (without this prop), followed up by a second 'loaded' modal (with this prop) */
  /* try not to do this and instead render different modal contents within the same modal. */
  suppressInitialAnimation?: boolean;
} & FormProps;

/**
 *
 * A controlled dialog that pops up when its prop `isOpen` is set to `true`.
 *
 * You can either render the modal and then conditionally set `isOpen`, or set `isOpen` to `true` and conditionally render the entire modal.
 *
 * (NB. because of the way Storybook renders these examples, it doesn't show the `isOpen` prop when you click "Show code". It's there, trust me. When you click the button below, a `useState` hook is set to `true` and passed through to the modal as `isOpen`.)
 *
 * The content behind the modal is blurred, it just doesn't show up in Storybook as there isn't a `<main>` round the content.
 *
 * ### Close behaviour
 *
 * By default, clicking on the overlay or pressing Esc will close the modal. This is good, as it helps people
 * escape the modal quickly.
 *
 * If you really need to disable this (e.g. if there's a form with a lot of info and it'd be super annoying if you lost all your work), pass in `disableQuickClose={true}`.
 *
 * Content should be passed in as a child component, using the `ModalContent` component.
 *
 * ### Footers
 * The footer is now an entirely separate component which you need to add yourself, after the `ModalContent`. This means if you have a form, you can now wrap both the `ModalContent` and `ModalFooter` components in a `form` tag.
 *  */
export const Modal = ({
  title,
  titleBadge,
  as = "div",
  onSubmit,
  analyticsTrackingId,
  analyticsTrackingMetadata = {},
  children,
  hideHeader = false,
  headingLevel = 3,
  disableQuickClose = false,
  className,
  loading,
  onClose: onCloseProp,
  isXXL = false,
  maximised = false,
  isExtraLarge = false,
  doNotUse_dangerouslyBypassFocusLock = false,
  suppressInitialAnimation = false,
  isOpen,
}: ModalProps): React.ReactElement | null => {
  const analytics = useAnalytics();

  const onClose = useCallback(() => {
    analytics?.track(
      `${analyticsTrackingId}.modal-closed`,
      analyticsTrackingMetadata,
    );
    if (onCloseProp) {
      onCloseProp();
    }
  }, [onCloseProp, analytics, analyticsTrackingMetadata, analyticsTrackingId]);

  useEffect(() => {
    if (analytics == null || analyticsTrackingId == null) {
      return;
    }

    if (isOpen) {
      analytics?.track(
        `${analyticsTrackingId}.modal-opened`,
        analyticsTrackingMetadata,
      );
    }
  }, [isOpen, analytics, analyticsTrackingId, analyticsTrackingMetadata]);

  const onSubmitProp = as === "form" ? { onSubmit } : {};

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const dialogRef = useRef(null);

  return (
    <MotionDialogOverlay
      as="div"
      isOpen={isOpen}
      initial={suppressInitialAnimation ? false : { opacity: 0 }}
      animate={{ opacity: 1, display: "block" }}
      exit={
        suppressInitialAnimation
          ? undefined
          : { opacity: 0, transitionEnd: { display: "none" } }
      }
      transition={{ duration: 0.2 }}
      dangerouslyBypassFocusLock={doNotUse_dangerouslyBypassFocusLock}
      onDismiss={
        disableQuickClose
          ? () => {
              setShowConfirmationDialog(true);
            }
          : onClose
      }
      className="z-[50] bg-none !bg-surface-invert !bg-opacity-25"
    >
      <motion.div
        initial={suppressInitialAnimation ? false : { opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1, display: "block" }}
        exit={
          suppressInitialAnimation
            ? undefined
            : { opacity: 0, scale: 0.95, transitionEnd: { display: "none" } }
        }
        transition={{
          duration: 0.1,
        }}
      >
        <DialogContent
          ref={(ref) => {
            dialogRef.current = ref;
          }}
          className={tcx(
            styles.dialogContent,
            { [styles.xl]: isExtraLarge },
            { [styles.xxl]: isXXL },
            { [styles.maximised]: maximised },
            className,
          )}
          as={as}
          aria-label={title || "loading"}
          {...onSubmitProp}
        >
          <ErrorBoundary>
            <PortalProvider portalRef={dialogRef}>
              <>
                {!hideHeader && (
                  <ModalHeader
                    headingLevel={headingLevel}
                    title={
                      <>
                        {title}
                        {titleBadge}
                      </>
                    }
                    onClose={onClose}
                  />
                )}

                {loading ? (
                  <ModalContent>
                    <Loader />
                  </ModalContent>
                ) : (
                  children
                )}

                {showConfirmationDialog && (
                  <ConfirmationDialog
                    onConfirm={onClose}
                    onCancel={() => {
                      setShowConfirmationDialog(false);
                    }}
                    suppressInitialAnimation={suppressInitialAnimation}
                  />
                )}
              </>
            </PortalProvider>
          </ErrorBoundary>
        </DialogContent>
      </motion.div>
    </MotionDialogOverlay>
  );
};
const MotionDialogOverlay = motion(DialogOverlay);

export function BlurFilter({
  visible,
}: {
  visible: boolean;
}): React.ReactElement {
  return (
    <svg className={visible ? undefined : "hidden"} height={0} width={0}>
      <filter
        width="200%"
        height="200%"
        x="-50%"
        y="-50%"
        offset="-50%"
        id="blur"
      >
        <feGaussianBlur stdDeviation="5" />
      </filter>
    </svg>
  );
}

const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  suppressInitialAnimation = false,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  suppressInitialAnimation?: boolean;
}): React.ReactElement => {
  return (
    <MotionDialogOverlay
      as="div"
      initial={suppressInitialAnimation ? false : { opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      className="z-[100] bg-none !bg-surface-invert !bg-opacity-25"
      onDismiss={onCancel}
    >
      <motion.div
        initial={suppressInitialAnimation ? false : { opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.1,
        }}
      >
        <DialogContent
          className={tcx(styles.dialogContent)}
          aria-label="close-modal-confirmation"
        >
          <div
            className={tcx(styles.modalHeader, "p-4", "border-b border-stroke")}
          >
            <Heading level={3}>Close modal</Heading>
          </div>
          <ModalContent>
            <p>
              Are you sure you want to close this modal? You will lose any
              unsaved changes.
            </p>
            <div className="flex flex-row-reverse w-full items-center space-x-2 gap-2">
              <Button
                analyticsTrackingId="confirm-close-modal"
                theme={ButtonTheme.Primary}
                onClick={onConfirm}
              >
                Confirm
              </Button>
              <Button
                analyticsTrackingId="cancel-close-modal"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </ModalContent>
        </DialogContent>
      </motion.div>
    </MotionDialogOverlay>
  );
};
