import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { TutorialPrompt } from "@incident-shared/tutorial";
import { IconEnum, Toggle } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import {
  CatalogHeroBanner,
  useShowCatalogBanner,
} from "src/components/catalog/type-list/CatalogTypeSetupBanner";
import { EscalationDrawer } from "src/components/escalations/EscalateDrawer";
import { V3HomepageTrendsTiles } from "src/components/insights/v3/dashboards/trends/TrendsTiles";
import { EscalationGrid } from "src/components/legacy/escalation-grid/EscalationGrid";
import { HomeKanban } from "src/components/legacy/home/HomeKanban";
import { useIdentity } from "src/contexts/IdentityContext";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

import { ReinstallGoogleMeetBanner } from "../../components/incident-calls/ReinstallGoogleMeetBanner";
import { useShowRicherCallsBanner } from "../../components/incident-calls/useShowBanner";
import { OnCallBanner } from "../../components/legacy/home/OnCallBanner";
import { useShowOnCallBanner } from "../../components/legacy/home/useShowOnCallBanner";
import {
  DeclareIncidentAndEscalateButton,
  DeclareIncidentButton,
} from "../../components/legacy/incident/DeclareIncidentButton";
import { IncidentCreateModal } from "../../components/legacy/incident/IncidentCreateModal";
import { InternalStatusPageLinkButton } from "../../components/legacy/internal-status-pages/InternalStatusPageLinkButton";
import { useOnCallEntityCount } from "../../components/legacy/on-call/utils";
import { useAPI } from "../../utils/swr";
import styles from "./HomeRoute.module.scss";

export const HomeRoute = () => {
  const { featureSnow } = useFlags();
  const [snow, setSnow] = useState(false);
  const { hasOnCall } = useProductAccess();
  const { data: onCallEntityCount, isLoading: onCallEntityLoading } =
    useOnCallEntityCount();

  const [showIncidentCreateModal, setShowIncidentCreateModal] = useState(false);
  const [showEscalateDrawer, setShowEscalateDrawer] = useState(false);
  const [escalationIdForIncidentDeclare, setEscalationIdForIncidentDeclare] =
    useState<string | undefined>(undefined);
  const { identity } = useIdentity();
  const hasInsights = identity?.feature_gates.advanced_insights;

  const { data: providerData } = useAPI("escalationsListProviders", undefined, {
    fallbackData: {
      providers: [],
    },
  });

  return (
    <>
      {snow &&
        Array(500)
          .fill("")
          .map((_, i) => (
            <div className={tcx(styles.snow, "shadow-md")} key={i} />
          ))}
      <PageWrapper
        width={PageWidth.Wide}
        icon={IconEnum.Home}
        title="Home"
        noPadding
        accessory={
          <div className="flex-center-y gap-2 flex-wrap">
            {featureSnow && (
              <Toggle
                toggleClassName="hidden lg:flex"
                id="let-it-snow"
                label="Let it snow"
                toggleLabelClassName="text-slate-400 !mr-1"
                on={snow}
                onToggle={() => setSnow(!snow)}
              />
            )}
            <InternalStatusPageLinkButton />
            <TutorialPrompt />
            {providerData.providers.length > 0 ? (
              <DeclareIncidentAndEscalateButton
                onDeclareIncident={() => setShowIncidentCreateModal(true)}
                onEscalate={() => setShowEscalateDrawer(true)}
              />
            ) : (
              <DeclareIncidentButton
                onClick={() => setShowIncidentCreateModal(true)}
              />
            )}
          </div>
        }
      >
        <div className={"space-y-8 py-8 grow flex flex-col"}>
          <Banner />
          {hasInsights && <V3HomepageTrendsTiles />}
          <HomeKanban fullHeight={!hasOnCall} />
          {!onCallEntityLoading && hasOnCall && onCallEntityCount > 0 ? (
            <EscalationGrid />
          ) : null}
        </div>
        {showIncidentCreateModal && (
          <IncidentCreateModal
            escalationId={escalationIdForIncidentDeclare}
            onClose={() => {
              setShowIncidentCreateModal(false);
              setEscalationIdForIncidentDeclare(undefined);
            }}
          />
        )}
        {showEscalateDrawer ? (
          <EscalationDrawer
            onClose={() => setShowEscalateDrawer(false)}
            onDeclareIncident={(escalationID) => {
              setShowIncidentCreateModal(true);
              setEscalationIdForIncidentDeclare(escalationID);
            }}
            shouldWarnWhenDirty={true}
          />
        ) : null}
      </PageWrapper>
    </>
  );
};

const Banner = () => {
  const { nextWizard: catalogBannerType } = useShowCatalogBanner();
  const { showOnCallBanner } = useShowOnCallBanner();
  const { showRicherCallsBanner } = useShowRicherCallsBanner();

  if (catalogBannerType) {
    return (
      <div className="px-4 md:px-8">
        <CatalogHeroBanner bannerType={catalogBannerType} />
      </div>
    );
  }

  if (showOnCallBanner) {
    return (
      <div className="px-4 md:px-8">
        <OnCallBanner />
      </div>
    );
  }

  if (showRicherCallsBanner) {
    return (
      <div className="px-4 md:px-8">
        <ReinstallGoogleMeetBanner />
      </div>
    );
  }

  return null;
};
