import "@reach/dialog/styles.css";
import "src/scss/base.scss";

import { AppWrapper } from "@incident-shared/layout/AppWrapper";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import { UniqueColorProvider } from "@incident-shared/utils/uniqueColorContext";
import { GenericErrorMessage, ScrollToTop } from "@incident-ui";
import { CommandPaletteProvider } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import { DrawerProvider } from "@incident-ui/Drawer/DrawerProvider";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { ToastProvider } from "@incident-ui/Toast/ToastProvider";
import { ErrorBoundary } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { matchPath, Navigate, useParams } from "react-router";
import { Location, Route, Routes, useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { AlertsRoute } from "src/components/alerts/AlertsRoute";
import { OnCallRoute } from "src/components/legacy/on-call/OnCallRoute";
import { UserPreferencesRoute } from "src/components/user-preferences/UserPreferencesRoute";
import { AnalyticsProvider } from "src/contexts/AnalyticsContext";
import {
  ClientProvider,
  SlackInfoReconnectionReasonEnum as ReconnectionReasonEnum,
} from "src/contexts/ClientContext";
import { BaseRoute } from "src/routes/BaseRoute";

import { CatalogRoute } from "./components/catalog/CatalogRoute";
import { AssistantOverlayProvider } from "./components/insights/assistant/AssistantOverlayContext";
import { AssistantThreadContextProvider } from "./components/insights/assistant/AssistantThreadContext";
import { InsightsV3Route } from "./components/insights/v3/InsightsV3Route";
import { DeclareIncidentFullPage } from "./components/msteams/DeclareIncidentFullPage";
import { MSTeamsPreviewRoute } from "./components/msteams/MSTeamsPreviewRoute";
import { PostIncidentRoute } from "./components/post-incident/PostIncidentRoute";
import { QrCodePage } from "./components/qr/QrCodePage";
import { ConfigureAtlassianConnectPage } from "./components/settings/integrations/atlassian-connect/ConfigureAtlassianConnectPage";
import { IntegrationsRedirectRoutes } from "./components/settings/integrations/IntegrationsRedirectRoutes";
import { SettingsRoute } from "./components/settings/SettingsRoute";
import { LoginMsTeamsTabRoute } from "./components/setup/msteams/LoginMsTeamsTabRoute";
import { SetupMsTeamsLoginUnauthenticatedRoute } from "./components/setup/msteams/SetupMsTeamsLoginUnauthenticatedRoute";
import { SetupLoginUnauthenticatedRoute } from "./components/setup/SetupLoginUnauthenticatedRoute";
import { SetupRoute } from "./components/setup/SetupRoute";
import { StatusPagesRoute as StatusPagesListRoute } from "./components/status-pages/StatusPagesRoute";
import { MicrosoftTeamsTabNotFoundPage } from "./components/teams/TabNotFoundPage";
import { IdentityProvider, useIdentity } from "./contexts/IdentityContext";
import { MicrosoftTeamsContextProvider } from "./contexts/MicrosoftTeamsTabContext";
import { SurvicateProvider } from "./contexts/SurvicateContext";
import { useIntegrations } from "./hooks/useIntegrations";
import { useAllResources } from "./hooks/useResources";
import { useSettings } from "./hooks/useSettings";
import { ConfigureMsTeamsTabRoute } from "./routes/ConfigureMsTeamsTabRoute";
import { DemoMagicRoute } from "./routes/legacy/demo-magic/DemoMagicRoute";
import { HomeRoute } from "./routes/legacy/HomeRoute";
import { ImportIncidentHistoryRoute } from "./routes/legacy/ImportIncidentHistoryRoute";
import { IncidentFilterFieldsInsightsRoute } from "./routes/legacy/IncidentFilterFieldsInsightsRoute";
import { IncidentsPostMortemDebugRoute } from "./routes/legacy/IncidentPostMortemDebugRoute";
import { IncidentRoute } from "./routes/legacy/IncidentRoute";
import { IncidentsListRoute } from "./routes/legacy/IncidentsListRoute";
import { InternalStatusPagesRoute } from "./routes/legacy/internal-status-pages/InternalStatusPagesRoute";
import {
  AnonymousLoginRoute,
  LoginRoute,
  OrgAwareLoginRoute,
} from "./routes/legacy/LoginRoute";
import { MicrosoftTeamsTabErrorRoute } from "./routes/legacy/MicrosoftTeamsTabErrorRoute";
import { OnCallReportsAddRoute } from "./routes/legacy/on-call-pay/OnCallReportsAddRoute";
import { PingErrorRoute } from "./routes/legacy/PingErrorRoute";
import { ReinstallSlackEnterpriseGridRoute } from "./routes/legacy/ReinstallSlackEnterpriseGridRoute";
import { ReinstallSlackRoute } from "./routes/legacy/ReinstallSlackRoute";
import { StartTutorialRoute } from "./routes/legacy/StartTutorialRoute";
import { WorkflowsRoute } from "./routes/legacy/WorkflowsRoute";
import { NotFoundRoute } from "./routes/NotFoundRoute";
import { ReinstallMsTeamsRoute } from "./routes/ReinstallMsTeamsRoute";
import { StaffRoomRoute } from "./routes/StaffRoomRoute";
import { ShowDebugIDProvider } from "./utils/ShowDebugIDProvider";
import { sendWarningToSentry } from "./utils/utils";

const logEasterEgg = () => {
  // eslint-disable-next-line no-console
  console.log(`
   \\||/
   \\||/
 .<><><>.
.<><><><>.
'<><><><>'
 '<><><>'
  `);
  // eslint-disable-next-line no-console
  console.log(
    "👋 Like what you see? We’re hiring! Checkout our jobs page at https://jobs.incident.io",
  );
};

const INTERCOM_APP_ID = "e2bwytl0";

// These are all the URLs which are reserved for internal use, as they were in use before
// we introduced org slugs.
const slugBlacklist = [
  "catalog",
  "404",
  "atlassian-connect/configure",
  "dashboard",
  "demo-magic",
  "follow-ups",
  "incident_filter_fields_insights",
  "incidents",
  "insights",
  "integrations",
  "login",
  "on-call",
  "ping-error",
  "reinstall-slack-enterprise-grid",
  "reinstall-slack",
  "settings",
  "setup",
  "setup-msteams",
  "start-tutorial",
  "status-pages",
  "undefined",
  "user-preferences",
  "workflows",
];

export const App = (): React.ReactElement => {
  logEasterEgg();
  return (
    <ClientProvider>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot={true}
        autoBootProps={{ hideDefaultLauncher: true }}
      >
        <ToastProvider>
          <ShowDebugIDProvider>
            <DrawerProvider>
              {/* First up, we mount all unauthenticated routes, where we don't know what org we're in */}
              <Routes>
                <Route path="/login" element={<LoginRoute />} />
                <Route
                  path="/login/additional-organisation"
                  element={<AnonymousLoginRoute />}
                />
                <Route
                  path="/setup-msteams/login"
                  element={<SetupMsTeamsLoginUnauthenticatedRoute />}
                />
                <Route
                  path="/setup/login"
                  element={<SetupLoginUnauthenticatedRoute />}
                />
                <Route
                  path="configure-msteams-tab"
                  element={<ConfigureMsTeamsTabRoute />}
                />
                <Route
                  path="login-msteams-tab"
                  element={<LoginMsTeamsTabRoute />}
                />
                <Route path="/qr" element={<QrCodePage />} />
                <Route path="/404" element={<NotFoundRoute />} />
                <Route path="/ping-error" element={<PingErrorRoute />} />
                <Route
                  path="/errors/microsoft-teams-tab"
                  element={<MicrosoftTeamsTabErrorRoute />}
                />
                {/* Next, we redirect anything missing a `/:slug` to `/~/...` - these are legacy URLs */}
                {slugBlacklist.map((urlPrefix) => (
                  <Route
                    key={urlPrefix}
                    path={`${urlPrefix}/*`}
                    element={<RedirectToLocationWithSlug />}
                  />
                ))}
                {/* Redirect / to /~/ */}
                <Route
                  path={""}
                  // We haven't got a slug yet, so OrgAwareNavigate wouldn't do anything.
                  // eslint-disable-next-line no-restricted-imports
                  element={<Navigate to="/~/" replace />}
                />
                {/* Finally, we've got a slug, so let's scope by org */}
                <Route path=":slug/*" element={<OrgScopedRoute />} />
              </Routes>
            </DrawerProvider>
          </ShowDebugIDProvider>
        </ToastProvider>
      </IntercomProvider>
    </ClientProvider>
  );
};

const RedirectToLocationWithSlug = (): React.ReactElement => {
  const location = useLocation();

  return (
    <OrgAwareNavigate to={`/~${location.pathname}${location.search}`} replace />
  );
};

const OrgScopedRoute = () => {
  const { slug } = useParams() as { slug: string };
  if (slug === "") {
    throw new Error("unexpected empty slug in OrgScopedRoute");
  }

  return (
    <ErrorBoundary fallback={<GenericErrorMessage />}>
      <Routes>
        <Route path="login" element={<LoginRoute slug={slug} />} />
        <Route
          path="/login/additional-organisation"
          element={<OrgAwareLoginRoute organisationSlug={slug} />}
        />
        <Route
          path="*"
          errorElement={<ErrorBoundary fallback={<GenericErrorMessage />} />}
          element={
            <IdentityProvider slug={slug}>
              <AuthenticatedRoutes />
              <DataPreloader />
            </IdentityProvider>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};

// Same as normal incident overview but without the AppWrapper
const MSTeamsIncidentRoute = () => {
  return (
    <UniqueColorProvider>
      <AssistantOverlayProvider>
        <AssistantThreadContextProvider>
          <CommandPaletteProvider>
            <MicrosoftTeamsContextProvider>
              <ScrollToTop />
              <Routes>
                <Route path="incidents/:id/*" element={<IncidentRoute />} />
                <Route path="incidents" element={<IncidentsListRoute />} />
                <Route path="declare" element={<DeclareIncidentFullPage />} />
                <Route path="*" element={<MicrosoftTeams404Page />} />
              </Routes>
            </MicrosoftTeamsContextProvider>
          </CommandPaletteProvider>
        </AssistantThreadContextProvider>
      </AssistantOverlayProvider>
    </UniqueColorProvider>
  );
};

const MicrosoftTeams404Page = () => {
  sendWarningToSentry("404 in Microsoft Teams tab", {
    location: window.location.href,
  });

  return <MicrosoftTeamsTabNotFoundPage />;
};

// AuthenticatedRoutes is a component that is mounted when we know what org we're in
const AuthenticatedRoutes = (): React.ReactElement => {
  const location = useLocation();
  const { identity } = useIdentity();
  const flags = useFlags();
  const searchString = window.location.search.replace("?", "&");

  // Flags should never be undefined since we're awaiting the provider before mounting the app
  // but it doesn't hurt to be sure
  if (!identity || !flags) {
    return <FullPageLoader />;
  }

  // OK, we are auth'd, but potentially we aren't installed. Let's check that next.
  if (!isAllowedRouteWhenNotInstalled(location)) {
    // Are we installed? If not, send to setup flow.
    if (!identity.app_installed) {
      return <OrgAwareNavigate to={"/setup"} replace />;
    }

    // Do we need to reinstall? If so, send to reinstall flow.
    if (
      identity.slack_info &&
      identity.slack_info.reconnection_reason !== ReconnectionReasonEnum.Empty
    ) {
      return (
        <OrgAwareNavigate
          to={{
            pathname: "/reinstall-slack",
            search: `return_to=${location.pathname + location.search}`,
          }}
          replace
        />
      );
    }
  }

  // At this point, we know that
  // 1. We're authenticated
  // 2. We have an identity
  // 3. We either have an active Slack connection, OR we are on a whitelisted route.
  return (
    <AnalyticsProvider>
      <SurvicateProvider>
        <Routes>
          <Route path="status/*" element={<InternalStatusPagesRoute />} />
          <Route path="ms-teams-tab/*" element={<MSTeamsIncidentRoute />} />
          <Route path="declare" element={<DeclareIncidentFullPage />} />
          <Route path="setup/*" element={<SetupRoute />} />
          <Route
            path="*"
            element={
              <UniqueColorProvider>
                <AssistantOverlayProvider>
                  <AssistantThreadContextProvider>
                    <CommandPaletteProvider>
                      <AppWrapper>
                        <ScrollToTop />
                        <Routes>
                          <Route path="" element={<BaseRoute />} />
                          <Route path="dashboard" element={<HomeRoute />} />

                          {/*
                            This is here to preserve the functionality of
                            inc.new that redirects to /incidents/create, and
                            should take the user to the incidents page with
                            the modal open.
                          */}
                          <Route
                            path="incidents/create"
                            element={
                              <OrgAwareNavigate
                                to={{
                                  pathname: "/declare",
                                  search: searchString,
                                }}
                                replace
                              />
                            }
                          />
                          <Route
                            path="incidents/create-retro"
                            element={
                              <OrgAwareNavigate
                                to={{
                                  pathname: "/declare",
                                  search: `mode=retrospective${searchString}`,
                                }}
                                replace
                              />
                            }
                          />
                          <Route
                            path="incidents"
                            element={<IncidentsListRoute />}
                          />
                          <Route path="alerts/*" element={<AlertsRoute />} />
                          <Route
                            path="incidents/:id/postmortem-debug/*"
                            element={<IncidentsPostMortemDebugRoute />}
                          />
                          <Route
                            path="incidents/:id/*"
                            element={<IncidentRoute />}
                          />

                          {/* Want to redirect insights v1 to the new insights */}
                          <Route
                            path="insights-v1"
                            element={
                              <OrgAwareNavigate
                                to={{ pathname: "/insights" }}
                                replace
                              />
                            }
                          />
                          <Route
                            path="insights/*"
                            element={<InsightsV3Route />}
                          />
                          <Route
                            path="post-incident/*"
                            element={<PostIncidentRoute />}
                          />
                          <Route
                            path="follow-ups/*"
                            element={
                              // Follow-ups used to be it's own route, but now it's a sub-route of post-incident
                              // so we should redirect there.
                              <OrgAwareNavigate
                                to={{
                                  pathname: "/post-incident/follow-ups",
                                }}
                                replace
                              />
                            }
                          />
                          <Route
                            path="workflows/*"
                            element={<WorkflowsRoute />}
                          />
                          {/* This lives outside /on-call/pay-calculator as it doesn't use the
                           * secondary nav, instead it ports you into a 'wizard' view */}
                          <Route
                            path="on-call/pay-calculator/reports/create"
                            element={<OnCallReportsAddRoute />}
                          />

                          <Route path="on-call/*" element={<OnCallRoute />} />

                          <Route
                            path="status-pages/*"
                            element={<StatusPagesListRoute />}
                          />
                          <Route path="catalog/*" element={<CatalogRoute />} />
                          <Route
                            path="settings/*"
                            element={<SettingsRoute />}
                          />
                          <Route
                            path="user-preferences/*"
                            element={<UserPreferencesRoute />}
                          />
                          <Route
                            path="reinstall-slack"
                            element={<ReinstallSlackRoute />}
                          />
                          <Route
                            path="reinstall-msteams"
                            element={<ReinstallMsTeamsRoute />}
                          />
                          <Route
                            path="reinstall-slack-enterprise-grid"
                            element={<ReinstallSlackEnterpriseGridRoute />}
                          />
                          <Route
                            path="start-tutorial"
                            element={<StartTutorialRoute />}
                          />
                          <Route
                            path="incident_filter_fields_insights/*"
                            element={<IncidentFilterFieldsInsightsRoute />}
                          />
                          <Route
                            path="demo-magic/*"
                            element={<DemoMagicRoute />}
                          />
                          <Route
                            path="staff-room/*"
                            element={<StaffRoomRoute />}
                          />
                          <Route
                            path="atlassian-connect/configure/*"
                            element={<ConfigureAtlassianConnectPage />}
                          />
                          <Route path="/404" element={<NotFoundRoute />} />
                          <Route
                            path="incident-history-import/*"
                            element={<ImportIncidentHistoryRoute />}
                          />
                          <Route
                            path="msteams-previews/*"
                            element={<MSTeamsPreviewRoute />}
                          />
                          <Route
                            path="handle-redirect/*"
                            element={<IntegrationsRedirectRoutes />}
                          />

                          <Route
                            path="*"
                            element={<OrgAwareNavigate to="404" replace />}
                          />
                        </Routes>
                      </AppWrapper>
                    </CommandPaletteProvider>
                  </AssistantThreadContextProvider>
                </AssistantOverlayProvider>
              </UniqueColorProvider>
            }
          />
        </Routes>
      </SurvicateProvider>
    </AnalyticsProvider>
  );
};

const isAllowedRouteWhenNotInstalled = (location: Location): boolean => {
  // These are routes that still need authentication (they rely on the identity) but they
  // don't need the app to be installed. We don't want to end up in an endless auth loop
  // so we allow "bad" authentication to load these.
  const allowedRoutesWhenNotInstalled = [
    "/:slug/settings/billing",
    "/:slug/reinstall-slack/*",
    "/:slug/reinstall-msteams/*",
    "/:slug/setup/*",
  ];

  for (const pattern of allowedRoutesWhenNotInstalled) {
    if (matchPath(pattern, location.pathname)) {
      return true;
    }
  }
  return false;
};

// DataPreloader exists to preload data that we know we'll need in lots of our app. It doesn't block
// us rendering anything, it just makes sure we start loading it as soon as the app boots.
const DataPreloader = (): null => {
  useSettings();
  useIntegrations();
  useAllResources();

  return null;
};
