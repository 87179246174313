/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PostmortemsCreateDestinationRequestBody,
    PostmortemsCreateDestinationRequestBodyFromJSON,
    PostmortemsCreateDestinationRequestBodyToJSON,
    PostmortemsCreateDestinationResponseBody,
    PostmortemsCreateDestinationResponseBodyFromJSON,
    PostmortemsCreateDestinationResponseBodyToJSON,
    PostmortemsCreateDocumentRequestBody,
    PostmortemsCreateDocumentRequestBodyFromJSON,
    PostmortemsCreateDocumentRequestBodyToJSON,
    PostmortemsCreateDocumentResponseBody,
    PostmortemsCreateDocumentResponseBodyFromJSON,
    PostmortemsCreateDocumentResponseBodyToJSON,
    PostmortemsCreateLegacyTemplateRequestBody,
    PostmortemsCreateLegacyTemplateRequestBodyFromJSON,
    PostmortemsCreateLegacyTemplateRequestBodyToJSON,
    PostmortemsCreateLegacyTemplateResponseBody,
    PostmortemsCreateLegacyTemplateResponseBodyFromJSON,
    PostmortemsCreateLegacyTemplateResponseBodyToJSON,
    PostmortemsEnqueueCreateDocumentRequestBody,
    PostmortemsEnqueueCreateDocumentRequestBodyFromJSON,
    PostmortemsEnqueueCreateDocumentRequestBodyToJSON,
    PostmortemsEnqueueCreateDocumentResponseBody,
    PostmortemsEnqueueCreateDocumentResponseBodyFromJSON,
    PostmortemsEnqueueCreateDocumentResponseBodyToJSON,
    PostmortemsListDestinationsResponseBody,
    PostmortemsListDestinationsResponseBodyFromJSON,
    PostmortemsListDestinationsResponseBodyToJSON,
    PostmortemsListDocumentsResponseBody,
    PostmortemsListDocumentsResponseBodyFromJSON,
    PostmortemsListDocumentsResponseBodyToJSON,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBody,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSON,
    PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyToJSON,
    PostmortemsListLegacyTemplatesResponseBody,
    PostmortemsListLegacyTemplatesResponseBodyFromJSON,
    PostmortemsListLegacyTemplatesResponseBodyToJSON,
    PostmortemsListTemplatesResponseBody,
    PostmortemsListTemplatesResponseBodyFromJSON,
    PostmortemsListTemplatesResponseBodyToJSON,
    PostmortemsRenderMarkdownRequestBody,
    PostmortemsRenderMarkdownRequestBodyFromJSON,
    PostmortemsRenderMarkdownRequestBodyToJSON,
    PostmortemsRenderMarkdownResponseBody,
    PostmortemsRenderMarkdownResponseBodyFromJSON,
    PostmortemsRenderMarkdownResponseBodyToJSON,
    PostmortemsRenderRequestBody,
    PostmortemsRenderRequestBodyFromJSON,
    PostmortemsRenderRequestBodyToJSON,
    PostmortemsRenderResponseBody,
    PostmortemsRenderResponseBodyFromJSON,
    PostmortemsRenderResponseBodyToJSON,
    PostmortemsSetDocumentStatusRequestBody,
    PostmortemsSetDocumentStatusRequestBodyFromJSON,
    PostmortemsSetDocumentStatusRequestBodyToJSON,
    PostmortemsSetDocumentStatusResponseBody,
    PostmortemsSetDocumentStatusResponseBodyFromJSON,
    PostmortemsSetDocumentStatusResponseBodyToJSON,
    PostmortemsShowDocumentResponseBody,
    PostmortemsShowDocumentResponseBodyFromJSON,
    PostmortemsShowDocumentResponseBodyToJSON,
    PostmortemsShowLegacyTemplateResponseBody,
    PostmortemsShowLegacyTemplateResponseBodyFromJSON,
    PostmortemsShowLegacyTemplateResponseBodyToJSON,
    PostmortemsShowTemplateResponseBody,
    PostmortemsShowTemplateResponseBodyFromJSON,
    PostmortemsShowTemplateResponseBodyToJSON,
    PostmortemsUpdateDestinationRequestBody,
    PostmortemsUpdateDestinationRequestBodyFromJSON,
    PostmortemsUpdateDestinationRequestBodyToJSON,
    PostmortemsUpdateDestinationResponseBody,
    PostmortemsUpdateDestinationResponseBodyFromJSON,
    PostmortemsUpdateDestinationResponseBodyToJSON,
    PostmortemsUpdateLegacyTemplateRequestBody,
    PostmortemsUpdateLegacyTemplateRequestBodyFromJSON,
    PostmortemsUpdateLegacyTemplateRequestBodyToJSON,
    PostmortemsUpdateLegacyTemplateResponseBody,
    PostmortemsUpdateLegacyTemplateResponseBodyFromJSON,
    PostmortemsUpdateLegacyTemplateResponseBodyToJSON,
} from '../models';

export interface PostmortemsCreateDestinationRequest {
    createDestinationRequestBody: PostmortemsCreateDestinationRequestBody;
}

export interface PostmortemsCreateDocumentRequest {
    createDocumentRequestBody: PostmortemsCreateDocumentRequestBody;
}

export interface PostmortemsCreateLegacyTemplateRequest {
    createLegacyTemplateRequestBody: PostmortemsCreateLegacyTemplateRequestBody;
}

export interface PostmortemsDestroyDestinationRequest {
    id: string;
}

export interface PostmortemsDestroyDocumentRequest {
    id: string;
}

export interface PostmortemsDestroyLegacyTemplateRequest {
    id: string;
}

export interface PostmortemsEnqueueCreateDocumentRequest {
    enqueueCreateDocumentRequestBody: PostmortemsEnqueueCreateDocumentRequestBody;
}

export interface PostmortemsListDocumentsRequest {
    incidentId: string;
}

export interface PostmortemsListLegacyTemplatesRequest {
    incidentId?: string;
}

export interface PostmortemsRenderRequest {
    renderRequestBody: PostmortemsRenderRequestBody;
}

export interface PostmortemsRenderMarkdownRequest {
    renderMarkdownRequestBody: PostmortemsRenderMarkdownRequestBody;
}

export interface PostmortemsSetDocumentStatusRequest {
    id: string;
    setDocumentStatusRequestBody: PostmortemsSetDocumentStatusRequestBody;
}

export interface PostmortemsShowDocumentRequest {
    id: string;
}

export interface PostmortemsShowLegacyTemplateRequest {
    id: string;
}

export interface PostmortemsShowTemplateRequest {
    id: string;
}

export interface PostmortemsUpdateDestinationRequest {
    id: string;
    updateDestinationRequestBody: PostmortemsUpdateDestinationRequestBody;
}

export interface PostmortemsUpdateLegacyTemplateRequest {
    id: string;
    updateLegacyTemplateRequestBody: PostmortemsUpdateLegacyTemplateRequestBody;
}

/**
 * 
 */
export class PostmortemsApi extends runtime.BaseAPI {

    /**
     * Create a postmortem destination
     * CreateDestination Postmortems
     */
    async postmortemsCreateDestinationRaw(requestParameters: PostmortemsCreateDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateDestinationResponseBody>> {
        if (requestParameters.createDestinationRequestBody === null || requestParameters.createDestinationRequestBody === undefined) {
            throw new runtime.RequiredError('createDestinationRequestBody','Required parameter requestParameters.createDestinationRequestBody was null or undefined when calling postmortemsCreateDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/destinations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateDestinationRequestBodyToJSON(requestParameters.createDestinationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateDestinationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a postmortem destination
     * CreateDestination Postmortems
     */
    async postmortemsCreateDestination(requestParameters: PostmortemsCreateDestinationRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateDestinationResponseBody> {
        const response = await this.postmortemsCreateDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a postmortem document and export to a document provider
     * CreateDocument Postmortems
     */
    async postmortemsCreateDocumentRaw(requestParameters: PostmortemsCreateDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateDocumentResponseBody>> {
        if (requestParameters.createDocumentRequestBody === null || requestParameters.createDocumentRequestBody === undefined) {
            throw new runtime.RequiredError('createDocumentRequestBody','Required parameter requestParameters.createDocumentRequestBody was null or undefined when calling postmortemsCreateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateDocumentRequestBodyToJSON(requestParameters.createDocumentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a postmortem document and export to a document provider
     * CreateDocument Postmortems
     */
    async postmortemsCreateDocument(requestParameters: PostmortemsCreateDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateDocumentResponseBody> {
        const response = await this.postmortemsCreateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create postmortem template
     * CreateLegacyTemplate Postmortems
     */
    async postmortemsCreateLegacyTemplateRaw(requestParameters: PostmortemsCreateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsCreateLegacyTemplateResponseBody>> {
        if (requestParameters.createLegacyTemplateRequestBody === null || requestParameters.createLegacyTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('createLegacyTemplateRequestBody','Required parameter requestParameters.createLegacyTemplateRequestBody was null or undefined when calling postmortemsCreateLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsCreateLegacyTemplateRequestBodyToJSON(requestParameters.createLegacyTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsCreateLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create postmortem template
     * CreateLegacyTemplate Postmortems
     */
    async postmortemsCreateLegacyTemplate(requestParameters: PostmortemsCreateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsCreateLegacyTemplateResponseBody> {
        const response = await this.postmortemsCreateLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a postmortem destination
     * DestroyDestination Postmortems
     */
    async postmortemsDestroyDestinationRaw(requestParameters: PostmortemsDestroyDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/destinations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a postmortem destination
     * DestroyDestination Postmortems
     */
    async postmortemsDestroyDestination(requestParameters: PostmortemsDestroyDestinationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyDestinationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a postmortem document
     * DestroyDocument Postmortems
     */
    async postmortemsDestroyDocumentRaw(requestParameters: PostmortemsDestroyDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a postmortem document
     * DestroyDocument Postmortems
     */
    async postmortemsDestroyDocument(requestParameters: PostmortemsDestroyDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Removes an existing template
     * DestroyLegacyTemplate Postmortems
     */
    async postmortemsDestroyLegacyTemplateRaw(requestParameters: PostmortemsDestroyLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsDestroyLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an existing template
     * DestroyLegacyTemplate Postmortems
     */
    async postmortemsDestroyLegacyTemplate(requestParameters: PostmortemsDestroyLegacyTemplateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.postmortemsDestroyLegacyTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Enqueue a postmortem to be created by some external provider, will retry to create a postmortem if a failed postmortem is provided
     * EnqueueCreateDocument Postmortems
     */
    async postmortemsEnqueueCreateDocumentRaw(requestParameters: PostmortemsEnqueueCreateDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsEnqueueCreateDocumentResponseBody>> {
        if (requestParameters.enqueueCreateDocumentRequestBody === null || requestParameters.enqueueCreateDocumentRequestBody === undefined) {
            throw new runtime.RequiredError('enqueueCreateDocumentRequestBody','Required parameter requestParameters.enqueueCreateDocumentRequestBody was null or undefined when calling postmortemsEnqueueCreateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/enqueue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsEnqueueCreateDocumentRequestBodyToJSON(requestParameters.enqueueCreateDocumentRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsEnqueueCreateDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Enqueue a postmortem to be created by some external provider, will retry to create a postmortem if a failed postmortem is provided
     * EnqueueCreateDocument Postmortems
     */
    async postmortemsEnqueueCreateDocument(requestParameters: PostmortemsEnqueueCreateDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsEnqueueCreateDocumentResponseBody> {
        const response = await this.postmortemsEnqueueCreateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all postmortem destinations
     * ListDestinations Postmortems
     */
    async postmortemsListDestinationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListDestinationsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/destinations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListDestinationsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all postmortem destinations
     * ListDestinations Postmortems
     */
    async postmortemsListDestinations(initOverrides?: RequestInit): Promise<PostmortemsListDestinationsResponseBody> {
        const response = await this.postmortemsListDestinationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List postmortem documents
     * ListDocuments Postmortems
     */
    async postmortemsListDocumentsRaw(requestParameters: PostmortemsListDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListDocumentsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling postmortemsListDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListDocumentsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem documents
     * ListDocuments Postmortems
     */
    async postmortemsListDocuments(requestParameters: PostmortemsListDocumentsRequest, initOverrides?: RequestInit): Promise<PostmortemsListDocumentsResponseBody> {
        const response = await this.postmortemsListDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List standard content blocks that are included when creating a new postmortem template
     * ListLegacyTemplateStandardContentBlocks Postmortems
     */
    async postmortemsListLegacyTemplateStandardContentBlocksRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListLegacyTemplateStandardContentBlocksResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/content_blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListLegacyTemplateStandardContentBlocksResponseBodyFromJSON(jsonValue));
    }

    /**
     * List standard content blocks that are included when creating a new postmortem template
     * ListLegacyTemplateStandardContentBlocks Postmortems
     */
    async postmortemsListLegacyTemplateStandardContentBlocks(initOverrides?: RequestInit): Promise<PostmortemsListLegacyTemplateStandardContentBlocksResponseBody> {
        const response = await this.postmortemsListLegacyTemplateStandardContentBlocksRaw(initOverrides);
        return await response.value();
    }

    /**
     * List postmortem templates
     * ListLegacyTemplates Postmortems
     */
    async postmortemsListLegacyTemplatesRaw(requestParameters: PostmortemsListLegacyTemplatesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListLegacyTemplatesResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListLegacyTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem templates
     * ListLegacyTemplates Postmortems
     */
    async postmortemsListLegacyTemplates(requestParameters: PostmortemsListLegacyTemplatesRequest, initOverrides?: RequestInit): Promise<PostmortemsListLegacyTemplatesResponseBody> {
        const response = await this.postmortemsListLegacyTemplatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List postmortem templates
     * ListTemplates Postmortems
     */
    async postmortemsListTemplatesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsListTemplatesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsListTemplatesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List postmortem templates
     * ListTemplates Postmortems
     */
    async postmortemsListTemplates(initOverrides?: RequestInit): Promise<PostmortemsListTemplatesResponseBody> {
        const response = await this.postmortemsListTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Renders a postmortem for an incident, using the given template
     * Render Postmortems
     */
    async postmortemsRenderRaw(requestParameters: PostmortemsRenderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsRenderResponseBody>> {
        if (requestParameters.renderRequestBody === null || requestParameters.renderRequestBody === undefined) {
            throw new runtime.RequiredError('renderRequestBody','Required parameter requestParameters.renderRequestBody was null or undefined when calling postmortemsRender.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/render`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsRenderRequestBodyToJSON(requestParameters.renderRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsRenderResponseBodyFromJSON(jsonValue));
    }

    /**
     * Renders a postmortem for an incident, using the given template
     * Render Postmortems
     */
    async postmortemsRender(requestParameters: PostmortemsRenderRequest, initOverrides?: RequestInit): Promise<PostmortemsRenderResponseBody> {
        const response = await this.postmortemsRenderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renders markdown of the postmortem for an incident, using the given template
     * RenderMarkdown Postmortems
     */
    async postmortemsRenderMarkdownRaw(requestParameters: PostmortemsRenderMarkdownRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsRenderMarkdownResponseBody>> {
        if (requestParameters.renderMarkdownRequestBody === null || requestParameters.renderMarkdownRequestBody === undefined) {
            throw new runtime.RequiredError('renderMarkdownRequestBody','Required parameter requestParameters.renderMarkdownRequestBody was null or undefined when calling postmortemsRenderMarkdown.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/templates/render_markdown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsRenderMarkdownRequestBodyToJSON(requestParameters.renderMarkdownRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsRenderMarkdownResponseBodyFromJSON(jsonValue));
    }

    /**
     * Renders markdown of the postmortem for an incident, using the given template
     * RenderMarkdown Postmortems
     */
    async postmortemsRenderMarkdown(requestParameters: PostmortemsRenderMarkdownRequest, initOverrides?: RequestInit): Promise<PostmortemsRenderMarkdownResponseBody> {
        const response = await this.postmortemsRenderMarkdownRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change the status of a postmortem document
     * SetDocumentStatus Postmortems
     */
    async postmortemsSetDocumentStatusRaw(requestParameters: PostmortemsSetDocumentStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsSetDocumentStatusResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsSetDocumentStatus.');
        }

        if (requestParameters.setDocumentStatusRequestBody === null || requestParameters.setDocumentStatusRequestBody === undefined) {
            throw new runtime.RequiredError('setDocumentStatusRequestBody','Required parameter requestParameters.setDocumentStatusRequestBody was null or undefined when calling postmortemsSetDocumentStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/documents/{id}/actions/set_status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsSetDocumentStatusRequestBodyToJSON(requestParameters.setDocumentStatusRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsSetDocumentStatusResponseBodyFromJSON(jsonValue));
    }

    /**
     * Change the status of a postmortem document
     * SetDocumentStatus Postmortems
     */
    async postmortemsSetDocumentStatus(requestParameters: PostmortemsSetDocumentStatusRequest, initOverrides?: RequestInit): Promise<PostmortemsSetDocumentStatusResponseBody> {
        const response = await this.postmortemsSetDocumentStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a postmortem document
     * ShowDocument Postmortems
     */
    async postmortemsShowDocumentRaw(requestParameters: PostmortemsShowDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowDocumentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowDocumentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a postmortem document
     * ShowDocument Postmortems
     */
    async postmortemsShowDocument(requestParameters: PostmortemsShowDocumentRequest, initOverrides?: RequestInit): Promise<PostmortemsShowDocumentResponseBody> {
        const response = await this.postmortemsShowDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an individual template by ID
     * ShowLegacyTemplate Postmortems
     */
    async postmortemsShowLegacyTemplateRaw(requestParameters: PostmortemsShowLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowLegacyTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get an individual template by ID
     * ShowLegacyTemplate Postmortems
     */
    async postmortemsShowLegacyTemplate(requestParameters: PostmortemsShowLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsShowLegacyTemplateResponseBody> {
        const response = await this.postmortemsShowLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a postmortem template with sections
     * ShowTemplate Postmortems
     */
    async postmortemsShowTemplateRaw(requestParameters: PostmortemsShowTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsShowTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsShowTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/postmortems/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsShowTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a postmortem template with sections
     * ShowTemplate Postmortems
     */
    async postmortemsShowTemplate(requestParameters: PostmortemsShowTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsShowTemplateResponseBody> {
        const response = await this.postmortemsShowTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a postmortem destination
     * UpdateDestination Postmortems
     */
    async postmortemsUpdateDestinationRaw(requestParameters: PostmortemsUpdateDestinationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateDestinationResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateDestination.');
        }

        if (requestParameters.updateDestinationRequestBody === null || requestParameters.updateDestinationRequestBody === undefined) {
            throw new runtime.RequiredError('updateDestinationRequestBody','Required parameter requestParameters.updateDestinationRequestBody was null or undefined when calling postmortemsUpdateDestination.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/destinations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateDestinationRequestBodyToJSON(requestParameters.updateDestinationRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateDestinationResponseBodyFromJSON(jsonValue));
    }

    /**
     * Updates a postmortem destination
     * UpdateDestination Postmortems
     */
    async postmortemsUpdateDestination(requestParameters: PostmortemsUpdateDestinationRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateDestinationResponseBody> {
        const response = await this.postmortemsUpdateDestinationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update postmortem template
     * UpdateLegacyTemplate Postmortems
     */
    async postmortemsUpdateLegacyTemplateRaw(requestParameters: PostmortemsUpdateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostmortemsUpdateLegacyTemplateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postmortemsUpdateLegacyTemplate.');
        }

        if (requestParameters.updateLegacyTemplateRequestBody === null || requestParameters.updateLegacyTemplateRequestBody === undefined) {
            throw new runtime.RequiredError('updateLegacyTemplateRequestBody','Required parameter requestParameters.updateLegacyTemplateRequestBody was null or undefined when calling postmortemsUpdateLegacyTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/postmortems/legacy_templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostmortemsUpdateLegacyTemplateRequestBodyToJSON(requestParameters.updateLegacyTemplateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostmortemsUpdateLegacyTemplateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update postmortem template
     * UpdateLegacyTemplate Postmortems
     */
    async postmortemsUpdateLegacyTemplate(requestParameters: PostmortemsUpdateLegacyTemplateRequest, initOverrides?: RequestInit): Promise<PostmortemsUpdateLegacyTemplateResponseBody> {
        const response = await this.postmortemsUpdateLegacyTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
