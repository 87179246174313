import {
  ErrorResponse,
  InsightsDateRangeAggregationEnum,
  Panel,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import { DateRangePickerState } from "@incident-shared/forms/v1/DateRangePicker";
import { createContext, useContext } from "react";

export type InsightsContextType = {
  dateRange: InsightsDateRangeState;
  setDateRange: (range: Partial<InsightsDateRangeState>) => void;
  saving?: boolean;
  filtersLoading: boolean;
  filtersError: ErrorResponse | null;
  viewMode: DashboardViewMode;
};

export enum DashboardViewMode {
  View = "view",
  EditFiltersAndVariables = "edit_filters_and_variables",
  EditDashboard = "edit_dashboard",
}

export type InsightsDateRangeState = {
  range: DateRangePickerState;
  aggregation: InsightsDateRangeAggregationEnum;
  is_comparison: boolean;
};

export type EditDashboardFormData = {
  name: string;
  icon: string;
  color: string;
  dateRange: InsightsDateRangeState;
  filters: ExtendedFormFieldValue[];
  panels: PanelFormData[];
};

export type PanelFormData = Panel & {
  variables_data: PanelVariableFormData;
};

export type PanelVariableFormData = {
  [variableName: string]:
    | { label: string; value: string }
    | { label: string; value: string }[];
};
export type VariablesFormData = {
  [panelKey: string]: PanelVariableFormData;
};

export const InsightsContext = createContext<Partial<InsightsContextType>>({});

export const useInsightsContext = (): InsightsContextType => {
  const ctx = useContext(InsightsContext);

  if (ctx) {
    return ctx as InsightsContextType;
  }

  throw new Error(
    "useInsightsContext must be used within a InsightsShowDashboardProvider",
  );
};
