import { slugForCatalogType } from "@incident-shared/catalog/helpers";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { Searcher } from "fast-fuzzy";
import { MutableRefObject, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { catalogTypeSearcher } from "src/components/catalog/type-list/CatalogTypeListPage";
import { useAssistantOverlay } from "src/components/insights/assistant/AssistantOverlayContext";
import { useSettingsPages } from "src/components/settings/SettingsRoute";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import {
  AIConfigEnabledFeaturesEnum,
  Identity,
  IncidentsListSortByEnum,
  Session,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAIFeatureForOrg } from "src/hooks/useAI";
import { useProductAccess } from "src/hooks/useProductAccess";
import { isDevelopment } from "src/utils/environment";
import {
  permissionsCanImpersonate,
  sessionCanSeeStaffRoom,
} from "src/utils/sessions";
import { useAPI } from "src/utils/swr";
import { useClipboard } from "src/utils/useClipboard";
import { useDebounce, useEventListener } from "use-hooks";

import {
  CommandPaletteContextT,
  useCommandPaletteContext,
} from "./CommandPaletteProvider";
import {
  CatalogTypeCommandItem,
  CommandPaletteUI,
  CommandPaletteUIItem,
  IncidentCommandItem,
} from "./CommandPaletteUI";

export type CommandPaletteProps = {
  contextualItems: MutableRefObject<Record<string, CommandPaletteItem[]>>;
  sessions: Session[] | undefined;
};

export type CommandPaletteItem = CommandPaletteUIItem & {
  searchString?: string;

  // Optionally, hide this command from showing up, unless it's found by
  // searching. Defaults to false.
  hideByDefault?: boolean;
  // Optionally, state if the command is should be included or not
  shouldInclude?: (identity: Identity) => boolean;
};

const useNavigationItems = (): CommandPaletteItem[] => {
  const settingsPages = useSettingsPages();
  const { hasResponse, hasOnCall } = useProductAccess();

  const navigationItems: CommandPaletteItem[] = [
    {
      label: "Go to Home",
      analyticsId: "navigation_home",
      key: "navigation_home",
      path: "/dashboard",
      icon: IconEnum.Home,
    },
    {
      label: "Go to Incidents",
      analyticsId: "navigation_incidents",
      key: "navigation_incidents",
      path: "/incidents",
      icon: IconEnum.Incident,
    },
    {
      label: "Go to Insights",
      analyticsId: "navigation_insights",
      key: "navigation_insights",
      path: "/insights",
      icon: IconEnum.Chart,
      shouldInclude: () => hasResponse,
    },
    {
      label: "Go to Workflows",
      analyticsId: "navigation_workflows",
      key: "navigation_workflows",
      path: "/workflows",
      icon: IconEnum.Workflows,
      hideByDefault: true,
      shouldInclude: () => hasResponse,
    },
    {
      label: "Go to Catalog",
      analyticsId: "navigation_catalog",
      key: "navigation_catalog",
      path: "/catalog",
      icon: IconEnum.Book,
      hideByDefault: true,
    },
    {
      label: "Go to Status pages",
      analyticsId: "navigation_status_pages",
      key: "navigation_status_pages",
      path: "/status-pages",
      icon: IconEnum.StatusPage,
      hideByDefault: true,
    },
    {
      label: "Go to Settings",
      analyticsId: "navigation_settings",
      key: "navigation_settings",
      path: "/settings",
      icon: IconEnum.Cog,
      hideByDefault: true,
    },
    {
      label: "Go to Follow-ups",
      analyticsId: "navigation_followups",
      key: "navigation_followups",
      path: "/post-incident/follow-ups",
      icon: IconEnum.Checklist,
      hideByDefault: true,
      shouldInclude: () => hasResponse,
    },
    {
      label: "Go to Post Incident Tasks",
      analyticsId: "navigation_post_incident_tasks",
      key: "navigation_post_incident_tasks",
      path: "/post-incident/post-incident-flow",
      icon: IconEnum.Clipboard,
      hideByDefault: true,
      shouldInclude: () => hasResponse,
    },
    {
      label: "Go to Alerts",
      analyticsId: "navigation_alerts",
      key: "navigation_alerts",
      path: "/alerts",
      icon: IconEnum.Alert,
      hideByDefault: true,
    },
    {
      label: "Go to On-call",
      analyticsId: "navigation_oncall",
      key: "navigation_oncall",
      path: "/on-call",
      icon: IconEnum.OnCall,
      hideByDefault: true,
      shouldInclude: () => hasOnCall,
    },
    {
      label: "Go to On-call → Schedules",
      analyticsId: "navigation_oncall_schedules",
      key: "navigation_oncall_schedules",
      path: "/on-call/schedules",
      icon: IconEnum.Calendar,
      hideByDefault: true,
      shouldInclude: () => hasOnCall,
    },
    {
      label: "Go to On-call → Escalations",
      analyticsId: "navigation_oncall_escalations",
      key: "navigation_oncall_escalations",
      path: "/on-call/escalations",
      icon: IconEnum.Escalate,
      hideByDefault: true,
      shouldInclude: () => hasOnCall,
    },
    {
      label: "Go to On-call → Escalation paths",
      analyticsId: "navigation_oncall_escalation_paths",
      key: "navigation_oncall_escalation_paths",
      path: "/on-call/escalation-paths",
      icon: IconEnum.OnCall,
      hideByDefault: true,
      shouldInclude: () => hasOnCall,
    },
  ];

  settingsPages.forEach((pageGroup) => {
    pageGroup.items
      .filter((page) => page.hide !== true) // remove all hidden pages
      .forEach((page) => {
        navigationItems.push({
          label: `Go to Settings → ${page.label}`,
          analyticsId: `navigation_settings_${page.slug}`,
          key: `navigation_settings_${page.slug}`,
          path: `/settings/${page.slug}`,
          icon: page.icon,
          hideByDefault: true,
        });
      });
  });

  return navigationItems;
};

const useActionItems = (sessions: Session[]) => {
  const navigateRaw = useNavigate();
  const { copyTextToClipboard } = useClipboard();
  const { isOverlayOpen, toggleOverlay } = useAssistantOverlay();
  const canUseAI = useAIFeatureForOrg();
  const { identity } = useIdentity();

  const staffRoomSession = sessions.find(sessionCanSeeStaffRoom);

  const actionItems: CommandPaletteItem[] = [
    {
      label: "Declare an incident",
      analyticsId: "action_declare_incident",
      key: "action_declare_incident",
      searchString: "declare create inc incident",
      path: "/incidents?createIncident=true",
      icon: IconEnum.Incident,
    },
    {
      label: "Open Assistant",
      analyticsId: "action_open_assistant",
      key: "action_open_assistant",
      searchString: "open assistant ai insights",
      icon: IconEnum.Sparkles,
      onSelect: () => {
        toggleOverlay();
      },
      shouldInclude: () => {
        return (
          canUseAI(AIConfigEnabledFeaturesEnum.Assistant) && !isOverlayOpen
        );
      },
    },
    {
      label: "Help documentation",
      analyticsId: "action_help_documentation",
      key: "action_help_documentation",
      searchString: "help docs support",
      icon: IconEnum.Book,
      onSelect: () => {
        window.Intercom("showSpace", "help");
      },
    },
    {
      label: "Give feedback",
      analyticsId: "action_give_feedback",
      key: "action_give_feedback",
      searchString: "help feedback bug support report",
      icon: IconEnum.SpeechImportant,
      onSelect: () => {
        // Send email with
        // subject: "Give feedback"
        // body: "I'd like to give you some feedback on incident.io:"
        window.open(
          "mailto:support@incident.io?subject=Give%20feedback&body=I%27d%20like%20to%20give%20you%20some%20feedback%20on%20incident.io%3A",
          "_blank",
        );
      },
    },
    {
      label: "Close Assistant",
      analyticsId: "action_close_assistant",
      key: "action_close_assistant",
      searchString: "close assistant ai insights",
      icon: IconEnum.Sparkles,
      onSelect: () => {
        toggleOverlay();
      },
      shouldInclude: () => {
        return canUseAI(AIConfigEnabledFeaturesEnum.Assistant) && isOverlayOpen;
      },
    },
    {
      label: "Chat to support",
      analyticsId: "action_chat_support",
      key: "action_chat_support",
      searchString: "help chat support email",
      icon: IconEnum.QuestionMark,
      hideByDefault: true,
      onSelect: () => {
        // Send email with
        // subject: "Chat to support"
        window.open(
          "mailto:support@incident.io?subject=Chat%20to%20support",
          "_blank",
        );
      },
    },
    {
      label: "Report a bug",
      analyticsId: "action_report_bug",
      key: "action_report_bug",
      searchString: "bug wtf help feedback support report email",
      icon: IconEnum.Bug,
      hideByDefault: true,
      onSelect: () => {
        // Send email with
        // subject: "Report a bug"
        // body: "I've found a bug! Here's what's wrong:"
        window.open(
          "mailto:support@incident.io?subject=Report%20a%20bug&body=I%27ve%20found%20a%20bug!%20Here%27s%20what%27s%20wrong%3A",
          "_blank",
        );
      },
    },
    {
      label: "Sign in to additional organisation",
      analyticsId: "add_organisation",
      key: "add_organisation",
      searchString: "add organisation org sign in sign-in",
      icon: IconEnum.SlackTeam,
      hideByDefault: true,
      onSelect: () => {
        navigateRaw("/login/additional-organisation");
      },
    },
    {
      label: "Impersonate an organisation",
      analyticsId: "action_impersonate",
      key: "action_impersonate",
      searchString: "impersonate impersonation",
      icon: IconEnum.Warning,
      hideByDefault: true,
      onSelect: (context?: CommandPaletteContextT) => {
        context?.openImpersonateModal();
      },
      shouldInclude: (identity) =>
        !!identity.organisation_is_staff &&
        permissionsCanImpersonate(identity.staff_permissions || []),
    },
    {
      label: "Override identity",
      analyticsId: "action_override_identity",
      key: "action_override_identity",
      searchString: "identity permissions override rbac",
      icon: IconEnum.Test,
      hideByDefault: true,
      onSelect: (context?: CommandPaletteContextT) => {
        context?.openOverrideIdentityModal();
      },
      shouldInclude: () => isDevelopment(),
    },
    {
      label: "Copy shareable URL",
      analyticsId: "copy_shareable_url",
      key: "copy_shareable_url",
      searchString: "copy shareable url link",
      icon: IconEnum.Copy,
      hideByDefault: true,
      onSelect: () => {
        const currentPath = window.location.href;
        const shareablePath = currentPath.replace(
          `/${identity.organisation_slug}/`,
          "/~/",
        );
        copyTextToClipboard(shareablePath);
      },
      shouldInclude: (identity) => identity.organisation_is_staff || false,
    },
    {
      label: "Sign out",
      analyticsId: "action_sign_out",
      key: "action_sign_out",
      searchString: "sign out signout logout log out",
      icon: IconEnum.Exit,
      hideByDefault: true,
      onSelect: (context?: CommandPaletteContextT) => {
        if (!context) {
          return;
        }
        context.logoutAll();
      },
    },
    ...sessions.map(
      (session): CommandPaletteItem => ({
        label: `Switch to ${session.organisation_name}`,
        analyticsId: "action_switch_session",
        key: `action_switch_session-${session.organisation_slug}`,
        searchString: `switch org to ${session.organisation_name} ${session.organisation_slug}`,
        icon: IconEnum.SwitchHorizontal,
        hideByDefault: true,
        onSelect: () => {
          navigateRaw(`/${session.organisation_slug}/dashboard`);
        },
        // Don't show "switch to <current org>", that's just silly
        shouldInclude: (identity) =>
          identity.organisation_slug !== session.organisation_slug,
      }),
    ),
  ];

  if (staffRoomSession) {
    let staffRoomPath = `/${staffRoomSession.organisation_slug}/staff-room`;
    if (!identity.organisation_is_staff) {
      // If you're impersonating, or logged in to another org, jump directly to
      // that within staff room.
      staffRoomPath += `/${identity.organisation_slug}`;
    }

    actionItems.push({
      label: "Staff room",
      analyticsId: "action_staff_room",
      key: "action_staff_room",
      searchString: "staff billing gates features subscription stripe",
      icon: IconEnum.Billing,
      hideByDefault: true,
      onSelect: () => navigateRaw(staffRoomPath),
    });
  }

  return actionItems;
};

export function CommandPalette({
  open,
  setOpen,
  contextualItems,
  sessions,
}: CommandPaletteProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
}): React.ReactElement {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const navigate = useOrgAwareNavigate();
  const analytics = useAnalytics();
  const context = useCommandPaletteContext();
  const { identity } = useIdentity();
  const navigationItems = useNavigationItems();
  const actionItems = useActionItems(sessions ?? []);

  const {
    data: { incidents },
    isLoading: incidentsLoading,
  } = useAPI(
    open ? "incidentsList" : null,
    {
      pageSize: 3,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      fullTextSearch: debouncedSearch ? { is: debouncedSearch } : undefined,
    },
    {
      fallbackData: { incidents: [], available_statuses: [] },
    },
  );

  const {
    data: { catalog_types: types },
    isLoading: catalogTypesLoading,
  } = useAPI(
    "catalogListTypes",
    { includeCount: false },
    { fallbackData: { catalog_types: [] } },
  );

  // Search through catalog types in memory using fast-fuzzy
  const filteredTypes = catalogTypeSearcher(types)
    .search(debouncedSearch)
    .slice(0, 5); // Show max 5

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (!open) {
      return;
    }

    if (
      e.key === "Backspace" &&
      open &&
      debouncedSearch === "" &&
      search === ""
    ) {
      setOpen(false);
    }
  });

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.trim().length > 0) {
      analytics?.track("command_palette_search", {
        search: debouncedSearch,
      });
    }
  }, [debouncedSearch, analytics]);

  // Given an array of commands, return a searcher, which can rifle through them
  // looking for search terms, either based on explicit keyworks (if defined),
  // otherwise, using the label
  const commandSearcher = (commands: CommandPaletteItem[]) =>
    new Searcher(commands || [], {
      keySelector: (item) =>
        item.searchString ? item.searchString : item.label || "",
      threshold: 0.8,
    });

  // Filter out items that shouldn't be included
  const includedNavigationItems = navigationItems.filter((item) => {
    if (item.shouldInclude) {
      return item.shouldInclude(identity);
    }
    return true;
  });
  const includedActionItems = actionItems.filter((item) => {
    if (item.shouldInclude) {
      return item.shouldInclude(identity);
    }
    return true;
  });

  const contextualItemGroups: {
    groupTitle: string;
    items: CommandPaletteItem[];
  }[] = Object.entries(contextualItems.current)
    .map(([groupTitle, items]) => {
      const availableItems = items.filter((item) =>
        item.shouldInclude ? item.shouldInclude(identity) : true,
      );

      if (search) {
        return {
          groupTitle,
          items: commandSearcher(availableItems).search(search),
        };
      } else {
        return {
          groupTitle,
          items: availableItems
            .filter((item) => !item.hideByDefault)
            .slice(0, 5),
        };
      }
    })
    .filter(({ items }) => items.length > 0);

  // Filter action, navigation and injected items by the search term, if it
  // exists, otherwise revert to only items shown by default
  const filteredNavigationItems = search
    ? commandSearcher(includedNavigationItems).search(search)
    : includedNavigationItems.filter((item) => !item.hideByDefault);

  const filteredActionItems = search
    ? commandSearcher(includedActionItems).search(search)
    : includedActionItems.filter((item) => !item.hideByDefault);

  // Given a basic command, execute the action based whether it's to a relative
  // path, URL or has a callback function.
  const basicCommandHandler = (item: CommandPaletteItem) => {
    // If there's a path, push it onto the history
    if (item.path !== undefined) {
      navigate(item.path);
      return;
    }

    // If there's an href, follow the link, but open it in a new tab
    if (item.href !== undefined) {
      window.open(
        item.href,
        "_blank", // <- This is what makes it open in a new window.
      );
      return;
    }

    // Otherwise, it's a JS callback
    if (item.onSelect !== undefined) {
      item.onSelect(context);
      return;
    }

    // If we get here, sad things have happened!
    throw new Error("Tried to handle an invalid CMD+K route");
  };

  const trackCallbackAndCloseModal = (item: CommandPaletteItem) => {
    // It's a bit weird that this also closes the modal as well as tracking the
    // event, but not going to sweat it too much
    setOpen(false);
    setSearch("");

    // Track an analytics event
    analytics?.track("command_palette_select_item", {
      search: search,
      id: item.analyticsId,
      ...(item.analyticsProps || {}),
    });

    return basicCommandHandler(item);
  };

  return (
    <CommandPaletteUI
      open={open}
      setOpen={setOpen}
      search={search}
      setSearch={setSearch}
      onSelectItem={trackCallbackAndCloseModal}
      itemGroups={[
        ...contextualItemGroups,
        {
          groupTitle: "Actions",
          items: filteredActionItems,
        },
        {
          groupTitle: "Navigation",
          items: filteredNavigationItems,
        },
        {
          groupTitle: "Incidents",
          loading: incidentsLoading || search !== debouncedSearch,
          items: filterIncidentsByExactMatch(incidents, debouncedSearch).map(
            (incident) => ({
              path: `/incidents/${incident.id}`,
              analyticsId: "navigation_incident_homepage",
              analyticsProps: { incident_id: incident.id },
              renderItem: ({ onSelect }) => (
                <IncidentCommandItem incident={incident} onSelect={onSelect} />
              ),
            }),
          ),
        },
        {
          groupTitle: "Catalog Types",
          loading: catalogTypesLoading || search !== debouncedSearch,
          items: filteredTypes.map((catalogType) => ({
            path: `/catalog/${slugForCatalogType(catalogType)}`,
            analyticsId: "navigation_catalog_type",
            analyticsProps: { catalog_type_id: catalogType.id },
            renderItem: ({ onSelect }) => (
              <CatalogTypeCommandItem
                catalogType={catalogType}
                onSelect={onSelect}
              />
            ),
          })),
        },
      ]}
    />
  );
}

// If the search term is "INC-123" then only show that exact incident in the list, instead of also
// showing incidents that match the fuzzy search, e.g:
// "INC-456: Something to do with INC-123"
const filterIncidentsByExactMatch = (incidents, searchTerm) => {
  if (
    incidents.some((incident) => `INC-${incident.external_id}` === searchTerm)
  ) {
    return incidents.filter(
      (incident) => `INC-${incident.external_id}` === searchTerm,
    );
  }

  return incidents;
};
