import { ScopeNameEnum } from "@incident-io/api";
import { OrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
} from "@incident-ui";
import {
  SearchBar,
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { useOutlet } from "react-router";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI } from "src/utils/swr";
import { useLocalStorage } from "usehooks-ts";

import { EmptyState } from "../legacy/on-call/EmptyState";
import { useOnCallEntityCount } from "../legacy/on-call/utils";
import EmptyStateImg from "./images/escalation-path-empty-state.png";
import { EscalationPathsList } from "./list/EscalationPathsList";

export const EscalationPathsPage = () => {
  const {
    data: escalationPathsResp,
    isLoading: escalationPathsIsLoading,
    error: escalationPathsError,
  } = useAPI("escalationPathsList", undefined);

  const {
    data: entityCount,
    isLoading: entityCountLoading,
    error: entityCountError,
  } = useOnCallEntityCount();

  const escalationPaths = escalationPathsResp?.escalation_paths ?? [];

  const drawer = useOutlet();

  const { hasOnCall } = useProductAccess();

  if (!hasOnCall) {
    return <OrgAwareNavigate to="/on-call" replace={true} />;
  }

  if (escalationPathsError || entityCountError) {
    return <GenericErrorMessage error={escalationPathsError} />;
  }

  if (escalationPathsIsLoading || !escalationPathsResp || entityCountLoading) {
    return <Loader />;
  }

  // If we've just deleted the last on-call entity, we want to send the user back to the get started page
  if (entityCount === 0 && !drawer) {
    return <OrgAwareNavigate to="/on-call/get-started" replace={true} />;
  }

  return (
    <SearchProvider>
      {/* This means any sub-routes (e.g. create/edit) get a fully-animated drawer */}
      <AnimatePresence>{drawer}</AnimatePresence>

      <Helmet title={"Escalation paths - incident.io"} />

      {escalationPaths.length === 0 ? (
        <EmptyState
          copy="Configure which schedules and individuals to notify and in what order when an escalation is triggered."
          imageSrc={EmptyStateImg}
          title="No escalation paths created"
          buttons={[
            {
              href: "/on-call/escalation-paths/create",
              analyticsTrackingId: "on-call-create-escalation-path",
              children: "Create escalation path",
              isGated: true,
              gatingProps: {
                requiredScope: ScopeNameEnum.EscalationPathsCreate,
                disabledTooltipContent:
                  "You do not have permission to create escalation policies",
              },
            },
          ]}
        />
      ) : (
        <>
          <EscalationPathsPageHeader />
          <div className={"pb-5"}>
            <EscalationPathsList escalationPathsResp={escalationPathsResp} />
          </div>
        </>
      )}
    </SearchProvider>
  );
};

export const EscalationPathsPageHeader = () => {
  const searchBarProps = useSearchContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex">
        <SearchBar {...searchBarProps} placeholder="Search" autoFocus />
        <div className="grow" />
        <Button
          href={"/on-call/escalation-paths/create"}
          analyticsTrackingId={null}
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Add}
        >
          New escalation path
        </Button>
      </div>
      <ConnectToAlertsBanner />
    </div>
  );
};

export const ConnectToAlertsBanner = () => {
  const localStorageKey = "connectToAlertsBannerDismissed";
  const [isDismissed, setDismissed] = useLocalStorage(localStorageKey, false);
  const handleDismiss = () => setDismissed(true);

  const {
    data: alertRoutesData,
    isLoading: alertRoutesLoading,
    error: alertRoutesError,
  } = useAPI("alertRoutesListAlertRoutes", undefined);

  const doNotShowConnectToAlertsBanner =
    alertRoutesLoading ||
    alertRoutesError ||
    !alertRoutesData ||
    alertRoutesData.alert_routes.some(
      (alertRoute) => alertRoute.escalation_bindings.length > 0,
    );

  return isDismissed || doNotShowConnectToAlertsBanner ? (
    <></>
  ) : (
    <Callout
      theme={CalloutTheme.Info}
      iconOverride={IconEnum.SplitArrow}
      title="Automate escalations with alert routes"
      subtitle="Escalation paths won't be triggered unless they're connected to an alert source via an alert route."
      cta={
        <Button
          href="/alerts/routes"
          analyticsTrackingId="escalation-paths-go-to-alerts"
          theme={ButtonTheme.Secondary}
          size={ButtonSize.Medium}
        >
          View alert routes
        </Button>
      }
      secondaryCta={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={null}
          onClick={() => handleDismiss()}
          title="Dismiss banner"
        >
          Dismiss
        </Button>
      }
      ctaPosition="bottom"
    />
  );
};
