import {
  Incident,
  IncidentTimelineItem,
  TextDocumentPayload,
  TextNode,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useForm } from "react-hook-form";
import { useAPIMutation } from "src/utils/swr";

import { ActivityItemTypeToComponentMap } from "./activity-items/ActivityItem";
import {
  ActivityLogItemMode,
  ActivityLogItemUIWrapper,
} from "./ActivityLogItemUI";

type CreateEditCustomEventForm = {
  timestamp: Date;
  title: string;
  description?: TextNode;
};

type CreateEditCustomEventProps = {
  existingItem?: IncidentTimelineItem;
  incident: Incident;
  onClose: () => void;
};

export const CreateEditCustomEventDrawer = ({
  onClose,
  incident,
  existingItem,
}: CreateEditCustomEventProps) => {
  const editing = !!existingItem;

  const formMethods = useForm<CreateEditCustomEventForm>({
    defaultValues: editing
      ? {
          title: existingItem.title,
          timestamp: existingItem.timestamp,
          description: existingItem.description?.text_node,
        }
      : {
          title: "",
          timestamp: incident.reported_at,
        },
  });

  const { trigger: editItem, genericError: editError } = useAPIMutation(
    "incidentTimelineListTimelineItems",
    {
      incidentId: incident.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    async (client, data: CreateEditCustomEventForm) => {
      let description: TextDocumentPayload | undefined;
      if (data.description) {
        description = {
          text_node: data.description,
        };
      }

      await client.incidentTimelineEditTimelineItem({
        incidentId: incident.id,
        timelineItemId: existingItem?.id ?? "",
        editTimelineItemRequestBody: {
          title: data.title,
          description,
          timestamp: new Date(data.timestamp),
        },
      });
    },
  );

  const { trigger: createTimelineItem, genericError: createError } =
    useAPIMutation(
      "incidentTimelineListTimelineItems",
      {
        incidentId: incident.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      async (client, data: CreateEditCustomEventForm) => {
        let description: TextDocumentPayload | undefined;
        if (data.description) {
          description = {
            text_node: data.description,
          };
        }

        await client.incidentTimelineCreateTimelineItem({
          incidentId: incident.id,
          createTimelineItemRequestBody: {
            title: data.title,
            description,
            timestamp: new Date(data.timestamp),
          },
        });
      },
    );

  const onSubmit = async (data: CreateEditCustomEventForm) => {
    if (editing) {
      await editItem(data);
    } else {
      await createTimelineItem(data);
    }

    onClose();
  };

  const genericError = editError ?? createError;
  const isTimestampDirty = formMethods.formState.dirtyFields.timestamp ?? false;
  const timestampIsDefault =
    formMethods.watch("timestamp").toISOString() ===
      incident.reported_at.toISOString() &&
    !isTimestampDirty &&
    !editing;

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title={existingItem ? "Edit timeline item" : "Add a custom event"}
          onClose={onClose}
          titleAccessory={
            <IconBadge
              icon={IconEnum.UnorderedList}
              size={IconSize.Small}
              color={ColorPaletteEnum.Blue}
            />
          }
        />
        <DrawerBody className="h-full">
          <Form.Root
            genericError={genericError}
            id="create-custom-event-form"
            formMethods={formMethods}
            onSubmit={onSubmit}
            saving={formMethods.formState.isSubmitting}
          >
            <Callout theme={CalloutTheme.Info}>
              You can create a custom event if something relevant to the
              incident happened in an external system, such as a message from a
              customer or a deployment.
            </Callout>
            <DateTimeInputV2
              formMethods={formMethods}
              helptext={
                timestampIsDefault ? (
                  <div className="text-content-secondary text-sm">
                    We&apos;ve defaulted this to be when the incident was
                    reported.
                  </div>
                ) : undefined
              }
              name="timestamp"
              required
              label="Timestamp"
            />
            <InputV2
              formMethods={formMethods}
              name="title"
              label="Title"
              helptext={"Choose a snappy title that summarizes what happened."}
              required
              // We want to cap people at a certain length, but still let them edit any existing long ones!
              maxLength={Math.max(90, existingItem?.title.length ?? 0)}
            />
            <TemplatedTextInputV2
              inputClassName="min-h-40"
              formMethods={formMethods}
              name="description"
              label="Description"
              helptext={
                "You can add some extra colour about what happened or who was involved."
              }
              labelAccessory={
                <span className="text-content-secondary">(optional)</span>
              }
              format={"basic"}
              includeVariables={false}
              includeExpressions={false}
            />
            {existingItem && (existingItem.evidence?.length ?? 0) > 0 && (
              <>
                <div className="text-content-primary text-sm-bold">
                  Activity
                </div>
                <div className="flex flex-col gap-2">
                  {existingItem.evidence?.map((evidenceItem) => {
                    const activityLog = evidenceItem.incident_activity_log;
                    if (activityLog) {
                      const ActivityLogItemComponent =
                        ActivityItemTypeToComponentMap[activityLog.type];
                      if (ActivityLogItemComponent) {
                        // As we're in the edit model, we don't want to allow the user to interact with the activity log
                        // so lets have a no-op function for zooming in on images
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        const noOp = () => {};

                        const activityProps = ActivityLogItemComponent(
                          incident,
                          activityLog,
                          undefined,
                          noOp,
                        );

                        if (activityProps) {
                          return (
                            <ContentBox key={evidenceItem.id} className="p-1">
                              <ActivityLogItemUIWrapper
                                mode={ActivityLogItemMode.EditTimelineItem}
                                incident={incident}
                                item={activityLog}
                                zoomImageSource={undefined}
                                setZoomImageSource={noOp}
                              />
                            </ContentBox>
                          );
                        }
                      }
                    }

                    return null;
                  })}
                </div>
              </>
            )}
          </Form.Root>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex justify-end">
            <Button
              loading={formMethods.formState.isSubmitting}
              analyticsTrackingId={null}
              type="submit"
              form="create-custom-event-form"
              theme={ButtonTheme.Primary}
            >
              Done
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};
