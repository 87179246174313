/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertAttributeBinding,
    AlertAttributeBindingFromJSON,
    AlertAttributeBindingFromJSONTyped,
    AlertAttributeBindingToJSON,
} from './AlertAttributeBinding';
import {
    AlertSourceConfigSlim,
    AlertSourceConfigSlimFromJSON,
    AlertSourceConfigSlimFromJSONTyped,
    AlertSourceConfigSlimToJSON,
} from './AlertSourceConfigSlim';
import {
    Priority,
    PriorityFromJSON,
    PriorityFromJSONTyped,
    PriorityToJSON,
} from './Priority';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';

/**
 * 
 * @export
 * @interface Alert
 */
export interface Alert {
    /**
     * 
     * @type {AlertSourceConfigSlim}
     * @memberof Alert
     */
    alert_source_config: AlertSourceConfigSlim;
    /**
     * Which alert source config produced this alert
     * @type {string}
     * @memberof Alert
     */
    alert_source_config_id: string;
    /**
     * Attribute values parsed into the alert
     * @type {{ [key: string]: AlertAttributeBinding; }}
     * @memberof Alert
     */
    attribute_values: { [key: string]: AlertAttributeBinding; };
    /**
     * When this entry was created
     * @type {Date}
     * @memberof Alert
     */
    created_at: Date;
    /**
     * If applicable, a link to the dashboard for this alert
     * @type {string}
     * @memberof Alert
     */
    dashboard_url?: string;
    /**
     * A deduplication key can be provided to uniquely reference this alert from your alert source. If you send an event with the same deduplication_key multiple times, only one alert will be created in incident.io for this alert source config.
     * @type {string}
     * @memberof Alert
     */
    deduplication_key: string;
    /**
     * 
     * @type {TextDocument}
     * @memberof Alert
     */
    description?: TextDocument;
    /**
     * Attributes that we failed to evaluate and why
     * @type {{ [key: string]: string; }}
     * @memberof Alert
     */
    evaluation_failures: { [key: string]: string; };
    /**
     * The ID of this alert
     * @type {string}
     * @memberof Alert
     */
    id: string;
    /**
     * URLs of images associated with this alert
     * @type {Array<string>}
     * @memberof Alert
     */
    image_urls?: Array<string>;
    /**
     * JSON payload that this alert was created from
     * @type {string}
     * @memberof Alert
     */
    payload?: string;
    /**
     * 
     * @type {Priority}
     * @memberof Alert
     */
    priority?: Priority;
    /**
     * The priority of this alert
     * @type {string}
     * @memberof Alert
     */
    priority_id?: string;
    /**
     * When this alert was resolved
     * @type {Date}
     * @memberof Alert
     */
    resolved_at?: Date;
    /**
     * If applicable, a link to silence this alert
     * @type {string}
     * @memberof Alert
     */
    silence_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Alert
     */
    source_type: AlertSourceTypeEnum;
    /**
     * If applicable, a link to the alert in the upstream system
     * @type {string}
     * @memberof Alert
     */
    source_url?: string;
    /**
     * Current status of this alert
     * @type {string}
     * @memberof Alert
     */
    status: AlertStatusEnum;
    /**
     * Alert title which is used when summarising the alert
     * @type {string}
     * @memberof Alert
     */
    title: string;
    /**
     * When this alert was last updated
     * @type {Date}
     * @memberof Alert
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum AlertSourceTypeEnum {
    Alertmanager = 'alertmanager',
    AppOptics = 'app_optics',
    AzureMonitor = 'azure_monitor',
    Bugsnag = 'bugsnag',
    Checkly = 'checkly',
    Cloudwatch = 'cloudwatch',
    Cloudflare = 'cloudflare',
    Cronitor = 'cronitor',
    CrowdstrikeFalcon = 'crowdstrike_falcon',
    Chronosphere = 'chronosphere',
    Datadog = 'datadog',
    Dynatrace = 'dynatrace',
    Elasticsearch = 'elasticsearch',
    Email = 'email',
    Expel = 'expel',
    GithubIssue = 'github_issue',
    GoogleCloud = 'google_cloud',
    Grafana = 'grafana',
    Http = 'http',
    Honeycomb = 'honeycomb',
    Jira = 'jira',
    MonteCarlo = 'monte_carlo',
    NewRelic = 'new_relic',
    Opsgenie = 'opsgenie',
    PagerDuty = 'pager_duty',
    Panther = 'panther',
    Pingdom = 'pingdom',
    Runscope = 'runscope',
    Sns = 'sns',
    Sentry = 'sentry',
    Splunk = 'splunk',
    StatusCake = 'status_cake',
    StatusPageViews = 'status_page_views',
    SumoLogic = 'sumo_logic',
    Uptime = 'uptime',
    Zendesk = 'zendesk'
}/**
* @export
* @enum {string}
*/
export enum AlertStatusEnum {
    Firing = 'firing',
    Resolved = 'resolved'
}

export function AlertFromJSON(json: any): Alert {
    return AlertFromJSONTyped(json, false);
}

export function AlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): Alert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_source_config': AlertSourceConfigSlimFromJSON(json['alert_source_config']),
        'alert_source_config_id': json['alert_source_config_id'],
        'attribute_values': (mapValues(json['attribute_values'], AlertAttributeBindingFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'dashboard_url': !exists(json, 'dashboard_url') ? undefined : json['dashboard_url'],
        'deduplication_key': json['deduplication_key'],
        'description': !exists(json, 'description') ? undefined : TextDocumentFromJSON(json['description']),
        'evaluation_failures': json['evaluation_failures'],
        'id': json['id'],
        'image_urls': !exists(json, 'image_urls') ? undefined : json['image_urls'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'priority': !exists(json, 'priority') ? undefined : PriorityFromJSON(json['priority']),
        'priority_id': !exists(json, 'priority_id') ? undefined : json['priority_id'],
        'resolved_at': !exists(json, 'resolved_at') ? undefined : (new Date(json['resolved_at'])),
        'silence_url': !exists(json, 'silence_url') ? undefined : json['silence_url'],
        'source_type': json['source_type'],
        'source_url': !exists(json, 'source_url') ? undefined : json['source_url'],
        'status': json['status'],
        'title': json['title'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AlertToJSON(value?: Alert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_source_config': AlertSourceConfigSlimToJSON(value.alert_source_config),
        'alert_source_config_id': value.alert_source_config_id,
        'attribute_values': (mapValues(value.attribute_values, AlertAttributeBindingToJSON)),
        'created_at': (value.created_at.toISOString()),
        'dashboard_url': value.dashboard_url,
        'deduplication_key': value.deduplication_key,
        'description': TextDocumentToJSON(value.description),
        'evaluation_failures': value.evaluation_failures,
        'id': value.id,
        'image_urls': value.image_urls,
        'payload': value.payload,
        'priority': PriorityToJSON(value.priority),
        'priority_id': value.priority_id,
        'resolved_at': value.resolved_at === undefined ? undefined : (value.resolved_at.toISOString()),
        'silence_url': value.silence_url,
        'source_type': value.source_type,
        'source_url': value.source_url,
        'status': value.status,
        'title': value.title,
        'updated_at': (value.updated_at.toISOString()),
    };
}

