import { ScopeNameEnum, SlimDashboard } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  GenericErrorMessage,
  Heading,
  IconBadge,
  IconEnum,
  IconSize,
  Loader,
  LoadingWrapper,
  SearchBar,
  StackedList,
  StackedListItem,
  ToastTheme,
} from "@incident-ui";
import {
  SearchProvider,
  useSearchContext,
} from "@incident-ui/SearchBar/SearchBar";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { Searcher, sortKind } from "fast-fuzzy";
import React, { useState } from "react";
import { UpsellNotice } from "src/components/settings/UpsellNotice";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { useRevalidate } from "src/utils/use-revalidate";

import { DeleteDashboardModal } from "../dashboards/common/DeleteDashboardModal";
import { CreateCustomDashboardModal } from "../dashboards/create-edit/CreateCustomDashboardModal";

type FormData = { id: string };

export const CustomDashboardsSection = ({
  hasExceededCustomDashboardGate,
  showCreateModal,
}: {
  hasExceededCustomDashboardGate: boolean;
  showCreateModal: () => void;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const customDashboardsGate = identity?.feature_gates.custom_dashboards_count;

  const {
    data: { dashboards: customDashboards },
    isLoading,
    error,
  } = useAPI("insightsListCustomDashboards", undefined, {
    fallbackData: { dashboards: [] },
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (customDashboards.length === 0) {
    // This means that the user doesn't have access to custom dashboards at all
    if (hasExceededCustomDashboardGate) {
      return (
        <UpsellNotice
          planName="pro"
          title="Custom dashboards"
          analyticsId="insights-custom-dashboards"
          // TODO: [FND-1209] help centre article
          articleId={0}
          description="Use custom dashboards to report on the specific metrics that are important to your team"
        />
      );
    }

    return (
      <EmptyState
        title="No custom dashboards"
        content="Create custom dashboards to track metrics that are important to your team."
        icon={IconEnum.InsightsDashboard}
        cta={
          <GatedButton
            theme={ButtonTheme.Primary}
            analyticsTrackingId="insights-create-dashboard"
            requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
            upgradeRequired={hasExceededCustomDashboardGate}
            onClick={showCreateModal}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: customDashboardsGate,
                featureNameSingular: "custom dashboard",
              },
              featureName: "custom dashboards",
            }}
          >
            Create dashboard
          </GatedButton>
        }
      />
    );
  }

  return (
    <SearchProvider>
      <CustomDashboardsList dashboards={customDashboards} />
    </SearchProvider>
  );
};

type DeleteDashboardModalState =
  // when showModal is true, id and name are required
  | { showModal: true; id: string; name: string }
  | { showModal: false; id?: string; name?: string };

const CustomDashboardsList = ({
  dashboards,
  isLoading,
}: {
  dashboards: SlimDashboard[];
  isLoading?: boolean;
}) => {
  const { identity, hasScope } = useIdentity();
  const canDestroyCustomDashboard = hasScope(
    ScopeNameEnum.InsightsCustomDashboardsDestroy,
  );
  const customDashboardsGate = identity?.feature_gates.custom_dashboards_count;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [deleteDashboardModalState, setDeleteDashboardModalState] =
    useState<DeleteDashboardModalState>({
      showModal: false,
    });

  const showToast = useToast();
  const refetchDashboards = useRevalidate(["insightsListCustomDashboards"]);

  const { trigger: starTrigger, isMutating: starLoading } = useAPIMutation(
    "insightsListCustomDashboards",
    {},
    async (apiClient, { id }: FormData) => {
      await apiClient.insightsStarInsightsDashboard({
        id: id,
      });
    },
    {
      onSuccess: () => {
        refetchDashboards();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to add dashboard to favourites",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const { trigger: unstarTrigger, isMutating: unstarLoading } = useAPIMutation(
    "insightsListCustomDashboards",
    {},
    async (apiClient, { id }: FormData) => {
      await apiClient.insightsUnstarInsightsDashboard({
        id: id,
      });
    },
    {
      onSuccess: () => {
        refetchDashboards();
      },
      onError: () => {
        showToast({
          theme: ToastTheme.Error,
          title: "Failed to remove dashboard from favourites",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  const searchBarProps = useSearchContext();
  const { value: search } = searchBarProps;

  const searcher = new Searcher(dashboards, {
    keySelector: (dashboard) => [dashboard.name],
    sortBy: sortKind.insertOrder,
    threshold: 0.8,
  });

  const filteredDashboards = search ? searcher.search(search) : dashboards;

  const hasMaximumNumberOfCustomDashboards =
    customDashboardsGate !== undefined &&
    dashboards.length >= customDashboardsGate;

  const loading = isLoading || starLoading || unstarLoading;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Heading level={2} size="medium">
          Custom dashboards
        </Heading>
        <div className="flex items-center gap-2">
          <SearchBar
            {...searchBarProps}
            placeholder="Search"
            autoFocus
            className="max-w-[300px]"
          />
          <GatedButton
            icon={IconEnum.InsightsDashboard}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId="insights-create-dashboard-beside-search"
            requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
            upgradeRequired={hasMaximumNumberOfCustomDashboards}
            onClick={() => setShowCreateModal(true)}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: customDashboardsGate,
                featureNameSingular: "custom dashboard",
              },
              featureName: "custom dashboards",
            }}
          >
            Create
          </GatedButton>
        </div>
      </div>
      {filteredDashboards.length === 0 && (
        <EmptyState
          icon={IconEnum.InsightsDashboard}
          title="No dashboards match your search"
          content="Try adjusting your search terms"
          className="w-full grow"
        />
      )}
      <LoadingWrapper loading={loading}>
        <StackedList>
          {filteredDashboards.map((dashboard) => (
            <StackedListItem
              icon={dashboard.icon as unknown as IconEnum}
              iconColor={dashboard.color as unknown as ColorPaletteEnum}
              title={dashboard.name}
              key={dashboard.id}
              rowHref={`/insights/dashboards/custom/${dashboard.id}`}
              accessory={
                <div className="flex flex-row gap-2">
                  {dashboard.is_starred && <StarBadge />}
                  <DropdownMenu
                    menuClassName="min-w-[230px] group"
                    triggerButton={
                      <Button
                        analyticsTrackingId={"list-custom-dashboard"}
                        type="button"
                        theme={ButtonTheme.Tertiary}
                        size={ButtonSize.Small}
                        icon={IconEnum.DotsVertical}
                        iconProps={{ size: IconSize.Large }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {null}
                      </Button>
                    }
                    align="end"
                  >
                    {dashboard.is_starred ? (
                      <DropdownMenuItem
                        label={"Remove from favourites"}
                        analyticsTrackingId={"list-custom-dashboard-unstar"}
                        icon={IconEnum.Star}
                        onSelect={() => {
                          unstarTrigger({ id: dashboard.id });
                        }}
                      />
                    ) : (
                      <DropdownMenuItem
                        label={"Add to favourites"}
                        analyticsTrackingId={"list-custom-dashboard-star"}
                        icon={IconEnum.Star}
                        onSelect={() => {
                          starTrigger({ id: dashboard.id });
                        }}
                      />
                    )}
                    <DropdownMenuItem
                      label={"Delete"}
                      analyticsTrackingId={"list-custom-dashboard-delete"}
                      icon={IconEnum.Delete}
                      disabled={!canDestroyCustomDashboard}
                      tooltipContent={
                        !canDestroyCustomDashboard &&
                        "You do not have permission to delete dashboards."
                      }
                      onSelect={() => {
                        setDeleteDashboardModalState({
                          showModal: true,
                          id: dashboard.id,
                          name: dashboard.name,
                        });
                      }}
                      destructive
                    />
                  </DropdownMenu>
                </div>
              }
            />
          ))}
        </StackedList>
      </LoadingWrapper>
      {deleteDashboardModalState.showModal && (
        <DeleteDashboardModal
          id={deleteDashboardModalState.id}
          name={deleteDashboardModalState.name}
          onClose={() => setDeleteDashboardModalState({ showModal: false })}
        />
      )}
      {showCreateModal && (
        <CreateCustomDashboardModal onClose={() => setShowCreateModal(false)} />
      )}
    </div>
  );
};

const StarBadge = () => {
  return (
    <IconBadge
      size={IconSize.Small}
      color={ColorPaletteEnum.Yellow}
      icon={IconEnum.Star}
    />
  );
};
