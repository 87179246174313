import {
  PostmortemTemplate,
  PostmortemTemplateSection,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import { Mode } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  GenericErrorMessage,
  IconBadge,
  IconEnum,
  IconSize,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAPI } from "src/utils/swr";

type PostmortemTemplateSectionEditProps = {
  onClose: () => void;
  mode: Mode;
};

export const PostmortemTemplateDrawer = ({
  mode,
  onClose,
}: PostmortemTemplateSectionEditProps) => {
  const [showSectionCreateDrawer, setShowSectionCreateDrawer] = useState(false);
  const [sectionEditing, setSectionEditing] =
    useState<PostmortemTemplateSection>();

  const { id } = useParams();
  const isEditing = mode === Mode.Edit;

  const {
    data,
    isLoading: loadingTemplates,
    error: errorTemplates,
  } = useAPI(isEditing ? "postmortemsShowTemplate" : null, { id: id ?? "" });

  const formMethods = useForm<PostmortemTemplate>({
    defaultValues: isEditing
      ? {
          name: data?.postmortem_template?.name,
        }
      : {
          name: "Default",
        },
  });

  if (loadingTemplates || !data?.postmortem_template) {
    return <FullPageLoader />;
  }

  if (errorTemplates) {
    return (
      <GenericErrorMessage description="We couldn't load your post-mortem template." />
    );
  }

  const template = data.postmortem_template;

  return (
    <AnimatePresence>
      <Drawer
        onClose={onClose}
        width="medium"
        side="right"
        isInBackground={!!sectionEditing || showSectionCreateDrawer}
      >
        <DrawerContents>
          <DrawerTitle
            title={`${isEditing ? "Edit" : "Create"} template`}
            onClose={onClose}
            titleAccessory={
              <IconBadge
                icon={IconEnum.Doc} // TODO(PINC-3366): swap icon out
                size={IconSize.Small}
                color={ColorPaletteEnum.Red}
              />
            }
          />
          <DrawerBody className="h-full">
            <Form.Root
              formMethods={formMethods}
              onSubmit={() => undefined}
              id="create-edit-template-sections"
              saving={false}
            >
              <InputV2
                formMethods={formMethods}
                name="name"
                label="Name"
                placeholder="Default"
                disabled={true} // We do not support this yet as this project is limited to just 1 template called "Default"
              />
            </Form.Root>
            <div className="font-medium text-content-primary text-sm -mb-2">
              <div className="flex justify-between items-center mb-2">
                <div className="font-medium text-content-primary text-sm">
                  Sections
                </div>
                <Button
                  analyticsTrackingId="add-section"
                  title="Add"
                  icon={IconEnum.Add}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  onClick={() => setShowSectionCreateDrawer(true)}
                >
                  Add
                </Button>
              </div>
            </div>

            <StackedList>
              {_.orderBy(template.sections, "order", "asc").map((section) => (
                <SectionRow
                  key={section.id}
                  section={section}
                  setSectionEditing={setSectionEditing}
                />
              ))}
            </StackedList>
            {/* {showSectionCreateDrawer && (
              <PostmortemTemplateSectionDrawer
                onClose={() => setShowSectionCreateDrawer(false)}
                template={template}
              />
            )}
            {sectionEditing && (
              <PostmortemTemplateSectionDrawer
                onClose={() => setSectionEditing(undefined)}
                template={template}
                section={sectionEditing}
              />
            )} */}
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    </AnimatePresence>
  );
};

const SectionRow = ({
  section,
  setSectionEditing,
}: {
  section: PostmortemTemplateSection;
  setSectionEditing: (section: PostmortemTemplateSection) => void;
}) => {
  return (
    <StackedListItem
      key={section.id}
      title={section.name}
      icon={IconEnum.Draggable}
      iconColor={ColorPaletteEnum.SlateOnWhite}
      accessory={
        <>
          <Button
            analyticsTrackingId="edit-section"
            icon={IconEnum.Edit}
            size={ButtonSize.Small}
            theme={ButtonTheme.Secondary}
            onClick={() => setSectionEditing(section)}
          >
            Edit
          </Button>
        </>
      }
    />
  );
};
