import { Mode } from "@incident-shared/forms/v2/formsv2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { PostmortemDestinationCreateEditModal } from "./destination/PostmortemDestinationCreateEditModal";
import { LegacyPostmortemTemplateCreatePage } from "./legacy-template/LegacyPostmortemTemplateCreatePage";
import { LegacyPostmortemTemplateEditPage } from "./legacy-template/LegacyPostmortemTemplateEditPage";
import { PostmortemsPage } from "./PostmortemsPage";
import {
  PostmortemShareTemplateCreateModal,
  PostmortemShareTemplateEditModal,
} from "./share/PostmortemShareTemplateCreateEditModal";
import { PostmortemTemplateDrawer } from "./templates/PostmortemTemplateDrawer";

export const PostmortemRoute = (): React.ReactElement => {
  return (
    <>
      <Routes>
        {/* These routes are full page edits - we don't want the main page to render underneath */}
        {/* TODO: we don't need to keep these separate when we switch to V2 postmortem templates, because create/edit will be in drawers! */}
        <Route path="templates">
          <Route
            path="create"
            element={<LegacyPostmortemTemplateCreatePage />}
          />
          <Route
            path=":id/edit"
            element={<LegacyPostmortemTemplateEditPage />}
          />
        </Route>
        <Route path="*" element={<PostmortemModalsRoute />} />
      </Routes>
    </>
  );
};

// We need to split this route so that the main page still renders behind the modals
const PostmortemModalsRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();

  const onClose = () => {
    navigate("/settings/post-mortem");
  };

  return (
    <>
      <PostmortemsPage />
      <Routes>
        <Route path="templates-v2">
          <Route
            path=":id/edit"
            element={
              <PostmortemTemplateDrawer onClose={onClose} mode={Mode.Edit} />
            }
          />
        </Route>
        <Route path="destinations">
          <Route
            path=":id/edit"
            element={<PostmortemDestinationCreateEditModal mode={Mode.Edit} />}
          />
          <Route
            path="create"
            element={
              <PostmortemDestinationCreateEditModal mode={Mode.Create} />
            }
          />
        </Route>
        <Route path="shares">
          <Route
            path="templates/:id/edit"
            element={<PostmortemShareTemplateEditModal />}
          />
          <Route
            path="templates/create"
            element={<PostmortemShareTemplateCreateModal />}
          />
        </Route>
      </Routes>
    </>
  );
};
