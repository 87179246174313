import { NonPrimitiveEntryList } from "@incident-shared/attribute";
import { Button, ButtonTheme, Heading, IconEnum, IconSize } from "@incident-ui";
import _, { sortBy } from "lodash";
import React from "react";
import {
  CatalogResourceColorEnum,
  CatalogResourceIconEnum,
  CustomFieldEntry,
  CustomFieldOption,
  CustomFieldTypeInfo,
  CustomFieldTypeInfoFieldTypeEnum,
  CustomFieldValue,
  EngineParamBindingValue,
  Incident,
  IncidentApplicableFields,
} from "src/contexts/ClientContext";
import { useIsWithinMicrosoftTeamsTab } from "src/contexts/MicrosoftTeamsTabContext";
import { assertUnreachable } from "src/utils/utils";

import { incidentInEditableStatus } from "../helpers";
import { IncidentSidebarEntry } from "./IncidentSidebarEntry";

type Props = {
  onEdit: () => void;
  entries: Array<CustomFieldEntry>;
  incident: Incident;
  applicableFields: IncidentApplicableFields;
};

export function CustomFieldEntries({
  entries,
  onEdit,
  incident,
  applicableFields,
}: Props): React.ReactElement | null {
  const isWithinTeamsTab = useIsWithinMicrosoftTeamsTab();
  const entriesToShow = entries
    // Exclude custom fields that haven't been set
    .filter(({ values }) => !_.isEmpty(values))
    // Exclude custom fields that aren't applicable to this incident, and haven't been manually edited
    .filter(({ custom_field }) => {
      if (applicableFields.custom_fields.includes(custom_field.id)) {
        return true;
      }

      const hasBeenManuallyEdited = incident.manual_edits?.some(
        (edit) => edit.custom_field_id === custom_field.id,
      );

      return hasBeenManuallyEdited;
    });

  if (
    entriesToShow.length === 0 &&
    applicableFields.custom_fields.length === 0
  ) {
    return null;
  }

  return (
    <>
      <div className="overflow-clip pb-1.5">
        <div className="flex mb-1 items-center gap-1">
          <Heading level={3} size="small">
            Custom fields
          </Heading>
          {incidentInEditableStatus(incident) ? (
            <Button
              analyticsTrackingId="edit-custom-field"
              theme={ButtonTheme.Naked}
              onClick={onEdit}
              icon={IconEnum.Edit}
              iconProps={{ size: IconSize.Medium }}
              title="edit"
            />
          ) : null}
        </div>
        {entriesToShow.length === 0 ? (
          <p className="text-content-tertiary">No custom fields set</p>
        ) : (
          <div className="space-y-2">
            {sortBy(entriesToShow, ({ custom_field }) => custom_field.rank).map(
              ({ custom_field: customField, values }) => {
                return (
                  <IncidentSidebarEntry
                    key={customField.id}
                    label={customField.name}
                    value={renderValues(customField, values, isWithinTeamsTab)}
                    allowWrap={
                      customField.field_type ===
                      CustomFieldTypeInfoFieldTypeEnum.Text
                    }
                    align={customField.catalog_info ? "center" : "baseline"}
                  />
                );
              },
            )}
          </div>
        )}
      </div>
    </>
  );
}

interface CustomFieldCatalogValue extends CustomFieldValue {
  value_option: CustomFieldOption;
}

const isCatalogCustomField = (
  customField: CustomFieldTypeInfo,
  values: CustomFieldValue[],
): values is CustomFieldCatalogValue[] => {
  return values && !!customField.catalog_info;
};

const renderValues = (
  customField: CustomFieldTypeInfo,
  values: CustomFieldValue[],
  isMSTeamsTabFriendlyView: boolean,
) => {
  if (_.isEmpty(values)) {
    return "Not set";
  }

  switch (customField.field_type) {
    case CustomFieldTypeInfoFieldTypeEnum.SingleSelect:
    case CustomFieldTypeInfoFieldTypeEnum.MultiSelect: {
      if (
        customField.catalog_info &&
        isCatalogCustomField(customField, values)
      ) {
        // TS can't tell this isn't undefined until we assign it to
        // a variable, even though it's in the if above (?!)
        const catalogInfo = customField.catalog_info;
        return (
          <NonPrimitiveEntryList
            collapseList
            innerClassName="truncate max-w-full"
            key={customField.id}
            arrayValue={
              values.map(({ value_catalog_entry }, idx) => {
                return {
                  catalog_entry: {
                    ...value_catalog_entry,
                    catalog_entry_id: value_catalog_entry?.id,
                    archived_at: value_catalog_entry?.archived_at,
                  },
                  label: value_catalog_entry?.name,
                  sort_key: String(idx),
                };
              }) as EngineParamBindingValue[]
            }
            catalogResource={{
              icon: catalogInfo.CatalogTypeIcon as unknown as CatalogResourceIconEnum,
              color:
                catalogInfo.CatalogTypeColor as unknown as CatalogResourceColorEnum,
            }}
            clickable={!isMSTeamsTabFriendlyView}
          />
        );
      }
      return values
        .map(({ value_option }) => {
          if (!value_option) {
            return "";
          }
          if (value_option.archived_at) {
            return value_option.value + " (archived)";
          }
          return value_option.value;
        })
        .join(", ");
    }

    case CustomFieldTypeInfoFieldTypeEnum.Text: {
      return values.map(({ value_text }, i) => (
        <span key={`${customField.id}-${i}`} className="max-w-full">
          {value_text}
        </span>
      ));
    }

    case CustomFieldTypeInfoFieldTypeEnum.Link: {
      return values.flatMap(({ value_link }) => {
        if (value_link) {
          return (
            <a
              href={value_link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex-grow text-content-tertiary underline hover:text-content-primary bg-opacity-40 text-ellipsis overflow-x-clip"
            >
              <span className="truncate block max-w-[12em]">
                {value_link.replace(/http[s]?:\/\//gi, "")}
              </span>
            </a>
          );
        }
        return <></>;
      });
    }

    case CustomFieldTypeInfoFieldTypeEnum.Numeric: {
      return values.map(({ value_numeric }) => value_numeric);
    }

    default:
      return assertUnreachable(customField.field_type);
  }
};
