import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { IconEnum } from "@incident-ui";
import { ActivityItemRenderProps } from "src/components/timeline/activity-items/ActivityItem";
import { ActivityLogEntry, Incident } from "src/contexts/ClientContext";

import { ActivityBadge } from "./ActivityBadge";

export const ActivityItemRoleUpdate = (
  incident: Incident,
  item: ActivityLogEntry,
): ActivityItemRenderProps => {
  if (!item.content.role_update) {
    throw new Error(
      "malformed timeline item: role_update was missing role_update field",
    );
  }

  const roleName =
    !!item.is_stream && item.content.role_update.role.stream_name
      ? item.content.role_update.role.stream_name
      : item.content.role_update.role.name;

  // If there was a previous value, show what changed
  const badge = (
    <ActivityBadge
      icon={IconEnum.User}
      value={item.content.role_update.to_user?.name || "Unset"}
      previousValue={item.content.role_update.from_user?.name}
    />
  );

  return {
    title: `${roleName} updated`,
    icon: IconEnum.User,
    colour: ColorPaletteEnum.Purple,
    actor: item.content.role_update.updater,
    unquotedContent: badge,
  };
};
