import { Product } from "@incident-shared/billing";
import { HeaderBanner } from "@incident-shared/layout/HeaderBanner/HeaderBanner";
import {
  HeaderBar,
  HeaderBarTitle,
} from "@incident-shared/layout/HeaderBar/HeaderBar";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  IconEnum,
  IconSize,
  SlackTeamAvatar,
  Tooltip,
} from "@incident-ui";
import { LoadingBar } from "@incident-ui/LoadingBar/LoadingBar";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { differenceInSeconds } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useLocation } from "react-router";
import {
  Incident,
  IncidentModeEnum,
  IncidentsShowResponseBody,
  IncidentStatusCategoryEnum as StatusCategoryEnum,
  IncidentsUpdateModeRequestBodyModeEnum,
  IncidentVisibilityEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  CommsPlatform,
  usePrimaryCommsPlatform,
} from "src/hooks/usePrimaryCommsPlatform";
import { useProductAccess } from "src/hooks/useProductAccess";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";
import { useIncidentTypesEnabled } from "src/utils/incident-types";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate as useRevalidateSWR } from "src/utils/use-revalidate";
import { useInterval } from "src/utils/utils";

import { formatDurationInSecondsShort } from "../../../../utils/datetime";
import { getDurationInSeconds } from "../../../../utils/presenters";
import { useQueryParams } from "../../../../utils/query-params";
import { incidentInEditableStatus } from "../helpers";
import { useIncident, useSlackTeamConfig } from "../hooks";
import { IncidentStatusBar } from "../IncidentStatusBar";
import { ProductAccessCondtionalComponent } from "../sidebar/IncidentSidebar";
import { Category, isCategory } from "../statuses/status-utils";
import { ToggleSubscriptionButton } from "../ToggleSubscriptionButton";
import {
  EditableIncidentTypesBadge,
  EditableSeverityBadge,
  EditableStatusBadge,
} from "./EditableBadges";

const refreshEagerlyForTenSeconds =
  (intervalMs: number) => (inc: IncidentsShowResponseBody | undefined) => {
    if (inc?.incident?.created_at == null) {
      return intervalMs;
    }

    // first arg is the later date, second is the earlier
    const diff = differenceInSeconds(new Date(), inc.incident.created_at);
    if (diff < 10) {
      return 1000;
    } else {
      return intervalMs;
    }
  };

const useRevalidate = ({
  incidentId,
  intervalMs = 10000,
}: {
  incidentId: string | null;
  intervalMs?: number;
}) => {
  const { incident } = useIncident(incidentId);
  const { hasResponse } = useProductAccess();
  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const { mutate: revalidateIncident, isValidating: incidentValidating } =
    useAPI(
      incidentId == null ? null : "incidentsShow",
      { id: incidentId ?? "" },
      {
        refreshInterval: refreshEagerlyForTenSeconds(intervalMs),
      },
    );
  const { mutate: revalidateStatuses, isValidating: statusesValidating } =
    useAPI(
      incidentId == null ? null : "incidentLifecyclesListStatusesForIncident",
      { incidentId: incidentId ?? "" },
      { refreshInterval: intervalMs },
    );
  const { mutate: revalidateMemberships, isValidating: membershipsValidating } =
    useAPI(
      isPrivate ? "incidentMembershipsList" : null,
      {
        incidentId: incidentId ?? "",
      },
      { refreshInterval: intervalMs },
    );
  const {
    mutate: revalidatePolicyViolations,
    isValidating: policyViolationsValidating,
  } = useAPI(
    incidentId == null ? null : "policiesListViolations",
    { incidentIds: [incidentId ?? ""] },
    { refreshInterval: intervalMs },
  );
  const { mutate: revalidateDebriefs, isValidating: debriefsValidating } =
    useAPI(
      incidentId == null || !hasResponse
        ? null
        : "debriefsListIncidentDebriefs",
      { incidentId: incidentId ?? "" },
      { refreshInterval: intervalMs },
    );

  const revalidateTabData = useRevalidateSWR(
    hasResponse
      ? [
          "incidentAttachmentsList",
          "incidentTimelineListTimelineItems",
          "incidentUpdatesListForIncident",
          "alertsListIncidentAlerts",
          "actionsList",
          "followUpsList",
          "postIncidentFlowListTasks",
        ]
      : [
          "incidentAttachmentsList",
          "incidentTimelineListTimelineItems",
          "incidentUpdatesListForIncident",
          "alertsListIncidentAlerts",
        ],
  );

  return {
    isValidating:
      incidentValidating ||
      statusesValidating ||
      membershipsValidating ||
      policyViolationsValidating ||
      debriefsValidating,
    revalidate: async () => {
      await Promise.all([
        revalidateIncident(),
        revalidateStatuses(),
        revalidateMemberships(),
        revalidatePolicyViolations(),
        revalidateDebriefs(),
        revalidateTabData(),
      ]);
    },
  };
};

export const IncidentHeader = ({
  incident,
  detailSidebarOpen,
  setDetailSidebarOpen,
  setModalOpen,
}: {
  incident: Incident | null;
  detailSidebarOpen: boolean;
  setDetailSidebarOpen: (open: boolean) => void;
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const goBack = () => {
    // If the user came from elsewhere in incident.io, just go back to the previous page.
    // This should preserve any filters.
    if (history.state?.usr?.isInternalRedirect) {
      history.back();
    } else {
      // Otherwise, they must have clicked on a link to the incident from elsewhere. In this case,
      // just go back to the incident list.
      navigate("/incidents");
    }
  };

  return (
    <HeaderBar
      title={incident ? `INC-${incident.external_id}: ${incident.name}` : ""}
      leftSideClassName="basis-[400px]"
      icon={IconEnum.Incident}
      backOnClick={goBack}
      titleNode={
        incident && (
          <HeaderBarTitle
            title={incident.name}
            titleNode={<IncidentName incidentId={incident.id} />}
            crumbs={[{ title: "Incidents", to: "/incidents" }]}
            onEditTitle={() => setModalOpen(IncidentHeaderModal.RenameIncident)}
          />
        )
      }
      accessory={
        <IncidentDetailsAccessory
          incidentId={incident?.id || null}
          detailSidebarOpen={detailSidebarOpen}
          openSidebar={() => setDetailSidebarOpen(!detailSidebarOpen)}
        />
      }
    />
  );
};

function IncidentDetailsAccessory({
  incidentId,
  detailSidebarOpen,
  openSidebar,
}: {
  incidentId: string | null;
  detailSidebarOpen: boolean;
  openSidebar: () => void;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);

  const location = useLocation();
  const navigate = useOrgAwareNavigate();
  const queryParams = useQueryParams();
  const { pathname } = location;
  const modalInPath = Object.values(IncidentHeaderModal).find((m) =>
    pathname.includes(m),
  );
  const setModalOpen = (modal: IncidentHeaderModal | null) => {
    if (modal) {
      const newPath =
        `${pathname + (pathname.endsWith("/") ? "" : "/")}${modal}?` +
        queryParams.toString();
      navigate(newPath, { replace: true });
    } else {
      navigate(pathname.replace(modalInPath ?? "", ""), { replace: true });
    }
  };
  const streamDrawerInPath = pathname.includes(IncidentDrawer.Streams);

  const incidentIsEditable =
    incident &&
    incidentInEditableStatus(incident) &&
    !detailSidebarOpen &&
    !streamDrawerInPath;

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  return (
    <div className="flex items-center gap-2">
      {isPrivate ? (
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.LockClosed}
          analyticsTrackingId="manage-access"
          onClick={() => setModalOpen(IncidentHeaderModal.ManageAccess)}
        >
          Manage access
        </Button>
      ) : incidentId != null ? (
        <ProductAccessCondtionalComponent requiredProduct={Product.Response}>
          <ToggleSubscriptionButton incidentId={incidentId} />
        </ProductAccessCondtionalComponent>
      ) : null}
      <Button
        theme={ButtonTheme.Secondary}
        analyticsTrackingId="incident-sidebar-toggle shrink-0"
        onClick={openSidebar}
        className={"xl:hidden"}
        icon={detailSidebarOpen ? IconEnum.ChevronRight : IconEnum.ChevronLeft}
      >
        Details
      </Button>
      {incidentIsEditable ? (
        <EditIncidentOverflowButton
          incident={incident}
          setModalOpen={setModalOpen}
        />
      ) : null}
    </div>
  );
}

export function IncidentDetailsBanner({
  incidentId,
  setModalOpen,
  anySidebarOpen,
}: {
  incidentId: string | null;
  setModalOpen: (modal: IncidentHeaderModal) => void;
  anySidebarOpen: boolean;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);
  const {
    data: { incident_statuses: statuses },
  } = useAPI(
    incidentId ? "incidentLifecyclesListStatusesForIncident" : null,
    { incidentId: incidentId ?? "" },
    {
      fallbackData: { incident_statuses: [] },
    },
  );

  const { identity } = useIdentity();

  const incidentIsEditable = incident && incidentInEditableStatus(incident);

  const intervalMs = identity?.organisation_is_demo ? 5000 : 10000;
  useRevalidate({
    incidentId,
    intervalMs,
  });

  const isTriage = isCategory(Category.Triage)(incident?.incident_status);

  return (
    <HeaderBanner className="justify-between gap-4 flex-col lg:flex-row items-start lg:items-center flex-wrap">
      <div className="flex items-center text-content-tertiary gap-2 flex-wrap grow">
        {incidentIsEditable && !isTriage && (
          <IncidentStatusBar
            incident={incident}
            statuses={statuses}
            onEdit={
              anySidebarOpen
                ? undefined
                : () => setModalOpen(IncidentHeaderModal.UpdateStatus)
            }
          />
        )}
        <IncidentDetailsBadges
          incidentId={incidentId}
          setModalOpen={setModalOpen}
          anySidebarOpen={anySidebarOpen}
        />
      </div>
    </HeaderBanner>
  );
}

const IncidentDuration = ({
  category,
  durationString,
}: {
  category: StatusCategoryEnum;
  durationString: string;
}) => {
  let prefix = "";
  switch (category) {
    case StatusCategoryEnum.Declined:
    case StatusCategoryEnum.Canceled:
    case StatusCategoryEnum.Merged:
      // Don't show anything!
      return <></>;

    case StatusCategoryEnum.Closed:
    case StatusCategoryEnum.PostIncident:
      prefix = "Lasted";
      break;
    case StatusCategoryEnum.Triage:
      prefix = "In triage for";
      break;
    default:
      prefix = "Ongoing for";
  }

  return (
    <Badge
      size={BadgeSize.Medium}
      theme={BadgeTheme.Secondary}
      icon={IconEnum.Clock}
    >
      {`${prefix} ${durationString}`}
    </Badge>
  );
};

const IncidentName = ({
  incidentId,
}: {
  incidentId: string;
}): React.ReactElement => {
  const { incident } = useIncident(incidentId);
  const showToast = useToast();

  const copyToClipboard = (incident: Incident) => {
    navigator.clipboard.writeText(`INC-${incident.external_id}`);
    showToast({
      theme: ToastTheme.Info,
      title: `"INC-${incident.external_id}" copied to clipboard`,
      description: "Paste it wherever you like.",
    });
  };

  if (!incident) {
    return <LoadingBar />;
  }

  return (
    <div className={tcx("flex items-center truncate gap-1")}>
      <span
        className="hover:cursor-pointer shrink-0"
        onClick={() => copyToClipboard(incident)}
        title="Copy incident ID to clipboard"
      >
        {`INC-${incident.external_id}`}
      </span>
      <span className="min-w-0 truncate">{incident.name}</span>
    </div>
  );
};

function IncidentDetailsBadges({
  incidentId,
  setModalOpen,
  anySidebarOpen,
}: {
  incidentId: string | null;
  setModalOpen: (modal: IncidentHeaderModal) => void;
  anySidebarOpen: boolean;
}): React.ReactElement {
  const { incident } = useIncident(incidentId);

  const incidentIsEditable = incident && incidentInEditableStatus(incident);
  const { slackTeam } = useSlackTeamConfig(incident);

  const isPrivate = incident?.visibility === IncidentVisibilityEnum.Private;

  const incidentTypesEnabled = useIncidentTypesEnabled();

  const calculateDuration = () => {
    if (!incident) return null;

    const duration = getDurationInSeconds(incident);
    if (!duration) return null;

    return formatDurationInSecondsShort(duration);
  };
  const [duration, setDuration] = useState(calculateDuration);
  useInterval(() => setDuration(calculateDuration), 1 * 1000);

  return (
    <div className="flex items-center flex-wrap gap-2">
      {isPrivate && <PrivateIncidentBadge />}
      {incident && <IncidentModeBadge incident={incident} />}
      {slackTeam && (
        <Badge theme={BadgeTheme.Secondary} size={BadgeSize.Medium}>
          <SlackTeamAvatar
            url={slackTeam.icon_url}
            name={slackTeam.name}
            title={slackTeam.name}
            size={IconSize.Small}
          />
          {slackTeam.name}
        </Badge>
      )}
      {incident && (
        <EditableSeverityBadge
          severity={incident.severity}
          onEdit={
            incidentIsEditable && !anySidebarOpen
              ? () => setModalOpen(IncidentHeaderModal.UpdateSeverity)
              : undefined
          }
        />
      )}
      {incident && (
        <EditableStatusBadge
          status={incident?.incident_status}
          onEdit={
            incidentIsEditable && !anySidebarOpen
              ? () => setModalOpen(IncidentHeaderModal.UpdateIncident)
              : undefined
          }
        />
      )}
      {incidentTypesEnabled && (
        <EditableIncidentTypesBadge
          incidentType={incident?.incident_type}
          onEdit={
            incidentIsEditable && !anySidebarOpen
              ? () => setModalOpen(IncidentHeaderModal.UpdateIncidentType)
              : undefined
          }
        />
      )}
      {incident && duration && (
        <IncidentDuration
          category={incident.incident_status.category}
          durationString={duration}
        />
      )}
    </div>
  );
}

function PrivateIncidentBadge() {
  return (
    <Tooltip
      content={
        <span className="">
          Only users who have been invited to this incident will be able to see
          it.
        </span>
      }
    >
      <Badge
        size={BadgeSize.Medium}
        theme={BadgeTheme.Primary}
        icon={IconEnum.LockClosed}
      >
        Private
      </Badge>
    </Tooltip>
  );
}

function IncidentModeBadge({
  incident,
}: {
  incident: Incident;
}): React.ReactElement | null {
  const modeToLabelAndIcon = {
    [IncidentModeEnum.Test]: { label: "Test", icon: IconEnum.Test },
    [IncidentModeEnum.Tutorial]: {
      label: "Tutorial",
      icon: IconEnum.MortarBoard,
    },
    [IncidentModeEnum.Retrospective]: {
      label: "Retrospective",
      icon: IconEnum.Retrospective,
    },
  };

  const config = modeToLabelAndIcon[incident.mode];

  if (!config) {
    return null;
  }

  return (
    <Badge size={BadgeSize.Medium} theme={BadgeTheme.Secondary} {...config} />
  );
}

function EditIncidentOverflowButton({
  incident,
  setModalOpen,
}: {
  incident: Incident;
  setModalOpen: (modal: IncidentHeaderModal) => void;
}): React.ReactElement | null {
  const { permanentConvertPublicChannelsToPrivate } = useFlags();
  const commsPlatform = usePrimaryCommsPlatform();

  const { hasResponse } = useProductAccess();

  const revalidateISPIncidentLink = useRevalidateSWR([
    "internalStatusPageListIncidentLinks",
  ]);

  const { trigger: updateMode } = useAPIMutation(
    "incidentsShow",
    { id: incident.id },
    async (
      client,
      payload: { mode: IncidentsUpdateModeRequestBodyModeEnum },
    ) => {
      await client.incidentsUpdateMode({
        id: incident.id,
        updateModeRequestBody: payload,
      });

      // Marking incident as test will remove the incident from the internal status page
      if (payload.mode === IncidentsUpdateModeRequestBodyModeEnum.Test) {
        revalidateISPIncidentLink();
      }
    },
  );

  if (
    [
      StatusCategoryEnum.Triage,
      StatusCategoryEnum.Merged,
      StatusCategoryEnum.Declined,
    ].includes(incident.incident_status.category)
  ) {
    // If the incident is at the beginning of the lifecycle, there aren't any
    // additional options to show.
    return null;
  }

  const items: Parameters<typeof DropdownMenuItem>[0][] = [];
  const statusCategory = incident.incident_status.category;
  // Can this be resolved?
  if (
    statusCategory !== StatusCategoryEnum.Closed &&
    statusCategory !== StatusCategoryEnum.Canceled &&
    statusCategory !== StatusCategoryEnum.PostIncident
  ) {
    items.push({
      label: "Mark as resolved",
      analyticsTrackingId: "incident-resolve-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.Resolve),
      icon: IconEnum.Tick,
    });
  }

  // If we're in the post-incident phase, offer an opt-out
  if (statusCategory === StatusCategoryEnum.PostIncident) {
    items.push({
      label: "Opt out of post-incident",
      analyticsTrackingId: "incident-opt-out-post-incident-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.OptOutOfPostIncident),
      icon: IconEnum.Close,
    });
  }

  // Non-canceled incidents can be canceled
  if (statusCategory !== StatusCategoryEnum.Canceled) {
    items.push({
      label: "Mark as canceled",
      analyticsTrackingId: "incident-cancel-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.Cancel),
      icon: IconEnum.Close,
    });
  }

  // Non-test incidents can be marked as test
  if (
    incident.mode === IncidentModeEnum.Retrospective ||
    incident.mode === IncidentModeEnum.Standard
  ) {
    items.push({
      label: "Mark as test",
      analyticsTrackingId: "incident-mark-as-test",
      onSelect: () =>
        updateMode({ mode: IncidentsUpdateModeRequestBodyModeEnum.Test }),
      icon: IconEnum.Test,
    });
  }

  // Test incidents can be marked as standard
  if (incident.mode === IncidentModeEnum.Test) {
    items.push({
      label: "Mark as not a test",
      analyticsTrackingId: "incident-mark-as-standard",
      onSelect: () =>
        updateMode({ mode: IncidentsUpdateModeRequestBodyModeEnum.Standard }),
      icon: IconEnum.Incident,
    });
  }

  if (hasResponse) {
    items.push({
      label: "Give a shoutout",
      analyticsTrackingId: "incident-shoutout-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.GiveShoutout),
      icon: IconEnum.Announcement,
    });
  }

  // We only allow users to make things private if they have the feature enabled,
  // and the incident is not already private
  if (
    commsPlatform === CommsPlatform.Slack &&
    incident.visibility !== IncidentVisibilityEnum.Private &&
    permanentConvertPublicChannelsToPrivate
  ) {
    items.push({
      label: "Make private",
      analyticsTrackingId: "incident-make-private-modal-open",
      onSelect: () => setModalOpen(IncidentHeaderModal.ChangePrivacy),
      icon: IconEnum.LockClosed,
    });
  }

  // We always let you run a workflow
  items.push({
    label: "Run a workflow",
    analyticsTrackingId: "incident-run-workflow-modal-open",
    onSelect: () => setModalOpen(IncidentHeaderModal.RunWorkflow),
    icon: IconEnum.Workflows,
  });

  if (items.length === 0) {
    return null;
  }

  return (
    <DropdownMenu
      triggerButton={
        <Button
          title="More options"
          className="!py-1.5"
          analyticsTrackingId="incident-more-options"
          theme={ButtonTheme.Secondary}
          icon={IconEnum.DotsHorizontal}
          iconProps={{ size: IconSize.Large }}
        />
      }
    >
      {items.map((props, idx) => (
        <DropdownMenuItem key={idx} {...props} />
      ))}
    </DropdownMenu>
  );
}
